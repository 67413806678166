import { VoucherState } from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm.consts';
import { useState } from 'react';
import { VoucherGroup } from 'utils/types/campaigns';

export const useVoucherState = () => {
  const [voucherApiLoading, setVoucherApiLoading] = useState(false);
  const [isExternalVoucher, setIsExternalVoucher] = useState(false);
  const [voucherError, setVoucherError] = useState(null);
  const [voucherDetails, setVoucherDetails] = useState<VoucherGroup>(null);

  return {
    voucherApiLoading,
    setVoucherApiLoading,
    isExternalVoucher,
    setIsExternalVoucher,
    voucherError,
    setVoucherError,
    voucherDetails,
    setVoucherDetails,
  } as VoucherState;
};
