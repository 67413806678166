import styled from '@emotion/styled';

export const StyledDragAndDrop = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%; 
  gap: 17px; 
`;

export const ItemName = styled.div`
  line-height: 30px; 
  display: flex; 
  flex-direction: row; 
  gap: 16px; 
  align-items: center; 
`
