import React, { useEffect, useState } from 'react';
import { getItemTitle } from 'components/shared/dropdown/Dropdown.utils';
import { GenericItem } from 'utils/types';
import { AdornmentPosition, DropdownProps } from './Dropdown.consts';
import {
  AddNew,
  AddNewText,
  StyledCheckbox,
  StyledDropdown,
  StyledDropdownItem,
  StyledDropdownSearch,
  StyledSearch,
  StyledTextField,
} from './Dropdown.style';
import { handleKeyPressLi } from 'utils/keyPress';

function defaultFilterFunction<T extends GenericItem>(q: string) {
  return (item: T) => item.name.toString().toLowerCase().includes(q.toLowerCase());
}

export default function Dropdown<T extends GenericItem>({
  items,
  onClick,
  selectedItems = {},
  className,
  multiple,
  withSearch,
  withAmount,
  filterFunction = defaultFilterFunction,
  onCreateOption,
  titleFormatter,
  onServerSearchChange,
  ...rest
}: DropdownProps<T>) {
  const [query, setQuery] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);

  const onSearchChange = (searchValue: string) => {
    if (!withSearch) {
      return;
    }

    if (onServerSearchChange) {
      onServerSearchChange(searchValue);
    } else {
      setQuery(searchValue);
    }
  };

  useEffect(() => {
    if (filterFunction && withSearch && !onServerSearchChange) {
      setFilteredItems(items?.filter(filterFunction(query)));
    } else {
      setFilteredItems(items);
    }
  }, [items, query]);

  useEffect(() => {
    onSearchChange('');
  }, []);
  return (
    <StyledDropdown className={className} {...rest} data-automation-id="dropdown">
      {(filterFunction || onServerSearchChange) && withSearch && (
        <StyledDropdownSearch>
          <StyledTextField
            type="text"
            name="query"
            value={query}
            debounceTime={400}
            onChange={(e) => {
              onSearchChange(e.target.value);
            }}
            autoFocus
            adornmentPosition={AdornmentPosition.End}
          >
            <StyledSearch name="search" />
          </StyledTextField>
        </StyledDropdownSearch>
      )}
      {filteredItems?.map((item: T) =>
        multiple ? (
          <StyledDropdownItem key={item.id} data-automation-id="dropdown-item">
            <StyledCheckbox
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick(item);
              }}
              checked={!!selectedItems[item.id]}
              label={withAmount && item.amount ? `${item.name} (${item.amount})` : `${item.name}`}
            />
          </StyledDropdownItem>
        ) : (
          <StyledDropdownItem
            key={item.id}
            onClick={() => onClick(item)}
            selected={!!selectedItems[item.id]}
            data-automation-id="dropdown-item"
            tabIndex={0}
            onKeyDown={(event) => handleKeyPressLi(event, () => onClick(item))}
          >
            {getItemTitle(item, titleFormatter)}
          </StyledDropdownItem>
        ),
      )}

      {Boolean(query?.length && withSearch && onCreateOption) && (
        <AddNew onClick={() => onCreateOption(query)}>
          {query} <AddNewText>(Add New)</AddNewText>
        </AddNew>
      )}
    </StyledDropdown>
  );
}
