import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';
import { StyledWithNewThemeProps, StyledWithThemeProps } from 'utils/types';

import { styledHeaderContainer } from './Header.consts';

export const Container = styled.div<styledHeaderContainer>`
  display: flex;
  height: 85px;
  width: 100%;
  align-items: center;
  background: ${({ theme }) => newGlobalTheme.colors.global.headerBackground};
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.25);
  position: relative;
  font-family: ${newTypography.primaryFont};
  color: ${newGlobalTheme.colors.text.primary};
  @media print {
    display: none;
  }
`;

export const StyledEnvironmentLabel = styled.div<StyledWithThemeProps>`
  text-transform: uppercase;
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  margin: 0 ${newTypography.unit * 2}px;
  font-weight: 600;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const StyledTitle = styled.h1<StyledWithThemeProps>`
  font-size: 16.12px;
  font-family: ${newTypography.primaryFont};
  font-weight: 900;
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  margin-left: 17px;
  display: flex;
  flex-direction: column;
  line-height: 15.04px;
`;

export const StyledNewTitle = styled.h1<StyledWithNewThemeProps>`
  font-size: ${newTypography.secondaryFontSize}px;
  font-family: ${newTypography.primaryFont};
  font-weight: 900;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-left: 18px;
  line-height: 22.83px;
`;

// this should always be in the center of the header
export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  position: initial;
  left: 0;
  right: 0;
  margin-left: 63px;
  width: 215px;

  @media screen and (max-width: 1300px) {
    position: initial;
  }
`;

export const Line = styled.span`
`;
