import styled from '@emotion/styled';
import typography from 'styles/typography';
import ControlledCheckbox from 'components/shared/controlledCheckbox/ControlledCheckbox';
import Checkbox from 'components/shared/checkbox/Checkbox';
import NewRadioGroup from 'components/shared/radioGroup/NewRadioGroup';
import { OfferFormTextArea } from 'components/shared/textArea/OfferFormTextArea';
import { OfferFormTextField } from 'components/shared/textField/OfferFormTextField';
import { CampaignType } from 'utils/types/campaigns';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 530px;
  gap: 16px; 

`;

export const FormRow = styled.div<{type: CampaignType}>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 16px; 
  ${({type}) => type === CampaignType.Voucher ? 'height: 36px' : 'height: 80px; '}
  padding-left: 2px; 

`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction:column;
  align-items: baseline;
`;

export const GamingFormRow = styled.div<{type: CampaignType, showTags?: boolean}>`
  display: flex;
  align-items: baseline;
  gap: 16px;
  ${({showTags, type}) => !showTags && type !== CampaignType.Voucher && 'display:none;' }
  `
  ;

export const NewFormRow = styled.div`
  display: flex;
  margin-bottom: ${typography.unit * 4}px;
  margin-top:16px;
  align-items: baseline;
`;

export const PriorityWrapper = styled.div`
 display: flex;
 flex-direction: row;
 gap: 16px; 
 height: 80px; 
 align-items: center; 
 align-items: flex-start; 
 padding-left: 2px;

`

export const CampaignDescription = styled(OfferFormTextArea)`
  width: 100%;
  display: flex;
  font-weight:700;

`;

export const CampaignName = styled(OfferFormTextField)<{ isGeneratedName: boolean, version: string, disabled?: boolean }>`
  width: 257px;
  opacity: ${({ isGeneratedName }) => (isGeneratedName ? 0.5 : 1)};
  margin-bottom: 0px; 
  div[role='alert'] {
      color: #D90007 !important; 
      margin-top: 39px; 
  }
  input {
      height: 35px; 
  }

  .input-wrapper {
      border-bottom: ${({ disabled, version }) => (disabled && version === 'campaign-form' && '1px solid #ADADAD;')};
  }

`;

export const GamingCampaignName = styled(OfferFormTextField)<{ isGeneratedName: boolean, version: string, disabled: boolean, }>`
  width: 257px;
  opacity: ${({ isGeneratedName }) => (isGeneratedName ? 0.5 : 1)};
  div[role='alert'] {
      color: #D90007 !important; 
      margin-top: 39px; 
  }
  input {
      height: 35px; 
  }
`;


export const VoucherDistribution = styled(NewRadioGroup)`
  width: 295px;
`;

export const StyledCheckbox = styled(ControlledCheckbox)<{ nameHasError: boolean }>`
  margin-top: ${({ nameHasError }) => (nameHasError ? `${typography.unit * 6}px` : `${typography.unit}px`)};
`;

export const StyledPriorityCheckbox = styled(Checkbox)`
  align-self: flex-end;
  font-size: 12px;
  height: 36px; 
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  margin-bottom: 20px; 
  


`;
