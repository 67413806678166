

import React from 'react';
import { StyledOfferFormTextArea } from './OfferFormTextArea.style';
import { TextAreaProps } from './TextArea.consts';
import { getIsControlWithError } from 'utils/form';


export const OfferFormTextArea = (props: TextAreaProps) => {
    let hasError;
    if (props.errors && props.name) {
        hasError = getIsControlWithError(props.name, props.errors);
    }
    return <StyledOfferFormTextArea hasError={hasError} {...props}/>;
  };
