import React from 'react';
import { NewButtonBaseProps } from 'components/shared/button/buttonBase/ButtonBase.consts';
import { NewStyledButton, StyledPlus } from './AddNewButton.style';

const NewAddNewButton = ({ onClick, className }: NewButtonBaseProps) => {
  return (
    <>
      <NewStyledButton onClick={onClick} className={className} data-title="Add New">
        <StyledPlus name="newPlus" />
      </NewStyledButton>
    </>
  );
};

export default NewAddNewButton;
