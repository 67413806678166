import styled from '@emotion/styled';
import typography from 'styles/typography';
import { ButtonClose, NewButtonCloseInSelect } from '../button';
import { StyledChipProps } from './Chip.consts';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const StyledChip = styled.div<StyledChipProps>`
  background-color: ${({ theme }) => theme.colors.global.background};
  border: ${({ theme }) => `1px solid ${theme.colors.global.border};`};
  border-radius: 20px;
  padding: ${({ extra, onClose, enableTagHover }) =>
    extra || !onClose || enableTagHover ? `0 ${typography.unit * 3}px` : `0 0 0 ${typography.unit * 3}px`};
  display: flex;
  cursor: default;
  min-width: ${({ extra }) => (extra ? `unset` : 0)};
  width: fit-content;
  margin-left: ${({ extra }) => (extra ? `${typography.unit}px` : 0)};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
`;

export const NewStyledChip = styled.div<StyledChipProps>`
  background-color: ${({ first, theme }) =>
    first ? newGlobalTheme.colors.button.new.primary : theme.colors.global.background};
  border: none;
  color: ${newGlobalTheme.colors.text.primary};
  border-radius: 20px;
  padding: ${({ extra, onClose }) =>
    extra || !onClose ? `0 ${typography.unit * 3}px` : `0 0 0 ${typography.unit * 3}px`};
  display: flex;
  cursor: default;
  min-width: ${({ extra }) => (extra ? `unset` : 0)};
  max-width: 85px;
  margin-left: ${({ extra }) => (extra ? `${typography.unit}px` : 0)};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
`;

export const StyledButtonClose = styled(ButtonClose)`
  height: auto;
  padding: 0;
  margin: 0 ${typography.unit * 2}px;

  svg {
    width: 8px;
  }
`;

export const NewStyledButtonClose = styled(NewButtonCloseInSelect)`
  height: auto;
  padding: 0;
  margin: 0 ${typography.unit * 2}px;
`;
