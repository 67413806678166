import styled from '@emotion/styled';
import typography, { newTypography } from 'styles/typography';
import { Icon, IconProps } from 'components/shared/icon';
import { StyledWithThemeProps } from 'utils/types';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import Table from 'components/shared/table/CampaignTable';

// region Campaigns
export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${newTypography.unit * 4}px;
  
`;
export const NewTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 ${newTypography.unit * 1}px;
  border: 0;
  background-color: ${newGlobalTheme.colors.global.selectedBackground};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const NewTableSubHeader = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  margin: ${newTypography.unit * 4}px ${newTypography.unit * 6}px 0 ${newTypography.unit * 6}px;
  padding-bottom: ${newTypography.unit * 5}px;
  background-color: ${newGlobalTheme.colors.global.selectedBackground};
  border-bottom: 3px solid ${newGlobalTheme.colors.global.tileBackground} !important;
`;

export const NewTableBorder = styled.div`
  display: flex;
  width: 0px;
  background-color: ${newGlobalTheme.colors.global.selectedBackground};
  border-bottom: 3px solid ${newGlobalTheme.colors.global.selectedBackground} !important
`;

export const NewStyledTable = styled(Table)`
  padding: 0 0;
  margin: 0 ${typography.unit * 1}px;
  border: 0;
  border-radius: 0;
  .MuiDataGrid-columnHeaders {
    border-bottom: 3px solid ${newGlobalTheme.colors.global.tileBackground} !important;
    font-weight: 700;
    font-family: ${newTypography.primaryFont};
    color: ${newGlobalTheme.colors.global.primaryTextColor};
  }
  .MuiDataGrid-row {
    min-height: 45px !important;
    max-height: 45px !important;
    cursor: pointer !important;
    margin-bottom: 0 !important;
    border-bottom: 3px solid ${newGlobalTheme.colors.global.tileBackground} !important;
    font-family: ${newTypography.primaryFont};
    color: ${newGlobalTheme.colors.global.primaryTextColor};
  }
  .MuiDataGrid-row:hover {
    background-color: ${newGlobalTheme.colors.global.tileBackground};
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  gap: ${newTypography.unit * 4}px;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-right: 20px;
  margin-top: -50px;
`;

export const TitleActionButtonsContainer = styled.div`
  gap: ${newTypography.unit * 4}px;
`;

export const StyledBulkIcon = styled(Icon)<StyledWithThemeProps>`
  width: 20px;
  margin-right: ${newTypography.unit * 3}px;
  fill: ${({ theme }) => theme.colors.global.inputPrimary};
`;

// endregion

// region Campaigns table
export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const CellValueWrapper = styled.div`
  display: flex;
`;

export const StyledStatusLabel = styled.div`
  display: flex;
`;

export const NewStyledStatusLabel = styled.div`
  display: flex;
`;

export const StyledLabelIcon = styled(Icon)`
  margin-left: ${newTypography.unit * 2}px;
`;

export const StyledAlertIcon = styled(Icon)<IconProps>`
  width: 16px;
  fill: ${({ theme }) => theme.colors.global.error};
  margin-left: ${newTypography.unit * 2}px;
  margin-top: ${newTypography.unit * 1}px;
`;

export const AlertIconContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

export const StyledTotalAmountContainer = styled.div`
  display: flex;
`;
// endregion
