import styled from '@emotion/styled';
import zIndex from 'styles/zIndex';
import typography from 'styles/typography';
import { css, keyframes } from '@emotion/react';
import { ModalProps } from './Modal.const';

export const Container = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  min-width: 660px;
  min-height: 500px;
  max-height: 900px;
  padding: ${typography.unit * 5}px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity ${typography.transition}s;
  z-index: ${zIndex.modal};
  background: ${({ theme }) => theme.colors.global.background};
  border-radius: ${typography.borderRadius}px;
  box-shadow: 0 4.8px 14.4px rgba(0, 0, 0, 0.18), 0 25.6px 57.6px rgba(0, 0, 0, 0.22);
`;

const loader = keyframes`
  0% {visibility: visible; background: unset; width:100%; height: 100%; opacity: 0}
  100% {visibility: visible; background: rgba(255, 255, 255, 1); width:100%; height: 100%; opacity: 0.5}
`;

export const Loading = styled.div<{ isLoading: boolean }>`
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.modalLoading};
  animation: ${({ isLoading }) =>
    isLoading
      ? css`
          ${loader} 0.3s 0s 1 ease-in-out forwards
        `
      : 'unset'};
`;

export const ModalHeader = styled.div`
  margin-bottom: ${typography.unit * 5}px;
`;

export const MainTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${typography.unit}px;
`;

export const TitleWithProgress = styled.div`
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`;