import useOnClickOutside from 'hooks/use-onclick-outside';
import React, { useEffect, useRef, useState } from 'react';
import { getUserNotificationFilters, updateAllNotificationsBySubscriberId } from 'utils/api/notifications';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { useQuery } from '@apollo/client';
import { StyledLoader } from 'pages/shared/shared.style';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { OrderDirection, SSE } from 'utils/types';
import { useToastError } from 'hooks/use-toast-error';
import { UserNotification } from 'utils/types/notification';
import { getNotificationTemplate, setNotificationsMap } from './notifications.utils';
import NotificationMessage from './notificationMessage/NotificationMessge';
import {
  StyledUnreadNotificationIcon,
  NotificationContainer,
  NotificationsMenuDropdown,
  StyledIconContainer,
  StyledActionsContainer,
  StyledAction,
  StyledSeparator,
  StyledNonNotification,
  StyledBellIcon,
  NotificationsList,
  NotificationsListWrapper,
  StyledActionBorder,
  NoNotificationsContainer,
} from './NotificationsMenu.style';
import { notificationsGqls } from './Notifications.gqls';
import { FetchPolicies } from 'utils/types/common';
import sseService from 'utils/sseService';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { StyledActionContainer } from './notificationMessage/NotificationMessge.style';
import { handleKeyPress } from 'utils/keyPress';

const NotificationsMenu = () => {
  const { config } = useSelector(marketConfig);
  const { dateFormat, timeFormat, startTimezone, endTimezone } = config;
  const [isWithNotificationPoint, setIsWithNotificationPoint] = useState(true);
  const { data, refetch, error, fetchMore } = useQuery(notificationsGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: getUserNotificationFilters(),
        order: { id: OrderDirection.DESC },
        limit: 10,
        offset: 0,
      },
    },
  });

  const {
    getNotifications: { items: notifications, total },
  } = data || { getNotifications: { items: [] } };
  useToastError(error, 'Error loading notifications');

  const startSSEConnection = () => {
    const handleNotifications = (event: any) => {
      if (notifications) {
        refetch({
          data: {
            filters: getUserNotificationFilters(),
            order: { id: OrderDirection.DESC },
            offset: 0,
            limit: 10,
          },
        });
      }
    };
    if (sseService.sse) {
      try {
        sseService.sse?.addEventListener(SSE.Notifications, handleNotifications);
      } catch (error) {
        console.error('Error adding event listener for Notifications:', error);
      }
      return () => {
        try {
          sseService.sse?.removeEventListener(SSE.Notifications, handleNotifications);
        } catch (error) {
          console.error('Error removing event listener for Notifications:', error);
        }
      };
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => setIsOpen(false));

  const onReadAllClick = async () => {
    try {
      await updateAllNotificationsBySubscriberId({
        isRead: true,
      });
    } catch (err) {
      showToast(MessageType.Error, 'Failed to mark all notifications as read');
    }
  };

  const onClearAllClick = async () => {
    try {
      await updateAllNotificationsBySubscriberId({
        isArchive: true,
      });
    } catch (err) {
      showToast(MessageType.Error, 'Failed to clear all notifications');
    }
  };

  const fetchNextNotifications = async () => {
    if (notifications?.length <= total) {
      await fetchMore({
        variables: {
          data: {
            filters: getUserNotificationFilters(),
            offset: notifications.length,
            limit: 10,
            order: { id: OrderDirection.DESC },
          },
        },
      });
    }
  };

  useEffect(() => {
    const cleanup = startSSEConnection();
    return cleanup;
  }, [notifications]);

  useEffect(() => {
    if (notifications) {
      setIsWithNotificationPoint(notifications.some((notification: UserNotification) => !notification.isRead));
      setNotificationsMap(notifications);
    }
  }, [notifications]);

  return (
    <NotificationContainer ref={ref} data-automation-id="notification-container">
      <StyledIconContainer
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        tabIndex={0}
        onKeyDown={(event) =>
          handleKeyPress(event, (e: any) => {
            setIsOpen(!isOpen);
          })
        }
        data-title="Notifications"
        aria-label="Notifications"
        role={"button"}
      >
        {isWithNotificationPoint && <StyledUnreadNotificationIcon name="newBellAlert" />}
        {!isWithNotificationPoint && <StyledBellIcon name="newBell" />}
      </StyledIconContainer>
      {isOpen && (
        <NotificationsMenuDropdown data-automation-id="notification-menu">
          {notifications?.length ? (
            <>
              <StyledActionsContainer>
                <header>
                  <h5 tabIndex={0}>Notifications {`(${total})`}</h5>
                </header>
                <StyledActionContainer>
                  <StyledActionBorder>
                    <StyledAction onClick={onReadAllClick}>Read All</StyledAction>
                  </StyledActionBorder>
                  <StyledSeparator />
                  <StyledActionBorder>
                    <StyledAction onClick={onClearAllClick}>Clear All</StyledAction>
                  </StyledActionBorder>
                </StyledActionContainer>
              </StyledActionsContainer>
              <NotificationsListWrapper id="notifications-scroll" data-automation-id="notifications-scroll">
                <NotificationsList
                  scrollableTarget="notifications-scroll"
                  dataLength={notifications.length}
                  next={fetchNextNotifications}
                  hasMore={notifications.length !== total}
                  loader={<StyledLoader size={LoaderSize.Small} />}
                >
                  {notifications?.map((notification: UserNotification) => (
                    <NotificationMessage
                      key={`notification-${notification.id}`}
                      notification={getNotificationTemplate(
                        notification,
                        dateFormat,
                        timeFormat,
                        startTimezone,
                        endTimezone,
                      )}
                    />
                  ))}
                </NotificationsList>
              </NotificationsListWrapper>
            </>
          ) : (
            <>
              <NoNotificationsContainer>
              <StyledActionsContainer>
              <header>
                <h5>Notifications </h5>
              </header>
              </StyledActionsContainer>
              </NoNotificationsContainer>
              <StyledNonNotification>No notifications</StyledNonNotification>
            </>
          )}
        </NotificationsMenuDropdown>
      )}
    </NotificationContainer>
  );
};

export default NotificationsMenu;
