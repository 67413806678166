import React from 'react';
import { ToggleSwitchProps } from './ToggleSwitch.consts';
import { Ball, Slide, StyledLabel, ToggleSwitchContainer } from './ToggleSwitch.styles';

const ToggleSwitch = ({ label, checked, disabled, size = 'xlarge', className, onClick, labelPosition = 'before' }: ToggleSwitchProps) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !disabled) {
      const syntheticMouseEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      e.currentTarget.dispatchEvent(syntheticMouseEvent);
    }
  };

  return (
    <ToggleSwitchContainer disabled={disabled} className={className} onClick={onClick} data-automation-id="toggle" tabIndex={0} onKeyDown={handleKeyDown}>
      {label && labelPosition === 'before' && <StyledLabel disabled={disabled} labelPosition={labelPosition}>{label}</StyledLabel>}
      <Slide checked={checked} disabled={disabled} size={size}>
        <Ball checked={checked} disabled={disabled} size={size} />
      </Slide>
      {label && labelPosition === 'after' && <StyledLabel disabled={disabled} labelPosition={labelPosition}>{label}</StyledLabel>}
    </ToggleSwitchContainer>
  );
};

export default ToggleSwitch;
