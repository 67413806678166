import styled from '@emotion/styled';
import typography from 'styles/typography';
import ControlledToggleSwitch from 'components/shared/controlledToggleSwitch/ControlledToggleSwitch';
import { OfferFormTextField } from 'components/shared/textField/OfferFormTextField';
import ToggleSwitch from 'components/shared/toggleSwitch/ToggleSwitch';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VoucherConfigRow = styled.div<{height?: number, platform?: string}>`
  display: flex;
  min-height: 59px;
  gap: 16px; 
  ${({platform}) => platform === 'iWin' && `margin-bottom: 16px;`}
  ${({height}) => height ? `height: ${height}px;`: 'height: 59px;'}

`;

export const GamingToggleSwitch = styled(ToggleSwitch)`
  label {
      width: 43px; 
  }

`

export const IwinRow = styled.div`
  display: flex;
  flex-direction:row;
  margin-bottom:16px;
  min-height: 59px;
`;

export const GamingTypeWrapper = styled.div<{margin?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px; 

`;

export const Quantity = styled(OfferFormTextField)`
  width: 257px;

  div[role='alert'] {
    span {
      display: flex; 
      margin-top: 15px; 
    }
    color: ${newGlobalTheme.colors.global.errorTextColor} !important; 
  }
`;

export const StyledToggleSwitch = styled(ControlledToggleSwitch)`
  margin-right: ${typography.unit * 6}px;
  margin-top: 6px;
  font-size: 12px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;

`;

export const StyledSelectBox = styled(NewSelectbox)`
`;

export const IwinStyledSelectBox = styled(NewSelectbox)`

  height: 60px; 
`;
