import { gql } from '@apollo/client';

export const scheduleManagementGqls = {
  queries: {
    getAll: gql`
      query CampaignLocalSchedules($data: GetAllQueryDto) {
        getCampaignLocalSchedules(data: $data) {
          items {
            ... on CampaignLocalSchedule {
              id
              approvalStatus
              zone {
                id
                name
              }
              period {
                id
                name
              }
              campaignsNumber
              alerts
              createdBy {
                id
              }
              updatedBy {
                id
              }
              campaignsValidNumber
              updateProcessStatus
            }
          }
          total
        }
      }
    `,
  },
  mutations: {
    updateApprovalStatus: gql`
      mutation UpdateLocalScheduleStatus($data: UpdateCampaignLocalScheduleDto!) {
        updateLocalScheduleStatus(data: $data) {
          isProcessed
          message
          items { 
            ... on CampaignLocalSchedule {
              id
              approvalStatus
            }
          }
        }
      }
    `,
  },
};
