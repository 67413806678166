import React, { useState, useEffect } from 'react';
import {
  LastUpdated,
  OverviewSmallBox,
  SideOverviewContent,
  SideOverviewHeader,
  SmallNumber,
  RedemptionSmallNumber,
} from 'pages/dashboard/Dashboard.style';
import { formatDate } from 'utils/date';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { RedemptionsOverview } from 'app/slices/dashboard';
import {
  RedemptionOverviewLabelText,
  RedemptionsOverviewStyle,
} from './RedemptionOverview.style';
import { RedemptionOverviewDropdownEnum, RedemptionOverviewRecord } from './RedemptionOverview.const';
import { RedemptionOverviewDropdown } from 'pages/dashboard/components/redemptionOverview/components/RedemptionOverviewDropdown';
import { startSSEConnection } from 'app/slices/dashboard';
import { useAppDispatch } from 'app/hooks';

const RedemptionOverview = ({ redemptions }: { redemptions: RedemptionsOverview }) => {
  const { config } = useSelector(marketConfig);
  const { dateFormat, timeFormat, startTimezone } = config;
  const [redemptionCount, setRedemptionCount] = useState<number>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const cleanup = dispatch(startSSEConnection());
    return cleanup;
  }, []);

  const getSelectedTotalRedemptions = (selectedOption: string = RedemptionOverviewDropdownEnum.Today) => {
    const selectedCount = {
      [RedemptionOverviewDropdownEnum.Yesterday]: redemptions.totalRedemptions.yesterday,
      [RedemptionOverviewDropdownEnum.Last7Days]: redemptions.totalRedemptions.last7Days,
      [RedemptionOverviewDropdownEnum.Last30Days]: redemptions.totalRedemptions.last30Days,
      [RedemptionOverviewDropdownEnum.Today]: redemptions.totalRedemptions.today,
    }[selectedOption];

    setRedemptionCount(selectedCount);
  };

  const options = Object.values(RedemptionOverviewDropdownEnum).map((dropdown) => ({
    id: dropdown,
    name: RedemptionOverviewRecord[dropdown],
  }));

  return (
    <RedemptionsOverviewStyle aria-label='Redemptions Overview' data-automation-id="redemptions-overview">
      <SideOverviewHeader>Redemptions Overview</SideOverviewHeader>
      <SideOverviewContent>
        <OverviewSmallBox
          color="grey"
          tabIndex={0}
          borderColor="yellow"
          data-automation-id="selected-days-redemptions-overview"
        >
          <RedemptionSmallNumber>{redemptionCount || redemptions.totalRedemptions.today}</RedemptionSmallNumber>
          <RedemptionOverviewDropdown
            options={options}
            onSelect={getSelectedTotalRedemptions}
            defaultOption={options[0]}
          />
        </OverviewSmallBox>
        <OverviewSmallBox
          color="grey"
          tabIndex={0}
          borderColor="yellow"
          data-automation-id="total-redemptions-overview"
        >
          <SmallNumber>{redemptions.totalRedemptions.overall}</SmallNumber>
          <RedemptionOverviewLabelText>Total Redemptions</RedemptionOverviewLabelText>
        </OverviewSmallBox>
      </SideOverviewContent>
      <LastUpdated>
        Last Updated:
        {redemptions.lastUpdated &&
          ` ${formatDate(redemptions.lastUpdated, { dateFormat, timeFormat }, startTimezone)}`}
      </LastUpdated>
    </RedemptionsOverviewStyle>
  );
};
export default RedemptionOverview;
