import React from 'react';
import { ApprovalStatusLabel, ApprovalStatusWithLabel } from 'utils/types';
import { NewStyledStatusLabel } from './NewStatusLabel.style';
import { NewStatusLabelProps } from './NewStatusLabel.consts';

const NewStatusLabel = ({ type, className }: NewStatusLabelProps) => {
  return (
    <NewStyledStatusLabel className={className} type={type} data-automation-id="status-label">
      {ApprovalStatusLabel[type as ApprovalStatusWithLabel]}
    </NewStyledStatusLabel>
  );
};

export default NewStatusLabel;
