import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PLUImageProps } from './PLUImage.consts';
import { ButtonText } from 'components/shared/button';
import { ProductPicker } from 'pages/offers/offerManagement/components/offerForm/components/templates/Template.style';
import { calcProductsHint } from 'utils/product';
import { StyledListSelectbox } from '../../DoeImageForm.style';
import {
  PLUContainer,
  PLUListContainer,
  StyledLabel,
  StyledImagePLUList,
  StyledImagePLUListItem,
  StyledIcon,
  StyledButtonTextAdd,
} from './PLUImages.style';
import getDoeProductsAction from './DoeProductsActionCondition';

export const PLUImages = ({ onProductSelection, defaultTemplateToggle, disabled }: PLUImageProps) => {
  const fieldName = 'image.metadata.products';
  const { control, register, unregister, watch, getValues, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  const MAX_LENGTH = 10;
  const [products] = watch([fieldName]);

  useEffect(() => {
    if (defaultTemplateToggle) {
      if (products) {
        unregister(fieldName);
      }
      remove();
    }
  }, [defaultTemplateToggle]);

  return !defaultTemplateToggle ? (
    <PLUContainer>
      <StyledLabel disabled={disabled}>Image PLUs</StyledLabel>
      <PLUListContainer>
        {fields && fields.length > 0 && (
          <StyledImagePLUList>
            {fields.map((item, index) => (
              <StyledImagePLUListItem key={`${item.id}_${index}`}>
                <StyledListSelectbox
                  disabled={disabled}
                  control={control}
                  name={`${fieldName}.${index}.count` as const}
                  validation={{
                    min: 1,
                    max: 15,
                    required: true,
                  }}
                  items={Array.from(
                    { length: 15 },
                    (x, i) =>
                      ({
                        id: (i + 1).toString(),
                        name: (i + 1).toString(),
                      } as any),
                  )}
                  defaultValue="1"
                  initialSelectedItems={products[index] && products[index]?.count ? [products[index].count] : ['1']}
                  selectWidth={60}
                />
                <ProductPicker>
                  {calcProductsHint(products?.[index]?.code) ?? ''}
                  {getDoeProductsAction(
                    disabled,
                    products?.[index]?.code,
                    getValues,
                    'Select Products *',
                    `products.${index}.code`,
                    (data: any) => ({ products: { [index]: data } }),
                    onProductSelection,
                  )}
                </ProductPicker>
                <input
                  type="hidden"
                  {...register(`${fieldName}.${index}.code` as const, {
                    value: products && products[index].code ? products[index].code : undefined,
                    shouldUnregister: true,
                    required: true,
                  })}
                />
                {index >= 0 && !disabled && (
                  <ButtonText
                    onClick={() => {
                      remove(index);
                      products.splice(index, 1);
                      unregister(fieldName);
                      setValue(fieldName, products);
                      register(fieldName);
                    }}
                  >
                    <StyledIcon name="minusVector" width={16} />
                  </ButtonText>
                )}
              </StyledImagePLUListItem>
            ))}
          </StyledImagePLUList>
        )}
        {fields.length < MAX_LENGTH && !disabled && (
          <StyledButtonTextAdd
            onClick={() => {
              append({ count: '1', code: undefined });
            }}
          >
            <StyledIcon name="plusVector" width={16} />
          </StyledButtonTextAdd>
        )}
      </PLUListContainer>
    </PLUContainer>
  ) : (
    <></>
  );
};
