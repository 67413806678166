import React, { MutableRefObject, useEffect } from 'react';

export default function useOnClickOutside(ref: MutableRefObject<HTMLElement>, handler: (value: any) => void) {
  useEffect(() => {
    const listener: any = (event: React.ChangeEvent<HTMLInputElement>) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    const handleFocusOut = (event: FocusEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    document.addEventListener('focusin', handleFocusOut);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
      document.addEventListener('focusin', handleFocusOut);
    };
  }, [ref, handler]);
}
