import React from 'react';
import { TabStripItemProps } from './TabStripItem.consts';
import { StyledArrowUp, StyledLink, StyledTitle } from './NewTabStripItem.style';
import { handleKeyPress } from 'utils/keyPress';

const TabStripItem = ({ tab, isSelected, isExpandable, isOpen, onClick, fontSize, paddingLeft, isOfferTab }: TabStripItemProps) => {
  const TitleContent = ({ tabIndex }: { tabIndex: number }) => {
    return (
      <StyledTitle
        tabIndex={tabIndex}
        key={tab.id}
        isSelected={isSelected}
        fontSize={fontSize}
        paddingLeft={paddingLeft}
        onClick={onClick}
        onKeyDown={(event) =>
          handleKeyPress(event, (e: any) => {
            onClick();
          })
        }
        data-automation-id="tab-item"
        isOfferTab={isOfferTab}
      >
        {tab.title}
        {isExpandable && <StyledArrowUp name="arrowUp" isOpen={isOpen} />}
      </StyledTitle>
    );
  };
  return (
    <>
      {tab.link ? (
        <StyledLink to={tab.link} isSelected={isSelected}>
          <TitleContent tabIndex={-1} />
        </StyledLink>
      ) : (
        <TitleContent tabIndex={0} />
      )}
    </>
  );
};

export default TabStripItem;
