import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { ApprovalStatus, GenericItem, OrderDirection } from 'utils/types';
import { CampaignAlert, CampaignType } from 'utils/types/campaigns';
import { CampaignFilterType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import type { RootState } from 'app/store';
import { buildOSBaseAndFilters, generateCampaignStatusFilters } from 'utils/serverFilters';
import { CampaignBulkAction } from 'pages/campaigns/campaignManagement/components/campaignBulkActions/CampaignBulkActions.consts';

const DAYS_IN_MILLISECONDS = 8.64e7;

export interface CampaignsUpdates {
  type: string;
  record: any;
}

export enum CampaignsStateId {
  DoeCampaingnPage = 'doeCampaignPage',
  CampaignsPage = 'campaignsPage',
  CampaignsSelection = 'campaignsSelection',
  CalenderCampaigns = 'calenderCampaigns',
}

export interface CampaignsFilters {
  [CampaignFilterType.Status]: ApprovalStatus[];
  [CampaignFilterType.Type]?: CampaignType[];
  [CampaignFilterType.Tags]: { [key in number | string]: GenericItem };
  [CampaignFilterType.Alert]: CampaignAlert[];
  [CampaignFilterType.SearchQuery]: string;
  [CampaignFilterType.ExcludedExternalIds]: number[];
  [CampaignFilterType.Period]: number[];
  [CampaignFilterType.Zone]?: number[];
  [CampaignFilterType.OnlyLocal]?: string;
  [CampaignFilterType.National]: string;
  [CampaignFilterType.OfferGroup]?: { [key in number | string]: GenericItem };
  [CampaignFilterType.OfferBank]?: { [key in number | string]: GenericItem };
  [CampaignFilterType.Priority]: string;
}

type CampaignsGenericSlice = {
  campaignsState: (state: RootState) => any;
  reducer: any;
  actions: any;
  getFilters: any;
};

export interface Campaigns {
  quickFilterCounts: {
    alertCount: number;
    deploymentFailedCount: number;
    pendingApprovalCount: number;
  };
  filters: CampaignsFilters;
  order: {
    [key: string]: OrderDirection;
  };
}

export const initialState: Campaigns = {
  quickFilterCounts: {
    alertCount: 0,
    deploymentFailedCount: 0,
    pendingApprovalCount: 0,
  },
  filters: {
    [CampaignFilterType.Status]: [
      ApprovalStatus.Draft,
      ApprovalStatus.PendingApproval,
      ApprovalStatus.Approved,
      ApprovalStatus.Deployed,
      ApprovalStatus.Active,
      ApprovalStatus.Expired,
      ApprovalStatus.Revoked,
      ApprovalStatus.DeploymentFailed,
      ApprovalStatus.Rejected,
    ],
    [CampaignFilterType.Type]: [],
    [CampaignFilterType.Tags]: {},
    [CampaignFilterType.Alert]: [],
    [CampaignFilterType.SearchQuery]: '',
    [CampaignFilterType.ExcludedExternalIds]: [],
    [CampaignFilterType.Period]: [],
    [CampaignFilterType.Zone]: [],
    [CampaignFilterType.OnlyLocal]: 'false',
    [CampaignFilterType.National]: 'false',
    [CampaignFilterType.Priority]: 'false',
  },
  order: {
    externalId: OrderDirection.DESC,
  },
};

export const priorityState: Campaigns = {
  quickFilterCounts: {
    alertCount: 0,
    deploymentFailedCount: 0,
    pendingApprovalCount: 0,
  },
  filters: {
    [CampaignFilterType.Status]: [
      ApprovalStatus.Active,
    ],
    [CampaignFilterType.Type]: [
      CampaignType.LocationBased,
      CampaignType.Segmented,
      CampaignType.Voucher,
      CampaignType.CustomerServiceOffer,
      CampaignType.WelcomeOffer,
      CampaignType.Ned,
    ],
    [CampaignFilterType.Tags]: {},
    [CampaignFilterType.Alert]: [],
    [CampaignFilterType.SearchQuery]: '',
    [CampaignFilterType.ExcludedExternalIds]: [],
    [CampaignFilterType.Period]: [],
    [CampaignFilterType.Zone]: [],
    [CampaignFilterType.OnlyLocal]: 'false',
    [CampaignFilterType.National]: 'false',
    [CampaignFilterType.Priority]: 'true',
  },
  order: {}
};

export const createCampaignsGenericSlice = (
  sliceName: CampaignsStateId,
  initialSliceState = initialState,
): CampaignsGenericSlice => {
  const getFilters = (initialFilters: CampaignsFilters, range?: Date, bulkAction?: CampaignBulkAction): any => {
    if (initialFilters) {
      const CampaignServerFiltersRecord = {
        [CampaignFilterType.Tags]: 'tags.id',
        [CampaignFilterType.Type]: 'type',
        [CampaignFilterType.Alert]: 'alert',
        [CampaignFilterType.Id]: 'externalId',
        [CampaignFilterType.ExcludedExternalIds]: 'externalId',
        [CampaignFilterType.Period]: 'period.id',
        [CampaignFilterType.Zone]: 'zones.id',
        [CampaignFilterType.OfferGroup]: 'baseOfferRec.offerGroupId',
        [CampaignFilterType.OfferBank]: 'baseOfferRec.offerBankId',
        [CampaignFilterType.CampaignId]: 'id',
      };
      let filters = buildOSBaseAndFilters(CampaignServerFiltersRecord, initialFilters);

      if (initialFilters[CampaignFilterType.Status]?.length) {
        const statusFilter = generateCampaignStatusFilters(initialFilters, bulkAction);
        filters.bool.must.push({ bool: { should: statusFilter } });
      }

      if (initialFilters[CampaignFilterType.OnlyLocal] === 'true') {
        filters.bool.must.push({ bool: { must: [{ exists: { field: 'localSchedule.id' } }] } });
      }

      if (
        initialFilters[CampaignFilterType.SearchQuery]?.length &&
        initialFilters[CampaignFilterType.SearchQuery][0]?.length
      ) {
        filters[CampaignFilterType.SearchQuery] = initialFilters[CampaignFilterType.SearchQuery][0] as any;
      }

      if (range) {
        filters = { bool: { must: [{ bool: { should: [] } }, filters] } };
        filters.bool.must[0].bool.should.push({
          bool: {
            must: [
              { range: { 'schedule.campaignStart': { lte: range.getTime() - 40 * DAYS_IN_MILLISECONDS } } },
              { range: { 'schedule.campaignEnd': { gte: range.getTime() + 40 * DAYS_IN_MILLISECONDS } } },
            ],
          },
        });
        filters.bool.must[0].bool.should.push({
          bool: {
            must: [
              { range: { 'schedule.campaignStart': { gte: range.getTime() - 40 * DAYS_IN_MILLISECONDS } } },
              { range: { 'schedule.campaignStart': { lte: range.getTime() + 40 * DAYS_IN_MILLISECONDS } } },
            ],
          },
        });
        filters.bool.must[0].bool.should.push({
          bool: {
            must: [
              { range: { 'schedule.campaignEnd': { gte: range.getTime() - 40 * DAYS_IN_MILLISECONDS } } },
              { range: { 'schedule.campaignEnd': { lte: range.getTime() + 40 * DAYS_IN_MILLISECONDS } } },
            ],
          },
        });
      }

      return filters;
    }
  };

  const campaignsSlice = createSlice({
    name: sliceName,
    initialState: initialSliceState,
    reducers: {
      setFilter(state, action) {
        state.filters = {
          ...state.filters,
          [action.payload.filter]: action.payload.value,
        };
      },
      setFilters(state, action) {
        state.filters = action.payload;
      },
      setOrder(state, action) {
        state.order = action.payload;
      },
    },
  });

  const campaignsState = (state: RootState) => state.campaigns[sliceName];
  return {
    campaignsState,
    reducer: campaignsSlice.reducer,
    actions: campaignsSlice.actions,
    getFilters,
  };
};

export const vceCampaignsPage: CampaignsGenericSlice = createCampaignsGenericSlice(CampaignsStateId.CampaignsPage);
export const doeCampaignsPage: CampaignsGenericSlice = createCampaignsGenericSlice(CampaignsStateId.DoeCampaingnPage, {
  quickFilterCounts: {
    alertCount: 0,
    deploymentFailedCount: 0,
    pendingApprovalCount: 0,
  },
  filters: {
    [CampaignFilterType.Status]: [
      ApprovalStatus.Deployed,
      ApprovalStatus.Active,
      ApprovalStatus.Expired,
      ApprovalStatus.Revoked,
      ApprovalStatus.DeploymentFailed,
      ApprovalStatus.DeploymentPending,
    ],
    [CampaignFilterType.Tags]: {},
    [CampaignFilterType.Alert]: [],
    [CampaignFilterType.SearchQuery]: '',
    [CampaignFilterType.ExcludedExternalIds]: [],
    [CampaignFilterType.Period]: [],
    [CampaignFilterType.National]: 'false',
    [CampaignFilterType.Priority]: '',
  },
  order: {
    externalId: OrderDirection.DESC,
  },
});

export const campaignsSelection: CampaignsGenericSlice = createCampaignsGenericSlice(
  CampaignsStateId.CampaignsSelection,
);
export const calenderCampaigns: CampaignsGenericSlice = createCampaignsGenericSlice(
  CampaignsStateId.CalenderCampaigns,
  initialState,
);

const campaignsReducer = combineReducers({
  [CampaignsStateId.DoeCampaingnPage]: doeCampaignsPage.reducer,
  [CampaignsStateId.CampaignsPage]: vceCampaignsPage.reducer,
  [CampaignsStateId.CampaignsSelection]: campaignsSelection.reducer,
  [CampaignsStateId.CalenderCampaigns]: calenderCampaigns.reducer,
});

export default campaignsReducer;
