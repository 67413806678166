import { FormProvider, useForm } from 'react-hook-form';
import { DoeImageFormProps, DEFAULT_TEMPLATES, METADATA, DISCOUNT_COMPONENT_CHECKBOX } from './DoeImageForm.consts';
import { DoeImage } from 'utils/types/images';
import { cloneDeep, default as _, isNumber, isString } from 'lodash';
import { useDoeImageFromRedux } from './components/hooks/useDoeImageFromRedux';
import {
  FileDetails,
  FileDetailsContainer,
  FormContent,
  Forms,
  ImageFormContainer,
  ImageUploadContainer,
  PropName,
  PropValue,
  StyledControlledToggleSwitch,
  StyledImagesFormModal,
  StyledSelectbox,
} from './DoeImageForm.style';
import { hasValue } from 'utils/text';
import {
  ImageContainer,
  ImageDescription,
  ImageName,
  ImageTags,
  ImagesFormContainer,
  StyledButtonsContainer,
  StyledImage,
} from 'pages/settings/images/components/shared.style';
import { ButtonContained, ButtonOutlined, ButtonText } from 'components/shared/button';
import Tooltip from 'components/shared/tooltip/Tooltip';
import {
  archiveImageClicked,
  onTagCreation,
  preventFormSendOnEnter,
  readDoeImage,
  unarchiveImageClicked,
} from '../../../images/images.utils';
import { useCallback, useEffect, useState } from 'react';
import { ImageMetadata, ImageProps, ImageSource } from 'pages/settings/images/Images.consts';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import InputFiles from 'components/shared/inputFiles/InputFiles';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import { FetchPolicies, ValidationMessages } from 'utils/types/common';
import { ButtonType } from 'components/shared/inputFiles/InputFiles.consts';
import { hideTooltip } from 'utils/tooltip';
import { store } from 'app/store';
import { Modals, closeModal, openModal } from 'app/slices/modals';
import { FormMode } from 'utils/types';
import { SetItemsSelectionFormState } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import { convertToGenericSet } from 'pages/offers/offerManagement/components/offerForm/components/templates/shared/ProductsActionCondition.utils';
import CheckboxGroup from 'components/shared/checkboxGroup/CheckboxGroup';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { useTagsQuery } from 'hooks/use-tags-query';
import { TagsValidEntities } from 'utils/types/tags';
import { OfferTemplates } from 'utils/types/offers';
import { doeImagesGqls } from '../../DoeImages.gqls';
import { useQuery } from '@apollo/client';
import { useToastError } from 'hooks/use-toast-error';
import ReactTooltip from 'react-tooltip';
import {
  createNewDoeImage,
  getCampaignsImpactsByImageId,
  getOffersImpactsByImageId,
  saveNotification,
  updateImage,
} from 'utils/api/images';
import { Language, LanguageRecord } from 'utils/languages';
import CopyButton from 'pages/shared/copyButton/CopyButton';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { handleOfferImpactChange } from 'utils/offer';
import { handleCampaignImpactChange } from 'utils/campaign';
import { HeaderComponentType } from 'components/impact/impactModal/ImpactModal.consts';
import { Metadata } from './components/Metadata/Metadata';

const DoeImageForm = ({ mode, image, className, onClose, viewOnly = false }: DoeImageFormProps) => {
  const {
    image: persistentImage,
    mode: persistentMode,
    updatedImagePlu: persistentUpdatedImagePlu,
  }: { image: DoeImage; mode: string; updatedImagePlu: boolean } = useDoeImageFromRedux(cloneDeep(image));

  const { languages } = useSelector(marketConfig);
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Image]);
  const [modalMode, setModalMode] = useState(mode ?? persistentMode);
  const [defaultTemplateToggle, setDefaultTemplateToggle] = useState(persistentImage.defaultTemplateToggle ?? false);
  const [isFileChanged, setIsFileChanged] = useState(false);
  const [uploadedDoeImage, setUploadedDoeImage] = useState({
    src: persistentImage.src,
    file: persistentImage.file,
    name: persistentImage.name,
    fileExtension: persistentImage.fileExtension,
  });

  const [metadataFieldsValidation, setMetadataFieldsValidation] = useState({
    discount: false,
    products: false,
    specialOptions: false,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const formMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { image: { ...persistentImage, imageSource: ImageSource.DOE } } as any,
  });

  const { data, error } = useQuery(doeImagesGqls.queries.getSpecialOptionsConfig, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
  });
  const { getSpecialOptionsConfig: { specialOptions: specialOptionsItems = [] } = { specialOptions: [] } } = data || {};
  useToastError(error, 'Error getting specialOptions');

  const getImageMetadata = (metadata: any) => {
    const metadataObj: ImageMetadata = {};
    if (metadataFieldsValidation.products) {
      const products: { count: number; code: number[] }[] = metadata.products.map((productObj: any) => {
        const productCodes: number[] = productObj.code.products.map((product: any) => product.plu);
        return {
          count: parseInt(productObj.count),
          code: productCodes,
        };
      });
      metadataObj.products = products;
    }
    if (metadataFieldsValidation.discount) {
      const { discountType, value, minPurchase }: { discountType: string; value: string; minPurchase: string } =
        metadata.discount;
      const discount = {
        ...(discountType && { discountType }),
        ...(value && { value: parseInt(value) }),
        ...(minPurchase && { minPurchase: parseInt(minPurchase) }),
      };
      metadataObj.discount = discount;
    }
    if (metadataFieldsValidation.specialOptions) {
      metadataObj.specialOptions = metadata.specialOptions.reduce((acc: { [key: string]: boolean }, curr: string) => {
        acc[curr] = true;
        return acc;
      }, {});
    }
    return metadataObj;
  };

  const onImageUpdate = async (editedImage: any) => {
    try {
      editedImage.tags = editedImage.tags.map((tag: any) => (isNumber(tag) ? tag : tag.id));
      await updateImage(editedImage, editedImage.imageSource);
      showToast(MessageType.Success, 'Image updated successfully');
      store.dispatch(closeModal());
    } catch (e) {
      const messageSuffix = e.message.includes('already exists') ? ` - ${editedImage.name} already exists` : '';
      showToast(MessageType.Error, `Failed to update image${messageSuffix}`);
    }
  };

  const getImageData = (imageData: Record<string, any>) => {
    const { image: doeImage } = imageData;
    const { name, imageSource, tags: imageTags, description = '', selectedLanguages, file, fileExtension } = doeImage;
    const imageToBeSaved: ImageProps = {
      name,
      imageSource,
      file: file as any,
      fileExtension: fileExtension,
      description,
      ...(imageTags && {
        tags: imageTags.map((tag: any) => (isNumber(tag) || isString(tag) ? Number(tag) : Number(tag.id))),
      }),
      ...(selectedLanguages && { selectedLanguages }),
    };
    if (doeImage.defaultTemplates) {
      imageToBeSaved.defaultTemplates = doeImage.defaultTemplates.map((item: string) => parseInt(item));
    }
    if (doeImage.metadata) {
      const metadataObj: ImageMetadata = getImageMetadata(doeImage.metadata);
      if (!_.isEmpty(metadataObj)) {
        imageToBeSaved.metadata = metadataObj;
      }
    }
    if (modalMode === FormMode.Edit) {
      imageToBeSaved.id = persistentImage.id;
    }
    return imageToBeSaved;
  };

  const onDoeImageSave = async (imageData: Record<string, any>) => {
    try {
      const imageToBeSaved = getImageData(imageData);
      await createNewDoeImage([imageToBeSaved]);
      showToast(MessageType.Success, `Image added successfully`);
      store.dispatch(closeModal());
    } catch (e) {
      showToast(MessageType.Error, e.message);
    }
  };

  const onEditImageSave = async (imageData: Record<string, any>) => {
    try {
      const imageToBeSaved = getImageData(imageData);
      if (isFileChanged) {
        await saveNotification(imageToBeSaved as any, onImageUpdate);
      } else {
        delete imageToBeSaved.file;
        delete imageToBeSaved.fileExtension;
        await onImageUpdate(imageToBeSaved);
      }
    } catch (e) {
      showToast(MessageType.Error, `Failed to update image`);
    }
  };
  const shouldShowField = (fieldName: string) => {
    if (modalMode === FormMode.View && !viewOnly) {
      const fieldVal = formMethods.getValues(fieldName);
      if (fieldVal && Array.isArray(fieldVal)) {
        return fieldVal.length > 0;
      }
      return fieldVal;
    }
    return true;
  };

  const onFileChange = async (files: FileList) => {
    const loadedImage = await readDoeImage(files[0]);
    if (loadedImage) {
      const { file, src, fileExtension } = loadedImage;
      const { image: uploadedImage } = formMethods.getValues();
      formMethods.setValue('image', { ...uploadedImage, file, src, fileExtension });
      setUploadedDoeImage({ name: uploadedImage.name, file, src, fileExtension });
      if (modalMode === FormMode.Edit) {
        setIsFileChanged(true);
      }
    }
  };

  const getModalTitle = () => {
    switch (modalMode) {
      case FormMode.New:
        return 'Add Image';
      case FormMode.Edit:
        return `Edit Image ID ${persistentImage?.id}`;
      case FormMode.View:
        return `View Image ID ${persistentImage?.id}`;
      default:
        return '';
    }
  };

  const resetFormFieldsOnToggle = () => {
    [DEFAULT_TEMPLATES, METADATA, DISCOUNT_COMPONENT_CHECKBOX].forEach((field) => {
      formMethods.unregister(field, undefined);
    });
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [formMethods.formState.isDirty]);

  useEffect(() => {
    if (modalMode === FormMode.Edit) {
      formMethods.reset(formMethods.getValues());
    }
  }, [modalMode]);

  useEffect(() => {
    if (!defaultTemplateToggle) {
      const atLeastOneMetadataFieldPresent =
        metadataFieldsValidation.discount ||
        metadataFieldsValidation.products ||
        metadataFieldsValidation.specialOptions;
      setIsButtonDisabled(!(formMethods.formState.isValid && atLeastOneMetadataFieldPresent));
    } else {
      setIsButtonDisabled(!formMethods.formState.isValid);
    }
  }, [metadataFieldsValidation, defaultTemplateToggle, formMethods.formState.isValid]);

  useEffect(() => {
    loadTags();
  }, []);

  const getImageFormContainer = () => {
    const { src, name, file } = uploadedDoeImage;
    formMethods.setValue(`image.file`, file);
    return (
      <ImageFormContainer key={name}>
        <ImageUploadContainer>
          <ImageContainer>
            <StyledImage src={src ?? (persistentImage?.file as string)} alt={name} crossOrigin="anonymous" />
          </ImageContainer>
          {modalMode !== FormMode.View && (
            <InputFiles
              buttonType={ButtonType.Text}
              handleFiles={(files: FileList) => onFileChange(files)}
              multiple={false}
              filesType="image/*"
            >
              Upload Image
              <InfoTooltip
                id="doeImageUploadTooltip"
                content="Image size should be smaller than 100KB. Supported file types: png, jpg, jpeg, gif."
              />
            </InputFiles>
          )}
          {(modalMode === FormMode.View || modalMode === FormMode.Edit) && (
            <FileDetailsContainer>
              <PropName>File Name</PropName>
              <FileDetails>
                <PropValue>{persistentImage.fileName}</PropValue>
                <CopyButton textToCopy={persistentImage.fileName} />
              </FileDetails>
            </FileDetailsContainer>
          )}
        </ImageUploadContainer>
        <FormContent>
          <ImageName
            register={formMethods.register}
            errors={formMethods.formState.errors}
            value={name}
            name={'image.name'}
            label="Image Name"
            placeholder="Enter"
            disabled={modalMode === FormMode.View}
            validation={{
              required: ValidationMessages.RequiredField,
              maxLength: { value: 200, message: 'Up to 200 characters' },
              validate: (value: string) => (!hasValue(value) ? ValidationMessages.RequiredField : true),
            }}
            labelIsHorizontal
          />
          {shouldShowField('image.tags') && (
            <ImageTags
              key={`${Boolean(tags.length)}`}
              control={formMethods.control}
              errors={formMethods.formState.errors}
              name={'image.tags'}
              enableTagHover={modalMode === FormMode.View ? true : false}
              label="Tags"
              placeholder="Select"
              disabled={false}
              multiple
              items={tags}
              maxItems={2}
              limit={10}
              withSearch
              withAmount
              onCreateOption={(tagName: string) => onTagCreation(tagName)}
              labelIsHorizontal
              reset
            />
          )}
          {shouldShowField('image.description') && (
            <ImageDescription
              register={formMethods.register}
              errors={formMethods.formState.errors}
              name={'image.description'}
              label="Description"
              disabled={modalMode === FormMode.View}
              placeholder="Enter"
              validation={{
                maxLength: { value: 200, message: 'Up to 200 characters' },
              }}
              rows={3}
              labelIsHorizontal
            />
          )}
          <CheckboxGroup
            control={formMethods.control}
            name={'image.selectedLanguages'}
            labelIsHorizontal
            checkboxes={languages.map((language: Language) => ({
              id: language,
              label: LanguageRecord[language],
            }))}
            defaultValue={languages}
            disabled={modalMode === FormMode.View}
            errors={formMethods.formState.errors}
            validation={{
              required: 'Must select at least one language',
            }}
          />
        </FormContent>
        <FormContent>
          <StyledControlledToggleSwitch
            key="defaultTemplateToggle"
            control={formMethods.control}
            name="image.defaultTemplateToggle"
            size="medium"
            disabled={modalMode === FormMode.View}
            label="Default Template"
            defaultValue={defaultTemplateToggle}
            onChange={(value) => {
              resetFormFieldsOnToggle();
              setDefaultTemplateToggle(value);
            }}
          />
          {shouldShowField(DEFAULT_TEMPLATES) && defaultTemplateToggle && (
            <StyledSelectbox
              name={DEFAULT_TEMPLATES}
              control={formMethods.control}
              items={Object.values(OfferTemplates)}
              placeholder="Select"
              label="Offer Template"
              reset
              multiple={true}
              disabled={modalMode === FormMode.View}
              maxItems={1}
              validation={{
                required: 'Must select at least one offer template',
              }}
              selectWidth={180}
            />
          )}
          <Metadata
            onProductSelection={onProductSelection}
            defaultTemplateToggle={defaultTemplateToggle}
            mode={modalMode as FormMode}
            shouldShowField={shouldShowField}
            specialOptionsItems={specialOptionsItems}
            setMetadataFieldsValidation={setMetadataFieldsValidation}
          />
        </FormContent>
      </ImageFormContainer>
    );
  };
  const onProductSelection = useCallback(
    async (valueKey: string, onSave: (data: any) => void, isOptional = false, setType = '', familyGroup = '') => {
      const offerProducts = formMethods.getValues(`image.metadata.${valueKey}`);
      store.dispatch(
        openModal({
          modal: Modals.ProductSetModal,
          data: { image: { ...formMethods.getValues()?.image }, mode: modalMode },
          props: {
            productSet: convertToGenericSet(offerProducts),
            onSave: (data: SetItemsSelectionFormState) =>
              onSave({
                products: Object.values(data.selectedItemsById),
                productSets: Object.values(data.selectedItemSetsById),
                excludedProductsIds: Object.keys(data.excludedItemsById).map(Number),
              }),
            onCancel: () =>
              store.dispatch(
                openModal({
                  modal: Modals.DoeImagesModal,
                  data: { image: { ...formMethods.getValues()?.image }, mode: modalMode },
                  props: { mode: FormMode.New },
                }),
              ),
            mode: FormMode.Select,
            setType,
            familyGroup,
            isOptional,
            isDoe: true,
          },
        }),
      );
    },
    [modalMode],
  );
  const onImageImpactClicked = async (img: ImageProps) => {
    const [offerImpacts, campaignImpacts] = await Promise.all([
      getOffersImpactsByImageId(img.id),
      getCampaignsImpactsByImageId(img.id),
    ]);

    if (offerImpacts.length || campaignImpacts.length) {
      const offerImpactProps = handleOfferImpactChange(offerImpacts);
      const campaignsImpactProps = handleCampaignImpactChange(campaignImpacts);
      store.dispatch(
        openModal({
          modal: Modals.ImpactModal,
          props: {
            title: 'View Usage',
            headerComponentType: HeaderComponentType.ImageUsageType,
            offerImpacts: offerImpactProps,
            campaignImpacts: campaignsImpactProps,
            entityType: 'Image',
            entityId: img.id,
            onCancel: () => store.dispatch(closeModal()),
          },
        }),
      );
    } else {
      showToast(MessageType.Info, `The image is not being used in campaigns or offers`);
    }
  };

  return (
    <StyledImagesFormModal title={getModalTitle()}>
      <FormProvider {...formMethods}>
        <ImagesFormContainer
          className={className}
          onKeyDown={(e) => preventFormSendOnEnter(e)}
          data-automation-id="images-form"
        >
          <Forms>{persistentImage ? getImageFormContainer() : null}</Forms>
          <StyledButtonsContainer>
            {modalMode === FormMode.View ? (
              <>
                <ButtonText onClick={() => (!viewOnly ? store.dispatch(closeModal()) : onClose())}>Close</ButtonText>
                {!viewOnly && (
                  <>
                    {image.isArchive ? (
                      <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin]}>
                        <ButtonOutlined onClick={() => unarchiveImageClicked(persistentImage as any)}>
                          Unarchive
                        </ButtonOutlined>
                      </RoleGuard>
                    ) : (
                      <>
                        <ButtonOutlined onClick={() => onImageImpactClicked(persistentImage as any)}>
                          Image Usage
                        </ButtonOutlined>
                        <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin]}>
                          <>
                            <ButtonOutlined onClick={() => archiveImageClicked(persistentImage as any)}>
                              Archive
                            </ButtonOutlined>
                            <ButtonContained onClick={() => setModalMode(FormMode.Edit)}>Edit</ButtonContained>
                          </>
                        </RoleGuard>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <ButtonText data-tip data-for="cancel-tooltip" onClick={() => null}>
                  Cancel
                </ButtonText>
                <Tooltip
                  id="cancel-tooltip"
                  content="Are you sure you want to cancel?"
                  onDisapproveClick={() => {
                    hideTooltip('#cancel-tooltip');
                  }}
                  onApproveClick={() => store.dispatch(closeModal())}
                />
                <ButtonContained
                  onClick={
                    modalMode === FormMode.New
                      ? formMethods.handleSubmit(onDoeImageSave)
                      : formMethods.handleSubmit(onEditImageSave)
                  }
                  disabled={
                    modalMode === FormMode.New
                      ? isButtonDisabled
                      : isButtonDisabled ||
                        (!formMethods.formState.isDirty && !isFileChanged && !persistentUpdatedImagePlu)
                  }
                >
                  Save
                </ButtonContained>
              </>
            )}
          </StyledButtonsContainer>
        </ImagesFormContainer>
      </FormProvider>
    </StyledImagesFormModal>
  );
};
export default DoeImageForm;
