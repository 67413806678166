import React, { useEffect } from 'react';
import { DiscountCondition, DiscountConditionRecord, DiscountType } from 'utils/types/offers';
import { useFormContext } from 'react-hook-form';
import { calcProductsHint } from 'utils/product';
import useDiscountType from 'hooks/use-discount-type';
import { DiscountTypeRecord, TemplateProps } from '../Template.consts';
import { StyledLabel, StyledForSelectbox, StyledLightLabel, ProductPicker, EnterValueWrapper } from '../Template.style';
import getProductsAction from '../shared/ProductsActionCondition';
import { Currency } from '../shared/Currency/Currency';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { LineWrapper, OfferInfoWrapper, ProductLine } from '../../../OfferForm.style';
import { getIsControlWithError } from 'utils/form';
import OfferTemplateTextField from 'components/shared/textField/OfferTemplateTextField';

export const SingleItemDiscount = ({ disabled, onProductSelection, offerSource, mode, offerID, onProductPreviewClick }: TemplateProps) => {
  const {
    control,
    register,
    getValues,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext();

  const templateValuesDiscountConditionPath = 'versions.0.templateValues.discountCondition';
  const { discountType, isFree, isPercent, shouldDisplayCurrency } = useDiscountType();
  const [discountCondition, products] = watch([
    templateValuesDiscountConditionPath,
    'versions.0.templateValues.products',
  ]);
  const isDoeEditMode = offerSource === OfferSource.DOE && mode === 'edit';

  useEffect(() => {
    if (!isFree) {
      setValue(templateValuesDiscountConditionPath, DiscountCondition.None);
    } else {
      resetField(templateValuesDiscountConditionPath, {
        defaultValue:
          discountCondition === DiscountCondition.None ? DiscountCondition.MinimumPurchase : discountCondition,
      });
    }
  }, [isFree]);
  const doeOffer = offerSource === OfferSource.DOE;
  const editMode = mode === 'edit';
  const doeEdit = doeOffer && editMode;
  return (
    <OfferInfoWrapper disabled={disabled}>
      <LineWrapper gap={8}>
        <StyledLabel>Buy</StyledLabel>
        <ProductPicker disabled={disabled}>
          <span>1</span>
          <ProductLine>
          {calcProductsHint(products)}
          </ProductLine>
          {getProductsAction(
            disabled,
            products,
            getValues,
            'Select Products',
            'products',
            (data: any) => ({
              products: data,
            }),
            onProductSelection,
            null,
            null,
            null,
            offerID,
            onProductPreviewClick
          )}
          <input
            type="hidden"
            {...register('versions.0.templateValues.products', {
              value: products,
              shouldUnregister: true,
              required: true,
            })}
          />
        </ProductPicker>
      </LineWrapper>
      <LineWrapper gap={8}>
        <StyledLabel disabled={disabled}>For</StyledLabel>
        <StyledForSelectbox
          name="versions.0.templateValues.discountType"
          control={control}
          disabled={disabled || isDoeEditMode}
          validation={{
            required: true,
          }}
          items={
            Object.entries(DiscountTypeRecord()).map(([key, value]) => {
              return {
                id: key,
                name: value,
              };
            }) as any[]
          }
          defaultValue={DiscountType.MoneyOff}
          initialSelectedItems={discountType ? [discountType] : [DiscountType.MoneyOff]}
          selectWidth={discountCondition === DiscountCondition.MinimumPurchase ? 120 : 164}
          version='offer-form'
        />
        {!isFree && (
          <EnterValueWrapper width={136} disabled={disabled} errors={getIsControlWithError(`versions.0.templateValues.discountValue`, errors)}>
          {shouldDisplayCurrency && <Currency />}
            <OfferTemplateTextField
              disabled={disabled || isDoeEditMode}
              register={register}
              validation={{
                required: true,
                min: isPercent ? 1 : 0.01,
                max: isPercent ? 99 : null,
                pattern: isPercent ? /^([1-9][0-9]?|99)$/ : /^\d+(?:\.\d{1,2})?$/,
                validate: (v: string) =>
                  (!Number.isNaN(Number(v)) && Number(v) >= 0.01) || (isPercent && Number(v) < 100 && Number(v) >= 1),
              }}
              errors={errors}
              name="versions.0.templateValues.discountValue"
              placeholder="Enter value"
              selectWidth={120}
            />

          </EnterValueWrapper>
        )}
        {isFree ? (
          <>
            <StyledLightLabel>with</StyledLightLabel>
            <StyledForSelectbox
              name="versions.0.templateValues.discountCondition"
              control={control}
              disabled={disabled || isDoeEditMode}
              validation={{
                required: true,
              }}
              items={
                Object.entries(DiscountConditionRecord)
                  .filter(([key]) => key !== DiscountCondition.None && key !== DiscountCondition.WithPurchaseOf)
                  .map(([key, value]) => {
                    return {
                      id: key,
                      name: value,
                    };
                  }) as any[]
              }
              defaultValue={DiscountCondition.MinimumPurchase}
              initialSelectedItems={
                discountCondition && discountCondition !== DiscountCondition.None
                  ? [discountCondition]
                  : [DiscountCondition.MinimumPurchase]
              }
              selectWidth={150}
              version='offer-form'
            />
            {discountCondition === DiscountCondition.MinimumPurchase && (
              <>
                <StyledLightLabel>of</StyledLightLabel>
                <EnterValueWrapper width={136} disabled={disabled} errors={getIsControlWithError(`versions.0.templateValues.conditionValue`, errors)}>
                <Currency />
                <OfferTemplateTextField
                    disabled={disabled || isDoeEditMode}
                  register={register}
                  validation={{
                    required: true,
                    min: 0.01,
                    max: null,
                    pattern: /^\d+(?:\.\d{1,2})?$/,
                    validate: (v: string) => !Number.isNaN(Number(v)) && Number(v) >= 0.01,
                  }}
                  errors={errors}
                  name="versions.0.templateValues.conditionValue"
                  placeholder="Enter value"
                  selectWidth={120}
                />
                </EnterValueWrapper>
              </>
            )}
          </>
        ) : null}
      </LineWrapper>
    </OfferInfoWrapper>
  );
};
