import styled from '@emotion/styled';
import { Icon, IconProps } from 'components/shared/icon';
import { defaultTheme } from 'styles/themes/defaultTheme';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { ButtonOutlined, NewButtonOutlined } from 'components/shared/button';
import typography, { newTypography } from 'styles/typography';
import zIndex from 'styles/zIndex';

export const StyledButton = styled(ButtonOutlined)`
  padding: ${typography.unit * 2}px;
`;

export const NewStyledButton = styled(NewButtonOutlined)`
  padding: ${newTypography.unit * 2}px;
  height: 32px;
  border: 1.26px solid ${newGlobalTheme.colors.button.download.border};
  font-weight: 400;
  &:hover {
    border-color: ${newGlobalTheme.colors.global.tabNavigation};
  }
 &:focus-visible {
    outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
    outline-offset: 0px;
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }
`;

export const StyledDownload = styled(Icon)<IconProps>`
  fill: ${defaultTheme.colors.global.inputPrimary};
  width: 16px;
`;

export const NewStyledDownload = styled(Icon)<IconProps>`
  fill: ${newGlobalTheme.colors.global.inputPrimary};
  width: 16px;
`;
