import styled from '@emotion/styled';
import CampaignModal from 'components/shared/modal/CampaignModal';
import typography from 'styles/typography';
import { StyledSectionTitle } from 'pages/shared/shared.style';

export const StyledModal = styled(CampaignModal)`
  height: 82vh;
  width: 31vw;
  padding:36px;
  font-family: 'Speedee';
  h3 {
    font-family: 'Speedee';
    font-size: 28px;
    font-weight: 700;
    line-height: 22.83px;
    letter-spacing: -0.03em;
    text-align: left;
    overflow: visible;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  margin-right:-36px;

  ::-webkit-scrollbar {
    width: 21px;
  }

  ::-webkit-scrollbar-thumb {
    border: 7px transparent solid;
    background-clip: padding-box;
    border-radius: 10px;
  }
`;

export const StyledSectionTitleWithLoader = styled(StyledSectionTitle)`
  display: inline-flex;
  align-items: center;

  span {
    margin-left: 4px;
  }

  div {
    margin-left: 4px;
  }
`;
