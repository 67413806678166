import styled from '@emotion/styled';
import { Icon, IconProps } from 'components/shared/icon';
import { NewButtonText } from 'components/shared/button';
import { appTheme } from 'styles/themes/defaultTheme';
import typography from 'styles/typography';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  line-height: 15.17px;
  font-size: 12px;

`;

export const Title = styled.div<{ disabled: boolean; theme?: appTheme }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
  flex: 1;
  color: ${({ disabled, theme }) => (disabled ? newGlobalTheme.colors.text.disabled : newGlobalTheme.colors.text.primary)};
`;

export const Action = styled.span<{ disabled: boolean }>`
  display: flex;
  width: auto;
  justify-content: end;
  margin-left: 8px;
`;

export const StyledIconButtonText = styled(NewButtonText)`
  width: 16px;
  height: 16px;
`;

export const StyledIcon = styled(Icon)<IconProps & { disabled: boolean }>`
  fill: ${({ disabled, theme }) => (disabled ? theme.colors.global.inputDisabled : theme.colors.global.inputPrimary)};
`;

export const StyledSetTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledWholeGroupIcon = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.text.secondary};
  width: 14px;
  margin-left: ${typography.unit}px;
  vertical-align: middle;
`;

export const SetNameContainer = styled.span`
  overflow: hidden;
  min-width: 279px;
  max-width: 290px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SetChildrenCounter = styled.span`
`;
