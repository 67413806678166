import React, { useEffect, useMemo } from 'react';
import { ActionType, FormMode, GenericItem, GenericSet, isViewMode, ProductSetType } from 'utils/types';
import { hasValue } from 'utils/text';
import { AdornmentPosition } from 'components/shared/textField/TextField.consts';
import SetItemsSelectionFormFooter from 'pages/shared/setItemsSelectionForm/setItemsSelectionFormFooter/SetItemsSelectionFormFooter';
import { FormProvider, useForm } from 'react-hook-form';
import { buildObjectById } from 'utils/mapping';
import merge from 'lodash/merge';
import AvailableSetItemsListPanel from 'pages/shared/setItemsSelectionForm/setItemsListPanel/availableSetItemsListPanel/AvailableSetItemsListPanel';
import SelectedSetItemsListPanel from 'pages/shared/setItemsSelectionForm/setItemsListPanel/selectedSetItemsListPanel/SelectedSetItemsListPanel';
import { isInArray } from 'utils/array';
import { SetItemsSelectionFormProps, SetItemsSelectionFormState } from './SetItemsSelectionForm.consts';
import {
  Container,
  SetItemsSelectionFormContainer,
  SetName,
  StyledNameInputContainer,
} from './SetItemsSelectionForm.style';
import {
  getAllItemsRecursively,
  getInitialSelectedItems,
  getInitialSelectedSets,
  getSetItems,
  getSetNestedSetsRecursively,
  getSetsFringesItems,
  getSetsNestedSetsRecursively,
  getTotalSelectedItems,
  hasNestedSet,
  hasOnlyItemsAccordions,
  isGenericSet,
} from './SetItemsSelectionForm.utils';
import { ValidationMessages } from 'utils/types/common';
import SetItemsSelectionFormHeader from './setItemsSelectionFormFooter/SetItemsSelectionFormHeader';

const SetItemsSelectionForm = ({
  itemSet,
  setOf,
  formMode,
  searchPlaceholder,
  onSave,
  itemSetTitleFormatter,
  onViewUsage,
  onDelete,
  onEdit,
  onDuplicate,
  onClose,
  itemFormatter,
  availableAccordionsProps,
  selectedAccordionsProps,
  fetchRootSetsByIds,
  fetchTotalAvailableItems,
  forcedExcludedSets,
  forcedExcludedItems,
  supportSetFringes = false,
  supportSingleItem = true,
  isOptional = false,
  setType,
  isNonProduct = false,
  isDoe,
  offerPreview
}: SetItemsSelectionFormProps) => {
  const formMethods = useForm<SetItemsSelectionFormState>({
    mode: 'onChange',
    defaultValues: {
      id: itemSet?.id,
      name: itemSet?.name,
      selectedItemsById: getInitialSelectedItems(
        itemSet,
        setOf,
        !isInArray([FormMode.Select, FormMode.SelectionView], formMode),
      ),
      selectedItemSetsById: getInitialSelectedSets(itemSet?.sets),
      excludedItemsById: buildObjectById(itemSet?.excludedItems),
      excludedItemSetsById: buildObjectById(itemSet?.excludedSets),
      description: itemSet?.description,
    },
  });
  const { register, getValues, watch, setValue, formState, trigger } = formMethods;
  const { errors } = formState;
  const { Select, Duplicate } = FormMode;
  const selectedItemsById = { ...watch('selectedItemsById') };
  const selectedItemSetsById = { ...watch('selectedItemSetsById') };
  const excludedItemSetsById = watch('excludedItemSetsById');
  const excludedItemsById = watch('excludedItemsById');
  const flattenSelectedItemSets = getSetsNestedSetsRecursively(Object.values(selectedItemSetsById));
  const forcedExcludedItemsIds = useMemo(
    () => new Set<number>(forcedExcludedItems?.map((item) => Number(item.id))),
    [forcedExcludedItems],
  );
  const forcedExcludedSetsIds = useMemo(
    () => new Set<number>(forcedExcludedSets?.map((set) => set.id)),
    [forcedExcludedSets],
  );

  const applyItemsToForm = () => {
    setValue(
      'selectedItemsById',
      { ...selectedItemsById },
      {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      },
    );
    trigger('selectedItemsById');
  };

  const applyItemSetsToForm = () => {
    setValue(
      'selectedItemSetsById',
      { ...selectedItemSetsById },
      {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      },
    );
    trigger('selectedItemSetsById');
  };

  const applyExcludedItemsToForm = () => {
    setValue(
      'excludedItemsById',
      { ...excludedItemsById },
      {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      },
    );
    trigger('excludedItemsById');
  };

  const applyExcludedItemSetsToForm = () => {
    setValue(
      'excludedItemSetsById',
      { ...excludedItemSetsById },
      {
        shouldDirty: true,
        shouldValidate: true,
        shouldTouch: true,
      },
    );
    trigger('excludedItemSetsById');
  };

  const handleExcludeItemsRemove = (items: GenericItem[]) => {
    items
      .filter((item) => !forcedExcludedItemsIds?.has(Number(item.id)))
      .forEach((item) => delete excludedItemsById[item.id]);
  };

  const handleExcludeItemSetsRemove = (set: GenericSet) => {
    getSetNestedSetsRecursively(set)
      .filter((s) => !forcedExcludedSetsIds?.has(s.id))
      .forEach((s) => delete excludedItemSetsById[s.id]);
  };

  const handleItemsAdd = (items: GenericItem[]) => {
    if (!items?.length) {
      return;
    }

    const selectedItemsIdsWithinSets = flattenSelectedItemSets
      ? new Set(getAllItemsRecursively(flattenSelectedItemSets, setOf).map((item) => Number(item.id)))
      : new Set<number>();

    Object.assign(selectedItemsById, {
      ...selectedItemsById,
      ...buildObjectById(items.filter((item) => !selectedItemsIdsWithinSets.has(Number(item.id)))),
    });
  };

  const handleItemsRemove = (items: GenericItem[]) => {
    items?.forEach((item) => delete selectedItemsById[item.id]);
  };

  const handleRemoveItemSetFromParentSet = (parentSet: GenericSet, setToRemove: GenericSet) => {
    delete selectedItemSetsById[parentSet.id];
    parentSet.sets
      .filter((set: GenericSet) => set.id !== setToRemove.id)
      .forEach((nestedSet) => {
        selectedItemSetsById[nestedSet.id] = nestedSet;
      });
    const items = getSetItems(parentSet, setOf, true);
    items.forEach((item) => {
      selectedItemsById[item.id] = item;
    });
  };

  const handleItemSetRemove = (set: GenericSet, removeAll1 = false) => {
    const parentSet = Object.values(selectedItemSetsById).find((s) => hasNestedSet(s, set.id));
    if (parentSet) {
      handleRemoveItemSetFromParentSet(parentSet, set);
    } else {
      getSetNestedSetsRecursively(set).forEach((nestedSet) => delete selectedItemSetsById[nestedSet.id]);
    }

    const shouldKeepRemovedSetFringes = supportSetFringes && !removeAll1 && !set.custom;
    if (shouldKeepRemovedSetFringes) {
      const fringes = getSetsFringesItems([set]);
      handleItemsAdd(fringes);
    }

    handleExcludeItemSetsRemove(set);
    const setItems = getAllItemsRecursively([set], setOf);
    handleExcludeItemsRemove(setItems);
  };

  const handleItemSetAdd = (set: GenericSet) => {
    const allSetItems = getAllItemsRecursively([set], setOf);
    const fringes = supportSetFringes ? getSetsFringesItems([set]) : [];
    const nestedSets = getSetNestedSetsRecursively(set);
    nestedSets
      .filter((nestedSet) => !nestedSet.dummy)
      .forEach((nestedSet) => delete selectedItemSetsById[nestedSet.id]);

    if (set.dummy || hasOnlyItemsAccordions(selectedAccordionsProps)) {
      set.sets?.forEach(handleItemSetAdd);
      handleItemsAdd(set[setOf]);
      handleItemsAdd(set.fringes);
    } else {
      handleItemsRemove(allSetItems);
      handleItemsRemove(fringes);
    }

    if (!set.dummy && !hasOnlyItemsAccordions(selectedAccordionsProps)) {
      selectedItemSetsById[set.id] = set;
    }
  };

  const handleCustomItemSetAdd = (set: GenericSet) => {
    const individualSelectedItemsIds = selectedItemsById
      ? new Set(Object.keys(selectedItemsById).map((id) => Number(id)))
      : new Set<number>();

    const selectedItemsIdsWithinSets = flattenSelectedItemSets
      ? new Set(getAllItemsRecursively(flattenSelectedItemSets, setOf).map((item) => Number(item.id)))
      : new Set<number>();

    const allSelectedItemsIds = new Set([
      ...Array.from(individualSelectedItemsIds),
      ...Array.from(selectedItemsIdsWithinSets),
    ]);

    handleItemsAdd(set[setOf].filter((item) => !allSelectedItemsIds.has(Number(item.id))));
    if (formMode !== FormMode.Select) {
      set.sets?.forEach(handleItemSetAdd);
    } else {
      handleItemSetAdd(set);
    }
  };

  const handleItemRemove = (item: GenericItem) => {
    if (selectedItemsById[item.id]) {
      delete selectedItemsById[item.id];
    } else {
      const flatSelectedItemSets = getSetsNestedSetsRecursively(Object.values(selectedItemSetsById));
      const setOfItem = flatSelectedItemSets.find((set) =>
        getSetItems(set, setOf, true)?.some((i) => i.id === item.id),
      );
      if (setOfItem) {
        handleItemSetRemove(setOfItem, true);
        const setItemsWithoutRemovedItem = getSetItems(setOfItem, setOf, true).filter(
          (i) => Number(i.id) !== Number(item.id),
        );
        delete selectedItemsById[item.id];
        const nestedSet = setOfItem.sets;
        Object.assign(selectedItemSetsById, merge(selectedItemSetsById, buildObjectById(nestedSet)));
        Object.assign(selectedItemsById, merge(selectedItemsById, buildObjectById(setItemsWithoutRemovedItem)));
      }
    }
  };

  const handleItemAdd = (item: GenericItem) => {
    selectedItemsById[item.id] = item;
  };

  const onExcludeItemClick = (actionType: ActionType, item: GenericItem, applyToForm = true) => {
    if (actionType === ActionType.AddExcluded) {
      excludedItemsById[item.id] = item;
    } else {
      handleExcludeItemsRemove([item]);
    }

    if (applyToForm) {
      applyExcludedItemsToForm();
    }
  };

  const onExcludeSetClick = (actionType: ActionType, set: GenericSet, applyToForm = true) => {
    if (actionType === ActionType.AddExcluded) {
      const allSetItems = getAllItemsRecursively([set], setOf);
      handleExcludeItemSetsRemove(set);
      handleExcludeItemsRemove(allSetItems);
      excludedItemSetsById[set.id] = set;
    } else {
      handleExcludeItemSetsRemove(set);
    }

    if (applyToForm) {
      applyExcludedItemsToForm();
      applyExcludedItemSetsToForm();
    }
  };

  const onItemActionClick = (actionType: ActionType, item: GenericItem, applyToForm = true) => {
    if (actionType === ActionType.Add) {
      handleItemAdd(item);
    } else {
      handleItemRemove(item);
    }

    if (applyToForm) {
      applyItemsToForm();
      applyItemSetsToForm();
    }
  };

  const onItemSetActionClick = (actionType: ActionType, set: GenericSet, applyToForm = true) => {
    if (actionType === ActionType.Add) {
      if (set.custom) {
        handleCustomItemSetAdd(set);
      } else {
        handleItemSetAdd(set);
      }
    } else {
      handleItemSetRemove(set,true);
    }

    if (applyToForm) {
      applyItemsToForm();
      applyItemSetsToForm();
      applyExcludedItemsToForm();
      applyExcludedItemSetsToForm();
    }
  };

  const removeAll = (payload: (GenericItem | GenericSet)[]) => {
    payload.forEach((obj) => {
      if (isGenericSet(obj, setOf)) {
        handleItemSetRemove(obj, true);
      } else {
        delete selectedItemsById[obj.id];
      }
    });

    applyItemsToForm();
    applyItemSetsToForm();
  };

  const removeSelectedItemsIncludedInSelectedSets = () => {
    const allSetItems = getAllItemsRecursively(Object.values(selectedItemSetsById), setOf, supportSetFringes);
    handleItemsRemove(allSetItems);
  };

  const selectAll = (payload: (GenericItem | GenericSet)[]) => {
    payload.forEach((obj) => {
      if (isGenericSet(obj, setOf)) {
        onItemSetActionClick(ActionType.Add, obj, false);
      } else {
        onItemActionClick(ActionType.Add, obj, false);
      }
    });

    removeSelectedItemsIncludedInSelectedSets();
    applyItemsToForm();
    applyItemSetsToForm();
  };

  const validateSetName = (value: string) => {
    if (!hasValue(value)) {
      return ValidationMessages.RequiredField;
    }
  };

  const viewMode = isViewMode(formMode);
  const isSelectedMode = formMode === Select;

  useEffect(() => {
    if (formMode === Duplicate) {
      setValue('id', null, { shouldDirty: true, shouldValidate: true });
      setValue('name', `${itemSet?.name} (Duplicate)`, { shouldDirty: true, shouldValidate: true });
    }
  }, [formMode]);

  useEffect(() => {
    forcedExcludedSets?.forEach((set) => onExcludeSetClick(ActionType.AddExcluded, set, false));
    applyExcludedItemSetsToForm();
  }, [forcedExcludedSets]);

  useEffect(() => {
    forcedExcludedItems?.forEach((item) => onExcludeItemClick(ActionType.AddExcluded, item, false));
    applyExcludedItemsToForm();
  }, [forcedExcludedItems]);

  return (
    
    <FormProvider {...formMethods}>
      {viewMode  && (
      <SetItemsSelectionFormHeader
        formMode={formMode}
        formState={formState}
        onSave={() => onSave(getValues())}
        onDelete={onDelete}
        onViewUsage={onViewUsage}
        onEdit={onEdit}
        onDuplicate={onDuplicate}
        onClose={onClose}
        isReadOnly={itemSet?.isReadOnly}
        isNonProduct={isNonProduct}
      />
      )
    }
      <Container offerPreview={offerPreview}>
       
        {!viewMode && !isSelectedMode && (
          <StyledNameInputContainer>
            <SetName
              register={register}
              name="name"
              label="Set Name"
              debounceTime={300}
              adornmentPosition={AdornmentPosition.End}
              placeholder="Enter"
              errors={errors}
              validation={{
                required: ValidationMessages.RequiredField,
                maxLength: { value: 100, message: 'Up to 100 characters' },
                validate: validateSetName,
              }}
              disabled={isNonProduct}
            />
          </StyledNameInputContainer>
        )}
        <SetItemsSelectionFormContainer offerPreview={offerPreview}>
          {!viewMode && (
            <AvailableSetItemsListPanel
              data-automation-id="available-items-container"
              setOf={setOf}
              searchPlaceholder={searchPlaceholder}
              onItemSelect={supportSingleItem && onItemActionClick}
              onItemSetActionClick={onItemSetActionClick}
              itemSetTitleFormatter={itemSetTitleFormatter}
              selectAll={selectAll}
              accordions={availableAccordionsProps}
              itemFormatter={itemFormatter}
              fetchTotalAvailableItems={fetchTotalAvailableItems}
              fetchRootSetsByIds={fetchRootSetsByIds}
              forcedExcludedSetsIds={forcedExcludedSetsIds}
              forcedExcludedItemsIds={forcedExcludedItemsIds}
              supportSetFringes={supportSetFringes}
              setType={setType}
              isDoe={isDoe}
            />
          )}
          {!isOptional && (
            <input
              type="hidden"
              {...register('selectedItemSetsById', {
                value: selectedItemSetsById,
                shouldUnregister: true,
                required: true,
                validate: () => {
                  const nonExcludedItems = getTotalSelectedItems(
                    Object.values(selectedItemSetsById),
                    Object.values(selectedItemsById),
                    Object.values(excludedItemSetsById),
                    Object.values(excludedItemsById),
                    setOf,
                    supportSetFringes,
                  );

                  return nonExcludedItems === 0
                    ? `At least one ${setOf.slice(0, -1)} or ${setOf.slice(0, -1)} set should be selected`
                    : null;
                },
              })}
            />
          )}
          <SelectedSetItemsListPanel
            data-automation-id="selected-items-container"
            setOf={setOf}
            searchPlaceholder={searchPlaceholder}
            onItemSetActionClick={!viewMode && onItemSetActionClick}
            itemSetTitleFormatter={itemSetTitleFormatter}
            onItemSelect={!viewMode && onItemActionClick}
            removeAll={!viewMode && removeAll}
            accordions={selectedAccordionsProps}
            itemFormatter={itemFormatter}
            fetchRootSetsByIds={fetchRootSetsByIds}
            onExcludeItemClick={formMode === FormMode.Select && onExcludeItemClick}
            onExcludeSetClick={formMode === FormMode.Select && onExcludeSetClick}
            formMode={formMode}
            forcedExcludedSetsIds={forcedExcludedSetsIds}
            forcedExcludedItemsIds={forcedExcludedItemsIds}
            supportSetFringes={supportSetFringes}
            isDoe={isDoe}
          />
        </SetItemsSelectionFormContainer>
        <SetItemsSelectionFormFooter
          formMode={formMode}
          formState={formState}
          onSave={() => onSave(getValues())}
          onDelete={onDelete}
          onViewUsage={onViewUsage}
          onEdit={onEdit}
          onDuplicate={onDuplicate}
          onClose={onClose}
          isReadOnly={itemSet?.isReadOnly}
          isNonProduct={isNonProduct}
        />
      </Container>
    </FormProvider>
  );
};

export default SetItemsSelectionForm;
