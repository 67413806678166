import styled from '@emotion/styled';
import { Icon, IconProps } from 'components/shared/icon';
import { defaultTheme } from 'styles/themes/defaultTheme';
import typography, { newTypography } from 'styles/typography';
import { AddNewButtonContained, ButtonContained} from 'components/shared/button';

export const StyledButton = styled(ButtonContained)`
  padding: ${typography.unit * 3}px;
`;

export const NewStyledButton = styled(AddNewButtonContained)`
  padding: ${newTypography.unit * 3}px;
  font-weight: 400;
`;

export const OfferNewStyledButton = styled(AddNewButtonContained)`
  padding: ${newTypography.unit * 3}px;
  font-weight: 400;
`;

export const StyledPlus = styled(Icon)<IconProps>`
  fill: ${defaultTheme.colors.text.white};
  width: 10px;
`;
