import styled from '@emotion/styled';
import { newTypography } from 'styles/typography';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { StyledWithThemeProps } from 'utils/types';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const StyledDataGridTable = styled(DataGridPro)<StyledWithThemeProps & { disableSorting?: boolean }>`
  font-family: ${newTypography.primaryFont} !important;
  color: ${newGlobalTheme.colors.text.primary} !important;

  div .MuiDataGrid-footerContainer {
    display: none;
  }

  .MuiDataGrid-main {
    .MuiDataGrid-viewport {
      overflow: initial;
    }

    .MuiDataGrid-columnSeparator,
    .MuiDataGrid-menuIcon {
      display: none;
    }

    .MuiDataGrid-columnHeaderTitle {
      font-weight: 500;
    }

    .MuiDataGrid-row.Mui-selected {
      background-color: initial;
    }

    .MuiDataGrid-cell {
      padding: 0 ${newTypography.unit * 6}px;
      background-color: #FFFFFF;
      border-bottom: 0;

      &:focus-within,
    }

    .MuiDataGrid-columnHeaders {
      background-color: #FFFFFF;
      &:focus-within,
      &:focus {
        outline: none;
      }
    }

    .MuiDataGrid-columnHeaderTitleContainer {
       padding: 0 ${newTypography.unit * 4}px;
      ${({ disableSorting }) =>
        disableSorting &&
        `
        cursor: default;
        .MuiDataGrid-iconButtonContainer {
          display: none;
        }
      `}
    }

      .MuiDataGrid-row {
        margin-bottom: 3px;
      }
  }
`;
