import styled from '@emotion/styled';
import TextField from 'components/shared/textField/TextField';
import typography, { newTypography } from '../../../styles/typography';

export const SetItemsSelectionFormContainer = styled.div<{offerPreview? : boolean}>`
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: space-between;
  overflow: visible;
  gap: 20px;
  margin-bottom: 20px;
  ${({offerPreview}) => offerPreview && 'margin-bottom: 18px;'}
`;

export const Container = styled.div<{offerPreview? : boolean}>`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledNameInputContainer = styled.div`
  display: flex;
  margin-bottom: ${typography.unit * 4}px;
`;

export const SetName = styled(TextField)`
  width: 473px;
  height: 31px;
`;
