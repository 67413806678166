import styled from '@emotion/styled';
import typography, { newTypography } from 'styles/typography';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { Color, borderColor } from 'components/shared/dragAndDrop/DragAndDrop';


export const StyledItems = styled.div<{color: Color, border: borderColor}>`
  width: 255px;
  height: 100px;
  padding: 29px 27px 21px 28px; 
  background: ${({color}) => color ? newGlobalTheme.colors.dashboard[color] : newGlobalTheme.colors.dashboard.grey};
  border-radius: 8px;
  transition: all ${typography.transition}s;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: ${newTypography.primaryFont};
  border-top: 6px solid ${({border}) => border ? newGlobalTheme.colors.dashboard.border[border] : newGlobalTheme.colors.dashboard.border.darkGrey};
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px; 

`;

export const StyledItemContent = styled.div`
  font-family: ${newTypography.primaryFont};
  display: flex;
  flex-direction: column;
  align-items: center; 
  width: 100%; 
  gap: 3px; 
`

export const PriorityText = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  width: 200px;
  text-align: center; 
  height: 30px;
  width: 200px;
  flex-shrink: 0;
  line-height: 22.834px;

`

export const IDText = styled.div`
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  height: 43px;
  width: 102px;
  flex-shrink: 0;
  line-height: 22.834px;
  text-align: center; 

`
