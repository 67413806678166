import styled from '@emotion/styled';
import { NewButtonText } from 'components/shared/button';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import OfferVersionItem from '../offerVersionItem/OfferVersionItem';

export const SectionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width:530px;
  flex-direction:column;
  gap: 8px; 
  padding-left: 4px; 
`;

export const SelectOfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding:0px;
`;

export const StyledButtonText = styled(NewButtonText)`
  font-size: 14px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  color:${newGlobalTheme.colors.global.textSubheading}; 
  font-family: Speedee; 
  height: 23px; 
`;

export const StyledOfferVersionItem = styled(OfferVersionItem)`
width:530px;
height:188px;
`;
