import React from 'react';
import { ToggleSwitchProps } from './ToggleSwitch.consts';
import { Ball, NewStyledLabel, Slide, ToggleSwitchContainer } from './NewToggleSwitchCampaign.styles';

const ToggleSwitch = ({ label, checked, disabled, size = 'medium', className, onClick, labelPosition = 'before' }: ToggleSwitchProps) => {
  return (
    <ToggleSwitchContainer disabled={disabled} className={className} onClick={onClick} data-automation-id="toggle"
    tabIndex={0}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const syntheticClick = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        });
        (e.target as HTMLElement).dispatchEvent(syntheticClick);
      }
    }}>

      {label && labelPosition === 'before' && <NewStyledLabel disabled={disabled} labelPosition={labelPosition}>{label}</NewStyledLabel>}
      <Slide checked={checked} disabled={disabled} size={size}>
        <Ball checked={checked} disabled={disabled} size={size} />
      </Slide>
      {label && labelPosition === 'after' && <NewStyledLabel disabled={disabled} labelPosition={labelPosition}>{label}</NewStyledLabel>}
    </ToggleSwitchContainer>
  );
};

export default ToggleSwitch;
