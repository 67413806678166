const typography = {
  primaryFont: 'Proxima Nova, -apple-system, sans-serif',
  secondaryFont: 'Montserrat',
  fontSize: 14,
  unit: 4,
  transition: 0.3,
  inputComponentHeight: 28,
  borderRadius: 3,
};

export const newTypography = {
  primaryFont: 'Speedee, -apple-system, sans-serif',
  secondaryFont: 'Montserrat',
  fontSize: 18,
  smallFontSize: 12,
  secondaryFontSize: 24,
  unit: 4,
  transition: 0,
  inputComponentHeight: 28,
  borderRadius: 6,
};

export default typography;
