import { appTheme } from 'styles/themes/defaultTheme';
import { Control } from 'react-hook-form/dist/types';
import { FieldErrors } from 'react-hook-form';
import { createTheme } from '@mui/material/styles';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';

export interface TimePickerProps {
  validation?: Record<string, unknown>;
  errors?: FieldErrors | string;
  control?: Control;
  name: string;
  placeholder?: string;
  withAmPm?: boolean;
  label?: string;
  labelIsHorizontal?: boolean;
  disabled?: boolean;
  defaultValue?: Date;
  changeHandler?: (val: any) => void;
  className?: string;
  theme?: appTheme;
}

export interface StyleTimePickerProps {
  disabled?: boolean;
  withAmPm?: boolean;
  error?: boolean;
  labelIsHorizontal?: boolean;
  theme?: appTheme;
}

export const materialTheme = createTheme({
  palette: {
    primary: { main: newGlobalTheme.colors.global.brandMain },
  },
  typography: {
    fontSize: 14,
    fontFamily: `${newTypography.primaryFont} !important`,
  },
  spacing: 1,
  
});
