export enum Error {
  Default,
  Unauthorized = 'ERROR1',
  WrongCreds = 'ERROR2',
}

export const ErrorMessages: Record<Error, string> = {
  [Error.Default]: 'Internal Server Error, please contact support',
  [Error.Unauthorized]: 'User name is undefined in VCE. Please contact your administrator',
  [Error.WrongCreds]: 'Username or password are wrong',
};

export interface LoginReducerState {
  formState: FormState;
  session?: string;
  error?: string;
  username?: string;
  autoFocus?: boolean;
}

export enum FormState {
  Login,
  ForgotPassword,
  ResetPassword,
}

export type LoginActionType = 'SET_FORM' | 'SUBMIT_LOGIN' | 'SET_ERROR';

export interface LoginAction {
  type: LoginActionType;
  payload: any;
}

export type LoginReducer = (state: LoginReducerState, action: LoginAction) => LoginReducerState;

export const loginReducer: LoginReducer = (state: LoginReducerState, action: LoginAction): LoginReducerState => {
  switch (action.type) {
    case 'SET_FORM':
      return { ...state, formState: action.payload };
    case 'SUBMIT_LOGIN':
      if (action.payload.session) {
        state.formState = FormState.ResetPassword;
      }
      return { ...state, ...action.payload };
    case 'SET_ERROR': 
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
