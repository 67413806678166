import styled from '@emotion/styled';
import { newTypography } from 'styles/typography';
import { OverviewBase, StatusSmallBox } from 'pages/dashboard/Dashboard.style';

export const RedemptionsOverviewStyle = styled(OverviewBase)`
  width: 288px;
  height: 291px;
  padding: ${newTypography.unit * 5}px ${newTypography.unit * 7}px;
  margin-bottom: ${newTypography.unit * 8}px;
`;

export const StyledStatusSmallBox = styled(StatusSmallBox)`
  cursor: auto;
  &:hover {
    box-shadow: none;
  }
`;

export const RedemptionOverviewLabelText = styled.div`
  padding-top: 3px;
`;
