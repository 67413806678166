import styled from '@emotion/styled/macro';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';
import zIndex from 'styles/zIndex';
import { StyledWithThemeProps } from 'utils/types';

export const NewDropdownButton = styled.div<StyledWithThemeProps>`
  background-color: transparent;
  cursor: auto;
  transition: border-color 0.3s ease;
  justify-content: space-between;
  padding-right: 20px;
  user-select: none;
  font-weight: 400;
  svg {
    margin-top: 6px;
    fill: #5E5E5E;
    width: 10px;
    position: absolute;
    margin-left: 5px;
  }
`;

export const DropdownContent = styled.div<StyledWithThemeProps>`
  position: absolute;
  width: 230px;
  height: 150px;
  margin: 0;
  background: white;
  overflow: auto;
  padding: 0;
  left: -20px;
  border: ${({ theme }) => `1px solid ${newGlobalTheme.colors.global.inputDisabled}`};
  border-radius: ${newTypography.borderRadius / 2}px;
  box-shadow: ${({ theme }) => newGlobalTheme.colors.global.boxShadow};
  z-index: ${zIndex.dropdown};
  &:disabled {
    pointer-events: none;
    color: ${({ theme }) => newGlobalTheme.colors.global.inputDisabled};
  }
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.03em;
`;

export const DropdownItem = styled.div<StyledWithThemeProps>`
  padding: ${newTypography.unit * 1}px ${newTypography.unit * 3}px;
  cursor: default;
  font-size: 14px;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

export const StyledRedemptionOverviewDropdown = styled.div`
  margin-top: 3px;
  position: relative;
  flex-grow: 1;
  .open {
    ${DropdownContent} {
      display: block;
    }
  }
`;
