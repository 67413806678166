import { CampaignFilterType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { isPositiveInteger } from 'utils/text';
import { ApprovalStatus } from './types';
import { OfferFilters } from 'pages/offers/offerManagement/Offers.const';
import { CampaignBulkAction } from 'pages/campaigns/campaignManagement/components/campaignBulkActions/CampaignBulkActions.consts';

const FILTER_TYPE_NATIONAL = 'national';
const FILTER_TYPE_ZONE = 'zone';
const FILTER_TYPE_ID = 'id';
const FILTER_TYPE_OFFER_BANK = 'offerBank';

export const buildBaseAndFilters = (
  filtersRecord: {
    [key: string]: { serverFilter: string; operation: string };
  },
  initialFilters: { [key: string]: any },
) => {
  const filters: { [key: string]: any[] } = { AND: [] };
  Object.keys(filtersRecord).forEach((filterKey) => {
    if (initialFilters[filterKey]?.length) {
      filters.AND.push({
        [filtersRecord[filterKey].serverFilter]: {
          [filtersRecord[filterKey].operation]: initialFilters[filterKey],
        },
      });
    }
  });
  if (initialFilters[FILTER_TYPE_NATIONAL]) {
    if (initialFilters[FILTER_TYPE_NATIONAL][0] === 'true') {
      filtersRecord[FILTER_TYPE_ZONE].operation = 'is_null';
      filters.AND.push({
        [filtersRecord[FILTER_TYPE_ZONE].serverFilter]: {
          [filtersRecord[FILTER_TYPE_ZONE].operation]: true,
        },
      });
    }
  }
  return filters;
};

export const buildOSBaseAndFilters = (
  filtersRecord: { [key: string]: string },
  initialFilters: { [key: string]: any },
) => {
  const filters: any = { bool: { must: [] } };
  Object.keys(filtersRecord).forEach((filterKey) => {
    const shouldObj: any = [];
    if (initialFilters[filterKey]?.length) {
      if (filterKey === FILTER_TYPE_ID) {
        // Collect all filter values into an array
        const filterValues = initialFilters[filterKey].map((currFilterValue: string | number) => currFilterValue);
        // Create the new 'terms' query
        shouldObj.push({
          terms: {
            [filtersRecord[filterKey]]: filterValues,
          },
        });
      } else {
        initialFilters[filterKey].forEach((currFilterValue: string | number) => {
          if (filterKey === FILTER_TYPE_OFFER_BANK) {
            const filterKeyName = `${filtersRecord[filterKey]}.keyword`;
            shouldObj.push({
              term: {
                [filterKeyName]: currFilterValue,
              },
            });
          } else {
            shouldObj.push({
              match: {
                [filtersRecord[filterKey]]: currFilterValue,
              },
            });
          }
        });
      }
      if (filterKey === FILTER_TYPE_ZONE && initialFilters[FILTER_TYPE_NATIONAL] === 'true') {
        shouldObj.push({
          bool: {
            must: {
              bool: {
                must: [{ exists: { field: 'localSchedule.period.id' } }],
                must_not: [{ exists: { field: 'localSchedule.zone.id' } }],
              },
            },
          },
        });
      }
      const boolObj = shouldObj.length
        ? { [filterKey === CampaignFilterType.ExcludedExternalIds ? 'must_not' : 'should']: shouldObj }
        : null;
      if (boolObj) {
        filters.bool.must.push({ bool: boolObj });
      }
    }
  });
  if (!initialFilters[FILTER_TYPE_ZONE] && initialFilters[FILTER_TYPE_NATIONAL] === 'true') {
    filters.bool.must.push({
      bool: {
        must: {
          bool: {
            must: [{ exists: { field: 'localSchedule.period.id' } }],
            must_not: [{ exists: { field: 'localSchedule.zone.id' } }],
          },
        },
      },
    });
  }
  return filters;
};

export const generateCampaignStatusFilters = (
  initialFilters: { [key: string]: any },
  bulkAction?: CampaignBulkAction,
) => {
  const statusFilter: any = [];
  let values = initialFilters[CampaignFilterType.Status];
  if (values.includes(ApprovalStatus.Draft)) {
    statusFilter.push({
      bool: { must: [{ match: { isDraft: true } }, { match: { isArchive: false } }] },
    });
  }
  if (values.includes(ApprovalStatus.Archived)) {
    statusFilter.push({ bool: { must: { match: { isArchive: true } } } });
  }
  if (values.includes(ApprovalStatus.PendingApproval)) {
    statusFilter.push({
      bool: {
        must: [
          { match: { isDraft: false } },
          { match: { isArchive: false } },
          { match: { status: ApprovalStatus.PendingApproval } },
        ],
      },
    });
  }
  values = values.filter(
    (f: any) => f !== ApprovalStatus.Draft && f !== ApprovalStatus.Archived && f !== ApprovalStatus.PendingApproval,
  );
  if (
    values.includes(ApprovalStatus.Active) &&
    (!bulkAction || bulkAction !== CampaignBulkAction.BulkStopAssociation)
  ) {
    values.push(ApprovalStatus.AssociationStopped);
  }
  if (values.length) {
    const status = values.map((currVal: any) => ({ match: { status: currVal } }));
    statusFilter.push({
      bool: {
        must: [
          { match: { isDraft: false } },
          { match: { isArchive: false } },
          {
            bool: {
              should: status,
            },
          },
        ],
      },
    });
  }
  return statusFilter;
};

export const generateOfferStatusFilters = (initialFilters: { [key: string]: any }) => {
  const statusFilter: any = [];
  let values = initialFilters[OfferFilters.Status];
  if (values.includes(ApprovalStatus.Draft)) {
    statusFilter.push({
      bool: { must: [{ match: { 'versions.isDraft': true } }, { match: { isArchive: false } }] },
    });
  }
  if (values.includes(ApprovalStatus.Archived)) {
    statusFilter.push({ bool: { must: { match: { isArchive: true } } } });
  }
  if (values.includes(ApprovalStatus.PendingApproval)) {
    statusFilter.push({
      bool: {
        must: [
          { match: { 'versions.isDraft': false } },
          { match: { isArchive: false } },
          { bool: { must_not: [{ exists: { field: 'versions.approvals' } }] } },
        ],
      },
    });
  }
  values = values.filter(
    (f: any) => f !== ApprovalStatus.Draft && f !== ApprovalStatus.Archived && f !== ApprovalStatus.PendingApproval,
  );
  if (values.length) {
    const status = values.map((currVal: any) => ({ match: { 'versions.approvals.approvalStatus': currVal } }));
    statusFilter.push({
      bool: {
        must: [
          { match: { 'versions.isDraft': false } },
          { match: { isArchive: false } },
          {
            bool: {
              should: status,
            },
          },
        ],
      },
    });
  }
  return statusFilter;
};

export const generateSearchFilter = (
  stringFields: string[],
  idFields: string[],
  values: any,
  templateValuesList: string[] = [],
  arrayFields: string[] = [],
  fuzzyFields: [string, number][] = [],
) => {
  const searchFilter: any = { OR: [] };
  const templateValuesPLUBuilder =
    templateValuesList?.length && isPositiveInteger(values[0])
      ? templateValuesList.map((item) => {
          return {
            BaseOfferVersion_templateValues: {
              is_in: { [item]: { pluFlatList: [parseInt(values, 10)] } },
            },
          };
        })
      : [];

  if (isPositiveInteger(values[0])) {
    searchFilter.OR.push(
      ...idFields.map((fieldName) => ({
        [fieldName]: {
          is: parseInt(values[0], 10),
        },
      })),
    );
    if (arrayFields) {
      arrayFields.forEach((f) => searchFilter.OR.push({ [f]: { in_array: values } }));
    }
  }
  searchFilter.OR = [...searchFilter.OR, ...templateValuesPLUBuilder];
  stringFields.forEach((f) => searchFilter.OR.push({ [f]: { contains: values } }));
  fuzzyFields.forEach((f) => searchFilter.OR.push({ [f[0]]: { fuzzy: [...values, f[1]] } }));
  return searchFilter;
};
