import styled from '@emotion/styled';
import Modal from 'components/shared/modal/Modal';
import NewModal from 'components/shared/modal/NewModal';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';

export const StyledProductSetModal = styled(NewModal)<{ isViewMode: boolean }>`
  width: ${({ isViewMode }) => (isViewMode ? '564px' : '1043px')};
  min-width: ${({ isViewMode }) => (isViewMode ? '564px' : '1043px')};
  height: 90vh;
  max-height: 804px;
  padding: 36px;

  .ModalHeader {
    & h3{
      ${({ isViewMode }) => isViewMode && 'width: 450px;'};
     }

  }

  color: ${newGlobalTheme.colors.text.primary};
  font-family: ${newTypography.primaryFont};;
`;
