import styled from '@emotion/styled/macro';
import { NewButtonOutlined } from 'components/shared/button';
import { NewButtonBase } from 'components/shared/button/buttonBase/ButtonBase';
import { NewTextPageTitle } from 'components/shared/text/textPageTitle/TextPageTitle';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import typography, { newTypography } from 'styles/typography';
import { StyledWithNewThemeProps } from 'utils/types';

export const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 9px;
  bottom: 0;
  padding-top: ${typography.unit * 2}px;
  margin-bottom: 25px; 
`;

export const StyledDnDItems = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%; 
  overflow-y: scroll; 
  padding: 30px 133px 0px 109px; 
  gap: 20px; 
`;


const CommonButtonStyles = `
  font-size: 16px;
  font-style: normal;
  display: flex; 
  font-weight: 700;
  border-radius: 5px; 
  align-items: center; 
  cursor: pointer; 

`

export const EditButton = styled(NewButtonOutlined)<{isSave?: boolean}>`
  ${CommonButtonStyles}
  border: 1.26px solid ${newGlobalTheme.colors.button.border.secondary};
  background: ${newGlobalTheme.colors.button.secondary}; 
  color: ${newGlobalTheme.colors.text.primary}; 
  width: 121px;
  height: 36px;
  flex-shrink: 0; 
  gap: 6.4px; 
  padding: ${({isSave}) => !isSave && '6px 0px 4px 17px; '}
  padding-right: ${({isSave}) => !isSave && '33px'};
  justify-content: ${({isSave}) => isSave ? 'center' : 'flex-start'};
  :hover {
    border: 1.256px solid ${newGlobalTheme.colors.button.border.secondary};
    color: ${newGlobalTheme.colors.text.primary};
  }
  ${({disabled}) => disabled && `
      border: none;
      background: ${newGlobalTheme.colors.button.disabledSave};
      color: ${newGlobalTheme.colors.text.disabledSave};
  `}
   &:disabled {
      border: none;
      background: ${newGlobalTheme.colors.button.disabledSave};
      color: ${newGlobalTheme.colors.text.disabledSave};
  }
`

export const CancelButton = styled(NewButtonOutlined)`
  ${CommonButtonStyles}
  border: 1.26px solid ${newGlobalTheme.colors.button.border.primary};
  background: ${newGlobalTheme.colors.button.tertiary}; 
  color: ${newGlobalTheme.colors.text.primary}; 
  justify-content: center;
  width: 121px;
  height: 36px;
  :hover {
    border: 1.256px solid ${newGlobalTheme.colors.button.border.primary}; 
    color: ${newGlobalTheme.colors.text.primary}; 
  }
  
`

export const CampaignPriorityTextTitle = styled(NewTextPageTitle)`
  margin-bottom: 21px; 
  line-height: 22px;
  width: 613px;
  flex-shrink: 0;
`

export const PriorityColumn  = styled.div`
  display: flex; 
  flex-direction: column; 
  width: 100%; 

`

export const PriorityRow = styled.div`
  display: flex; 
  flex-direction: row;
  gap: 17px;
  width: 100%;
`

export const PriorityFooterWrapper = styled.div`
  display: flex; 
  flex-direction: column; 
`

export const PriorityFooter = styled.div`
  height: 90px; 
`

export const EditTextWrapper = styled.div`
  width: 39px; 
  display: flex;
  height: 26px;
  line-height: 23px;
  align-items: center; 
  letter-spacing: -0.48px;  
`

export const CampaignPriorityHeader = styled.header<StyledWithNewThemeProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  font-family: ${newTypography.primaryFont}; 
  align-items: center; 
  margin: 0px 133px 0px 109px;
  padding-top:65px; 
  border-bottom: 3px solid ${newGlobalTheme.colors.pageHeader.border};
`;
