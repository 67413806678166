import React from 'react';
import { ButtonBaseProps, NewButtonBaseProps } from '../buttonBase/ButtonBase.consts';
import { StyledButtonBase, StyledCancelButtonBase, StyledNewButtonBase } from './ButtonOutlined.style';

export const ButtonOutlined = (props: ButtonBaseProps) => {
  return <StyledButtonBase {...props} />;
};

export const NewButtonOutlined = (props: NewButtonBaseProps) => {
  return <StyledNewButtonBase {...props} />;
};

export const CancelButtonOutlined = (props: NewButtonBaseProps) => {
  return <StyledCancelButtonBase {...props} />;
};
