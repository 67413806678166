import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import zIndex from 'styles/zIndex';
import ButtonBase, { NewButtonBase } from '../buttonBase/ButtonBase';

export const StyledButtonBase = styled(ButtonBase)`
  color: ${({ theme }) => theme.colors.global.inputPrimary};
  background-color: ${({ theme }) => theme.colors.text.white};
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  height: 32px;

  &:hover {
    border-color: ${({ theme }) => theme.colors.global.inputPrimary};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.button.disabled};
    border-color: ${({ theme }) => theme.colors.global.inputDisabled};
  }
`;

export const StyledNewButtonBase = styled(NewButtonBase)`
  color: ${newGlobalTheme.colors.global.inputPrimary};
  background-color: ${newGlobalTheme.colors.text.white};
  border: 3px solid ${newGlobalTheme.colors.global.inputDisabled};
  height: 60px;

  &:focus-visible {
    outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
    outline-offset: 4px;
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }
  
  &:hover {
    border-color: ${newGlobalTheme.colors.global.inputPrimary};
  }

  &:disabled {
    color: ${newGlobalTheme.colors.button.disabled};
    border-color: ${newGlobalTheme.colors.global.inputDisabled};
  }
`;

export const StyledCancelButtonBase = styled(NewButtonBase)`
  color: ${newGlobalTheme.colors.global.primaryTextColor};
  background-color: ${newGlobalTheme.colors.text.white};
  border: 1px solid ${newGlobalTheme.colors.text.disabledSave};
  height: 32px;
  font-size: 14px;

  &:focus-visible {
    outline: 1px solid ${newGlobalTheme.colors.global.tabNavigation};
    outline-offset: 4px;
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }
  
  &:hover {
    border-color: ${newGlobalTheme.colors.global.tabNavigation};
  }

  &:disabled {
    color: ${newGlobalTheme.colors.text.disabledSave};
    border-color: ${newGlobalTheme.colors.global.borderColor};
  }
`;
