import { SegmentIdModalProps } from './SegmentIdModal.consts';
import {
  ButtonContainer,
  Container,
  StyledListContainer,
  StyledListItem,
  StyledSegmentIdModal,
} from './SegmentIdModal.style';
import { ButtonText } from 'components/shared/button';
import { RowContainer } from 'pages/shared/setItemsSelectionForm/listItem/ListItem.style';

const SegmentIdModal = ({ segmentId, onCancel }: SegmentIdModalProps) => {
  const segmentIdList = segmentId.map((item) => (
    <StyledListItem data-automation-id="list-item" className={''}>
      <RowContainer disabled={false}>{item}</RowContainer>
    </StyledListItem>
  ));

  return (
    <StyledSegmentIdModal title="Cluster">
      <Container>
        <StyledListContainer>{segmentIdList}</StyledListContainer>
      </Container>
      <ButtonContainer>
        <ButtonText onClick={onCancel}>Close</ButtonText>
      </ButtonContainer>
    </StyledSegmentIdModal>
  );
};
export default SegmentIdModal;
