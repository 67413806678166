import { ApprovalStatus, DateTimeConfig, FormMode } from 'utils/types';
import { isUndefinedOrEmpty } from 'utils/form';
import { CampaignProps } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { convertUtcDateToTimezoneDate, convertUtcDateToTimezoneDateUsingLibrary, getMarketDatetime } from 'utils/date';
import { VoucherGroup } from 'utils/types/campaigns';

export const shouldShowFieldInViewMode = (mode: FormMode, fieldValue: any) => {
  if (typeof fieldValue === 'boolean' && mode === FormMode.View) {
    return fieldValue;
  }
  return mode !== FormMode.View || !isUndefinedOrEmpty(fieldValue);
};

export const shouldReDeploy = (campaign: CampaignProps, startTimezone: string): boolean => {
  const deploymentFailedCount = campaign?.approvals?.[0]?.deploymentFailedCount;
  return (
    deploymentFailedCount !== null &&
    campaign?.status === ApprovalStatus.DeploymentFailed &&
    deploymentFailedCount < 3 &&
    campaign?.totalLocations > 0 &&
    campaign?.schedule?.campaignStart > getMarketDatetime(startTimezone)
  );
};

export const validateCampaignVoucher = (
  voucherGroup?: VoucherGroup | null,
  schedule?: any,
  dateTimeConfig?: DateTimeConfig,
) => {
  if (!voucherGroup) {
    return false;
  }
  const { startAtUtc, endAtUtc, availableCount } = voucherGroup;
  const { endTimezone, startTimezone } = dateTimeConfig;
  const { exchangeLimit, campaignEnd, campaignStart } = schedule;
  return (
    Number(exchangeLimit?.globalLimit) <= availableCount &&
    convertUtcDateToTimezoneDateUsingLibrary(campaignEnd, endTimezone).getTime() <=
      new Date(convertUtcDateToTimezoneDate(endAtUtc, endTimezone)).getTime() &&
    convertUtcDateToTimezoneDateUsingLibrary(campaignStart, startTimezone).getTime() >=
      new Date(convertUtcDateToTimezoneDate(startAtUtc, startTimezone)).getTime()
  );
};
