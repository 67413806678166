import styled from '@emotion/styled/macro';
import StatusLabel from 'components/shared/statusLabel/StatusLabel';
import typography from 'styles/typography';
import { ApprovalStatus, StyledWithThemeProps } from 'utils/types';
import { OfferVersionItemProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerVersionItem/OfferVersionItem.consts';
import { OffersGridItemBackdrop } from 'pages/offers/offerManagement/components/offerItem/OfferItem.style';

export const OfferVersion = styled.div<OfferVersionItemProps>`
  display: flex;
  flex-direction: column;
  position: relative;

  &:hover ${OffersGridItemBackdrop} {
    display: flex;
    flex-direction: column;
  }
`;

export const OffersGridItemHeader = styled.header`
  height: 17px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 20px;
  padding-right: ${typography.unit * 2}px;
  margin-bottom: ${typography.unit * 2}px;
`;

export const NoOfferText = styled.div<StyledWithThemeProps>`
  margin: auto;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const OffersGridItemFooter = styled.footer`
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items:baseline;
  justify-content: end;

  h6,
  p {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 270px;
  }

  p {
    white-space: nowrap;
    display: initial;
  }

  h6 {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const OffersGridItemTemplate = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: ${typography.unit}px;
`;

export const OffersGridItemImageContainer = styled.div`
  height: 125px;
  display: flex;
  justify-content: center;
`;

export const OffersGridItemImage = styled.img`
  height: 125px;
  max-width: 260px;
`;

export const StyledStatusLabel = styled(StatusLabel)`
  border-width: ${({ type }) => (type === ApprovalStatus.Draft ? '0 1px 1px 0' : '0')};
`;
