import styled from '@emotion/styled';
import typography from 'styles/typography';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const ProgressIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: ${newGlobalTheme.colors.global.brandMain};
`;

export const StyledProgress = styled.div<{ marginLeft: number }>`
  margin-left: ${(props) => props.marginLeft || typography.unit * 2}px;
  cursor: pointer;
`;

export const BackgroundCircle = styled.div<{ size: number; thickness: number }>`
  position: absolute;
  width: ${(props) => props.size }px;
  height: ${(props) => props.size  }px;
  border-radius: 50%;
  border: ${(props) => props.thickness/1.8}px solid #adadad;
`;
