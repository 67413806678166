import styled from '@emotion/styled';
import ControlledToggleSwitch from 'components/shared/controlledToggleSwitch/ControlledToggleSwitch';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';

export const SectionContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  margin-bottom: 36px;
  width: 530px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  height: 36px;
  width: 530px;
  align-items: center;
`;

export const StyledToggleSwitch = styled(ControlledToggleSwitch)`
  font-size: 12px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  label {
    color: ${newGlobalTheme.colors.text.primary};
  }
`;

export const StyledSelectbox = styled(NewSelectbox)`
  width: 216px;
  border-bottom-color:${newGlobalTheme.colors.global.borderColorboxes}; !important
`;

export const StyledCheckbox = styled(Checkbox)`
  align-self: flex-end;
  border-bottom: 1px solid #c4c5c8;
  padding-bottom: 4px;
  padding-right: 5px;
`;

export const StyledCheckboxMakeNational = styled(Checkbox)`
  align-self: flex-end;
  padding-bottom: 4px;
  padding-right: 5px;
`;
