import styled from '@emotion/styled/macro';
import { NewActionButtonText, NewButtonText } from 'components/shared/button';
import { Icon, IconProps } from 'components/shared/icon';
import NewStatusLabel from 'components/shared/statusLabel/NewStatusLabel';
import  typography, { newTypography } from 'styles/typography';
import zIndex from 'styles/zIndex';
import {StyledWithThemeProps } from 'utils/types';

export const OffersGridItemBackdrop = styled.div`
  position: absolute;
  z-index: ${zIndex.hoverBackdrop};
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 145px;
  display: none;
  padding: ${typography.unit * 3}px ${typography.unit * 2}px ${typography.unit}px ${typography.unit}px;
  border-radius: 5px 5px 0 0;
`;
export const NewOffersGridItemTop = styled.div`
cursor: pointer;
width: 254px;
height: 140px;
gap: 8px;
border-radius: 5px 0px 0px 0px;
opacity: 0px;
 
`;
export const NewOffersGridItemBackdrop = styled.div`
  position: absolute;
  z-index: ${zIndex.hoverBackdrop};
  background: #292929A6;
  width: 254px;
  height: 141px;
  padding: ${typography.unit * 3}px ${typography.unit * 2}px ${typography.unit}px ${typography.unit}px;
  border-radius: 5px 5px 0 0;
   opacity: 0;
  ${NewOffersGridItemTop}:hover &,
  ${NewOffersGridItemTop}:focus-within & {
    opacity: 1; /
`;
export const OffersGridItemWrapper = styled.div`
  cursor: pointer;
  `;
export const OffersGridItemTop = styled.div`
cursor: pointer;
width: 254px;
height: 140px;
gap: 8px;
border-radius: 5px 0px 0px 0px;
opacity: 0px;
 &:hover ${OffersGridItemBackdrop} {
    display: flex;
    flex-direction: column;
  }  
`;



export const OffersGridItem = styled.div<StyledWithThemeProps>`
  width: 280px;
  height: 240px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => `${theme.colors.global.border}`};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  &:hover ${OffersGridItemBackdrop} {
    display: flex;
    flex-direction: column;
  }
`;

export const NewOffersGridItem = styled.div<StyledWithThemeProps>`
  width: 254px;
  height: 302px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => `${theme.colors.global.border}`};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow:hidden;
  background: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 5.3px 1px;
;
:hover{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 5.3px 1px;
}

 
`;

export const OffersGridItemBottomHeader = styled.div`
display:flex;
flex-direction:column;
width:222px;
height: 88px;
gap: 8px;
opacity: 0px;
  justify-content: space-between;
  font-size: 12px;
  line-height: 20px;
  padding: 0px 15px 0px 15px;
`;
export const OffersGridItemHeader = styled.header`
  height: 17px;
  display: flex;
  padding: 0 ${typography.unit * 2}px 0 0;
  margin: 0 0 ${typography.unit * 2}px 0;
  `;
export const OffersGridItemFooter = styled.footer`
  padding: 10px 6px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  h6,
  p {
    font-size: 14px;
  }
  p {
    white-space: nowrap;
    display: initial;
  }
  h6 {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const OffersGridItemBottom = styled.div`
width: 254px;
height:162px;
gap: 8px;
opacity: 0px;

  padding: 10px 6px 0;
  text-overflow: ellipsis;
  overflow: hidden;

  h6,
  p {
    font-size: 14px;
  }

  p {
    white-space: nowrap;
    display: initial;
  }

  h6 {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const NewOffersGridItemBottom = styled.div`
padding: 16px 0px 0px 0px;
gap: 16px;
border-radius: 0px 5px 5px 0px;
opacity: 0px;

`;

export const OffersGridItemTemplate = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: ${typography.unit}px;
`;

export const NewOffersGridItemTemplate = styled.span`
text-transform: uppercase;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
font-family: ${newTypography.primaryFont};
font-size: 12px;
font-weight: 400;
line-height: 14.52px;
text-align: left;
width: 222px;
height: 15px;
gap: 0px;
opacity: 0px;
`;

export const OffersGridItemImageContainer = styled.div`
height: 160px;
display: flex;
justify-content: center;
display: flex;
justify-content: center;
width: 254px;
height: 162pxpx;
gap: 8px;
opacity: 0px;
padding: 16px 0px 8px 0px;
overflow:hidden;
`;

export const NewOffersGridItemImageContainer = styled.div`
display: flex;
justify-content: center;
width: 254px;
height: 162pxpx;
gap: 8px;
opacity: 0px;
padding: 16px 0px 8px 0px;
overflow:hidden;
`;

export const OffersGridItemImage = styled.img`
height: 160px;
max-width: 260px;
gap: 0px;
opacity: 0px;
`;
export const NewOffersGridItemImage = styled.img`
width: 116px;
height: 116px;
gap: 0px;
opacity: 0px;
`;
export const OffersGridItemActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const NewOffersGridItemActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &:focus ${NewOffersGridItemBackdrop} {
    display: flex;
    flex-direction: column;
  }  
`;

export const StyledButtonText = styled(NewActionButtonText)`
 margin-right: ${newTypography.unit * 3}px;
`;

export const StyledViewDetailText = styled(NewButtonText)`
width: 77px;
height: 23px;
gap: 0px;
opacity: 0px;
font-family: ${newTypography.primaryFont};
font-size: 14px;
font-weight: 700;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: left;
color:#006BAE;
padding-bottom:5px;
`;

export const StyledIcon = styled(Icon)<IconProps>`
  width: 18px;
  fill: #fff;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
  transition: all ${typography.transition}s ease;
  opactity:1;
  fill:black;
`;

export const StyledView = styled(StyledIcon)`
  width: 24px;
  fill: #FFFFFF;
`;


export const StyledArchiveIcon = styled(StyledIcon)`
  width: 22px;
`;

export const StyledAddIcon = styled(StyledIcon)`
  width: 18px;
  fill:#FFFFFF;
`;

export const StyledRemoveIcon = styled(StyledIcon)`
  width: 20px;
  fill:#FFFFFF;
`;

export const OfferNameWrapper = styled.div`
width: 222px;
height: 23px;
gap: 0px;
opacity: 0px;
color: #292929;

font-family:${newTypography.primaryFont} ;
font-size: 18px;
font-weight: 400;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: left;
white-space: nowrap;           
  overflow: hidden;             
  text-overflow: ellipsis; 

`

  export const OfferSubTitleWrapper = styled.div`
width: 222px;
height: 15px;
gap: 0px;
opacity: 0px;
font-family: ${newTypography.primaryFont} ;
font-size: 12px;
font-weight: 400;
line-height: 14.52px;
text-align: left;
color: #6F6F6F;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`;

export const OfferIDWrapper = styled.div`
width: 222px;
height:15px;
opacity: 0px;
font-family: ${newTypography.primaryFont} ;
font-size: 12px;
font-weight: 700;
line-height: 14.52px;
text-align: left;
color: #292929;
`;

export const OffersGridItemBottomFooter = styled.div`
height:26px;
gap: 8px;
opacity: 0px;
display: flex;
flex-direction: row;
justify-content: space-between; 
flex:0.5;
padding: 20px 10px 0px 3px;
`;

export const OffersStatusContainer = styled.div`
  height: 26px;
  gap: 0px;
  opacity: 0px;
  font-family: ${newTypography.primaryFont};
  margin-left: 10px;
`;


export const OffersViewDetailContainer = styled.div`
height:26px;
gap: 0px;
opacity: 0px;
color: #292929;
font-family: ${newTypography.primaryFont};
font-size: 12px;
font-weight: 400;
line-height: 15.17px;
text-align: right;
height: 26px;
padding: 6px 10px 6px 10px;
gap: 4px;
opacity: 0px;
`;
export const StyledStatusLabel = styled(NewStatusLabel)`
font-family: ${newTypography.primaryFont};
font-size: 12px;
font-weight: 400;
line-height: 15.17px;
text-align: center;
`;

export const StyledSeprator = styled.span`
width: 254px;
height: 0px;
gap: 0px;
border: 1px 0px 0px 0px;
opacity: 0px;
angle: -0 deg;
border: 1px solid #D6D6D6
`;