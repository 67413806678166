import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import typography, { newTypography } from 'styles/typography';
import zIndex from 'styles/zIndex';
import { StyledWithThemeProps } from 'utils/types';
import { ButtonClose, NewButtonContained, NewButtonText } from '../button';
import TextArea from '../textArea/TextArea';

const offerViewVersion = 'offer-view'
export const StyledTooltip = styled(ReactTooltip)<StyledWithThemeProps & {version?: string}>`
  padding: ${newTypography.unit * 3}px ${newTypography.unit * 4}px !important;
  border: ${({ theme }) => `1px solid ${theme.colors.global.border} !important`};
  border-radius: 5px !important;
  background: ${({ theme }) => theme.colors.global.background};
  z-index: ${zIndex.tooltip};
  box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
  text-align: start;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  min-width: 246px;
  width: max-content;
  min-height: 70px;

  pointer-events: auto !important;
  ${({version}) => version === offerViewVersion && `
      margin-top: 8px; 
      span {
          color: ${newGlobalTheme.colors.text.primary};
      }
  `}

   ${({version}) => version === 'terms' && `
      height: 125px; 
  `}

  &.show {
    opacity: 1 !important;
    white-space: pre-wrap;
  }

  &[data-id='tooltip'] {
    &.place-bottom {
      margin-top: 15px;

      &::before {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        background-color: transparent;
        border-bottom: ${({ theme }) => `11px solid ${theme.colors.global.border} !important`};
        margin-left: -10px;
        top: -11px;
        z-index: -2;
    }

    &::after {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: ${({ theme }) => `11px solid ${theme.colors.text.white} `};
        transform: none;
        background-color: transparent;
        margin-left: -10px;
        top: -10px;
    }
    }

    &.place-top {
      margin-top: -15px;

      &::before {
        width: 0;
         height: 0;
         border-left: 10px solid transparent;
         border-right: 10px solid transparent;
         background-color: transparent; 
         border-top: ${({ theme }) => `11px solid ${theme.colors.global.border} !important`};
         margin-left: -10px;
         bottom: -11px; 
         z-index: -2;
     }

     &::after {
         width: 0;
         height: 0;
         border-left: 10px solid transparent;
         border-right: 10px solid transparent;
         border-top: ${({ theme }) => `11px solid ${theme.colors.text.white} `};
         transform: none;
         background-color: transparent;
         margin-left: -10px;
         bottom: -11px;
     }
    }

    &.place-right {
      margin-left: 20px;

      &::before {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: ${({ theme }) => `11px solid ${theme.colors.global.border} !important`};
        transform: none;
        background-color: transparent;
        margin-left: -3px;
        margin-top: -9px; 
        z-index: -2;
    }

    &::after {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: ${({ theme }) => `11px solid ${theme.colors.text.white} `};
        transform: none;
        background-color: transparent;
        margin-left: -4px;
        margin-top: -9px;
    }
    }

    &.place-left {
      margin-left: -20px;

      &::before {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: ${({ theme }) => `11px solid ${theme.colors.global.border} !important`};
        transform: none;
        background-color: transparent;
        margin-right: -11px;
        z-index: -2;
        margin-top: -9px;
      } 

      &::after {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: ${({ theme }) => `11px solid ${theme.colors.text.white} `};
        transform: none;
        background-color: transparent;
        margin-right: -4px;
        margin-top: -9px;
      }
    }
  }
`;

export const StyledButtonClose = styled(ButtonClose)<{version?: string}>`
  position: absolute;
  ${({version}) => version === offerViewVersion ? 'right: -9px;' : 'right: 0px;'}
  padding: 0;
  height: 8px;
  width: 8px;
  top: 0px;
   ${({version}) => version === offerViewVersion && `
      z-index: 1000;
      svg {
        fill: #292929;
        :hover {
          fill: #292929;
        }
      }
   `}

 
  
`;

export const StyledButtonText = styled(NewButtonText)`
  color: #006BAE;
`;

export const CancelButton = styled(NewButtonContained)`
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  background-color: ${newGlobalTheme.colors.button.secondary};
  height: 24px;
  width: 64px;
  :hover {
    background-color: ${newGlobalTheme.colors.button.secondary};
  }
  &:disabled {
    background-color: #f7e1a4;
  }
  border: 1px solid #c08b00;
`;

export const StyledContent = styled.div<{ shouldMargin: boolean, version?: string} & StyledWithThemeProps>`
  min-height: 14px; 
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  // text-overflow: ellipsis;
  // overflow: hidden;
  overflow-wrap: break-word;
  display: block;
  position: relative;
  top: -8px;
  font-family: Speedee; 
  letter-spacing: -0.03em;
   ${({version}) => version === 'terms' && `
      font-size: 12px; 
  `}
  ${({version}) => version === offerViewVersion && `
    margin-top: 8px;
   .MuiAccordionSummary-content  {
        font-family: Speedee; 
        font-size: 14px; 
        color: ${newGlobalTheme.colors.text.primary};
        svg {
          color: ${newGlobalTheme.colors.text.disabledSave}
      }
    }
    .MuiPaper-root {
        border-radius: 5px;
        border: 1px solid ${newGlobalTheme.colors.global.borderColor};
        background: ${newGlobalTheme.colors.global.background};
        box-shadow: none !important; 
        margin-top: 1px;
        border-top: 1px solid ${newGlobalTheme.colors.global.borderColor} !important; 
    }

    .MuiPaper-root-MuiAccordion-root-StyledAccordion {
      border-top: 1px solid ${newGlobalTheme.colors.global.borderColor} !important; 
    } 
  
  `}
 

`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${typography.unit * 2}px;

  button:first-of-type {
    margin-right: ${typography.unit * 5}px;
  }
`;

export const StyledTextArea = styled(TextArea)``;
