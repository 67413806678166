import React from 'react';
import { TextFieldProps } from '../textField/TextField.consts';
import { StyledNewTextField, StyledNewSearchTextField } from './NewTextField.style';

export const NewSearchTextField = (props: TextFieldProps) => {
  return <StyledNewSearchTextField {...props}/>;
};

const NewTextField = (props: TextFieldProps) => {
  return <StyledNewTextField {...props}/>;
};

export default NewTextField;
