import React, { useEffect, useState } from 'react';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import { Actions, StyledApproveButton, StyledHeader } from 'pages/shared/shared.style';
import { NewTextPageTitle } from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName, tabToPathMap } from 'components/header/Header.consts';
import ScheduleManagementFilterBar from 'pages/campaigns/scheduleManagement/components/scheduleManagementFilterBar/ScheduleManagementFilterBar';
import {
  AlertIcon,
  Buttons,
  ButtonsWrapper,
  Container,
  IconTooltipWrapperPlaceholder,
  ItemName,
  LockSpace,
  PeriodName,
  ScheduleTable,
  ScheduleTableData,
  ScheduleTableHeader,
  TableItem,
  StyledProgressIndicator,
} from 'pages/campaigns/scheduleManagement/ScheduleManagement.style';
import { getFilters, schedule as scheduleSlice, ScheduleFilterType, setFilters } from 'app/slices/schedule';
import { useSelector } from 'react-redux';
import { periodsGqls } from 'pages/settings/schedulePeriods/SchedulePeriods.gqls';
import { useQuery } from '@apollo/client';
import { ApprovalStatus, ApprovalStatusLabel, DateTimeConfig, OrderDirection } from 'utils/types';
import { scheduleManagementGqls } from 'pages/campaigns/scheduleManagement/ScheduleManagement.gqls';
import {
  CampaignLocalSchedule,
  LocalScheduleAlert,
  LocalScheduleAlertLabel,
} from 'pages/campaigns/scheduleManagement/ScheduleManagement.consts';
import { ButtonContained, ButtonOutlined } from 'components/shared/button';
import { EntityApprovalButton } from 'pages/shared/entityApproveButton/EntityApproveButton';
import { EntityType } from 'pages/shared/entityApproveButton/EntityApproveButton.consts';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import ReactTooltip from 'react-tooltip';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { hideTooltip } from 'utils/tooltip';
import { updateScheduleApprovalStatus } from 'utils/api/schedule';
import { Link, useHistory } from 'react-router-dom';
import { store } from 'app/store';
import { useUrlFilters } from 'hooks/use-url-filters';
import { getDateInConfigFormat } from 'utils/date';
import { campaignsGqls } from '../campaignManagement/Campaigns.gqls';
import { vceCampaignsPage } from 'app/genericSlices/campaigns';
import { CampaignFilterType } from '../campaignManagement/Campaigns.consts';
import { LoaderWrapper } from '../calendar/components/calendarView/CalendarView.style';
import { Loader } from 'components/shared/loader';
import { marketConfig } from 'app/slices/config';
import { FetchPolicies } from 'utils/types/common';
import { LockableProcessMessage } from 'utils/types/campaigns';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';

const ScheduleManagement = () => {
  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({ ...res, [key]: Array.isArray(params[key]) ? params[key] : [params[key]] }),
          {},
        ),
      ),
    );
  });
  const [periodStarted, setPeriodStarted] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [processingSchedules, setProcessingSchedules] = useState<Record<string, boolean>>({});
  const { filters } = useSelector(scheduleSlice);
  const { config } = useSelector(marketConfig);
  const { dateFormat, startTimezone, endTimezone } = config;
  const dateTimeConfig: DateTimeConfig = { dateFormat, startTimezone, endTimezone };
  const history = useHistory();
  const { data: archivedCampaigns } = useQuery(campaignsGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: vceCampaignsPage.getFilters({
          ...filters,
          [CampaignFilterType.Status]: [ApprovalStatus.Archived],
          [CampaignFilterType.OnlyLocal]: 'true',
        }),
      },
    },
  });

  const { data: periodsData, loading: loadingPeriods } = useQuery(periodsGqls.queries.getTotal, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: {},
        order: { createdAt: OrderDirection.DESC },
      },
    },
  });
  const { data: periodData } = useQuery(periodsGqls.queries.getById, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    skip: !filters[ScheduleFilterType.Period]?.[0],
    variables: {
      id: parseInt(filters[ScheduleFilterType.Period]?.[0], 10),
    },
  });
  const { data: nextPeriodData } = useQuery(periodsGqls.queries.getNextPeriod, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    skip: filters[ScheduleFilterType.Period] !== undefined,
  });

  const { data: draftData, loading: loading1 } = useQuery(scheduleManagementGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    skip: !filters[ScheduleFilterType.Period]?.[0],
    variables: {
      data: {
        filters: getFilters({ ...filters, [ScheduleFilterType.Status]: [ApprovalStatus.Draft] }),
      },
    },
  });
  const {
    getCampaignLocalSchedules: { items: unvalidDrafts },
  } = draftData || { getCampaignLocalSchedules: { items: [] } };
  const drafts = unvalidDrafts
    ? unvalidDrafts.filter(
      (campaigns: any) => campaigns.campaignsNumber && campaigns.approvalStatus === ApprovalStatus.Draft,
    )
    : [];
  const { data: pendingData, loading: loading2 } = useQuery(scheduleManagementGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    skip: !filters[ScheduleFilterType.Period]?.[0],
    variables: {
      data: {
        filters: getFilters({ ...filters, [ScheduleFilterType.Status]: [ApprovalStatus.PendingApproval] }),
      },
    },
  });
  const {
    getCampaignLocalSchedules: { items: unvalidPending },
  } = pendingData || { getCampaignLocalSchedules: { items: [] } };
  const pending = unvalidPending
    ? unvalidPending.filter(
      (campaigns: any) => campaigns.campaignsNumber && campaigns.approvalStatus === ApprovalStatus.PendingApproval,
    )
    : [];
  const { data: partialApprovedData, loading: loading4 } = useQuery(scheduleManagementGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    skip: !filters[ScheduleFilterType.Period]?.[0],
    variables: {
      data: {
        filters: getFilters({ ...filters, [ScheduleFilterType.Status]: [ApprovalStatus.PartiallyApproved] }),
      },
    },
  });
  const {
    getCampaignLocalSchedules: { items: unvalidPartialApproved },
  } = partialApprovedData || { getCampaignLocalSchedules: { items: [] } };
  const partialApproved = unvalidPartialApproved
    ? unvalidPartialApproved.filter(
      (campaigns: any) => campaigns.campaignsNumber && campaigns.approvalStatus === ApprovalStatus.PartiallyApproved,
    )
    : [];
  const { data: approvedData, loading: loading3 } = useQuery(scheduleManagementGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    skip: !filters[ScheduleFilterType.Period]?.[0],
    variables: {
      data: {
        filters: getFilters({ ...filters, [ScheduleFilterType.Status]: [ApprovalStatus.Approved] }),
      },
    },
  });
  const {
    getCampaignLocalSchedules: { items: unvalidApproved },
  } = approvedData || { getCampaignLocalSchedules: { items: [] } };
  const approved = unvalidApproved
    ? unvalidApproved.filter(
        (campaigns: any) => campaigns.campaignsNumber && campaigns.approvalStatus === ApprovalStatus.Approved,
      )
    : [];
  const isInProgress = (schedules?: CampaignLocalSchedule[]) => {
    return (
      schedules?.some((schedule: CampaignLocalSchedule) => schedule.updateProcessStatus) ||
      schedules?.some((schedule: CampaignLocalSchedule) => processingSchedules[schedule.id])
    );
  };
  const isDraftInProgress = isInProgress(drafts);
  const isPendingApprovalInProgress = isInProgress(pending);
  const loadProgressBar = (isLaneProgress: boolean, currentScheduleProcessStatus: string) => {
    if (currentScheduleProcessStatus) {
      const message =
        currentScheduleProcessStatus === LockableProcessMessage.BulkApproval
          ? 'Campaign approval is in progress'
          : 'Campaign submission for approval is in progress';
      return <StyledProgressIndicator size={28} message={message} marginLeft={4} onHover />;
    } else if (isLaneProgress) {
      return <LockSpace width={6} height={28} />;
    }
    return <></>;
  };
  const loadSpacing = (isProgress: boolean, currentInProgress?: string) => {
    return isProgress && !currentInProgress ? <LockSpace width={26} height={28} /> : <></>;
  };

  const ViewButton = (zone: number) => (
    <ButtonOutlined
      onClick={() => {
        if (zone) {
          history.push(
            `${tabToPathMap['campaign management']}?onlyLocal=true&zone=${zone}&period=${periodData?.getPeriod?.id}`,
          );
        } else {
          history.push(`${tabToPathMap['campaign management']}?national=true&period=${periodData?.getPeriod?.id}`);
        }
      }}
    >
      View
    </ButtonOutlined>
  );

  const onUpdateStatus = async (id: number, approvalStatus: ApprovalStatus) => {
    try {
      setIsloading(true);
      const {
        data: {
          updateLocalScheduleStatus: { isProcessed, items },
        },
      } = await updateScheduleApprovalStatus(id, approvalStatus);
      if (isProcessed === true) {
        if (items[0]?.approvalStatus === ApprovalStatus.PartiallyApproved) {
          showToast(
            MessageType.Success,
            `One or more campaigns in this schedule cannot be approved by you due to the two-step approval process.`,
          );
        } else {
          showToast(
            MessageType.Success,
            `Schedule successfully ${
              approvalStatus === ApprovalStatus.PendingApproval ? 'submitted for approval' : 'approved'
            }`,
          );
        }
      } else {
        showToast(
          MessageType.Info,
          `Your campaign ${
            approvalStatus === ApprovalStatus.PendingApproval ? 'submission for approval' : 'approval'
          } request has been received, we are working on it, and you'll be notified once it's ready.`,
        );
        setProcessingSchedules((values) => ({ ...values, [id]: true }));
      }
    } catch (e) {
      showToast(
        MessageType.Error,
        `Campaigns failed to be ${
          approvalStatus === ApprovalStatus.PendingApproval ? 'submitted for approval' : 'approved'
        }`,
      );
    }
  };

  useEffect(() => {
    if (periodData?.getPeriod?.startDate) {
      setPeriodStarted(new Date() > new Date(periodData.getPeriod.startDate));
    }
  }, [periodData]);

  useEffect(() => {
    if (nextPeriodData?.getNextPeriod?.id) {
      urlFilters.filter(ScheduleFilterType.Period, [nextPeriodData.getNextPeriod.id]);
    }
  }, [nextPeriodData]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    // Check if all queries have finished loading
    if (loading1 && loading2 && loading3 && loading4) {
      setIsloading(false);
    }
    if (loading1 === false && loading2 === false && loading3 === false && loading4 === false) {
      setProcessingSchedules({});
    }
  }, [loading1, loading2, loading3, loading4]);
  const isAnyLoading = [loading1, loading2, loading3, loading4].some(Boolean);

  return (
    <>
      {isLoading && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )}
      <PageContainer>
        <StyledHeader data-automation-id="header">
          <NewTextPageTitle text={tabName.ScheduleManagement} />
          <Actions data-automation-id="actions">
            <ScheduleManagementFilterBar filter={urlFilters.filter} filterMulti={urlFilters.filterMulti} />
          </Actions>
        </StyledHeader>
        <Container>
          {periodData?.getPeriod && (
            <div>
              <PeriodName>{periodData.getPeriod.name}</PeriodName>
              {getDateInConfigFormat(new Date(periodData.getPeriod.startDate), dateTimeConfig)} -
              {getDateInConfigFormat(new Date(periodData.getPeriod.endDate), dateTimeConfig)}
            </div>
          )}
          {loadingPeriods ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : periodsData?.getPeriods?.total === 0 ? (
            <div>
              <Link to={tabToPathMap[tabName.SchedulePeriods]}>Schedule Periods</Link> must be defined. To define, go to
              Schedule Periods in the Settings menu.
            </div>
            ) : isAnyLoading ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <ScheduleTable hasPartialApproved={Boolean(partialApproved?.length)}>
              <ScheduleTableHeader>{ApprovalStatusLabel[ApprovalStatus.Draft]}</ScheduleTableHeader>
              <ScheduleTableHeader>{ApprovalStatusLabel[ApprovalStatus.PendingApproval]}</ScheduleTableHeader>
              {partialApproved?.length ? (
                <ScheduleTableHeader>{ApprovalStatusLabel[ApprovalStatus.PartiallyApproved]}</ScheduleTableHeader>
              ) : null}
              <ScheduleTableHeader>{ApprovalStatusLabel[ApprovalStatus.Approved]}</ScheduleTableHeader>
              <ScheduleTableData>
                {drafts?.map((schedule: CampaignLocalSchedule) => (
                  <TableItem key={`${schedule.id}_CampaignLocalSchedule`}>
                    <ItemName>{schedule.zone?.name || 'National'}</ItemName>
                    <ButtonsWrapper>
                      <Buttons>
                        {ViewButton(schedule.zone?.id)}
                        <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                          <ButtonContained
                            onClick={() => null}
                            disabled={
                              periodStarted ||
                              schedule.campaignsValidNumber === 0 ||
                              schedule.alerts?.includes(LocalScheduleAlert.Conflicted) ||
                              processingSchedules[schedule.id] ||
                              !!schedule.updateProcessStatus
                            }
                            data-tip
                            data-for={`pending-${schedule.id}`}
                          >
                            Submit for Approval
                          </ButtonContained>
                          <Tooltip
                            id={`pending-${schedule.id}`}
                            content={`You are about to submit ${schedule.campaignsValidNumber} out of ${schedule.campaignsNumber} campaigns for approval. Are you sure?`}
                            approveMsg="Yes, Submit"
                            onDisapproveClick={() => {
                              hideTooltip(`#pending-${schedule.id}`);
                            }}
                            onApproveClick={async () => {
                              hideTooltip(`#pending-${schedule.id}`);
                              await onUpdateStatus(schedule.id, ApprovalStatus.PendingApproval);
                            }}
                          />
                        </RoleGuard>
                      </Buttons>
                      {loadProgressBar(isDraftInProgress, schedule.updateProcessStatus)}
                      {schedule.alerts?.length ? (
                        <>
                          <IconTooltipWrapperPlaceholder data-for="alert-tooltip-draft" data-tip>
                            <AlertIcon name="error" />
                          </IconTooltipWrapperPlaceholder>
                          <Tooltip
                            id="alert-tooltip-draft"
                            place="bottom"
                            content={[...new Set(schedule.alerts)]
                              .map((alert) => LocalScheduleAlertLabel[alert])
                              .join(', ')}
                            onHover
                          />
                        </>
                      ) : (
                        <IconTooltipWrapperPlaceholder />
                      )}
                      {loadSpacing(isDraftInProgress, schedule.updateProcessStatus)}
                    </ButtonsWrapper>
                  </TableItem>
                ))}
              </ScheduleTableData>
              <ScheduleTableData>
                {pending?.map((schedule: CampaignLocalSchedule) => (
                  <TableItem key={`${schedule.id}_CampaignLocalSchedule`}>
                    <ItemName> {schedule.zone?.name || 'National'}</ItemName>
                    <ButtonsWrapper>
                      <Buttons>
                        {ViewButton(schedule.zone?.id)}
                        <EntityApprovalButton entity={schedule} entityType={EntityType.Campaign}>
                          <StyledApproveButton
                            onClick={() => null}
                            disabled={
                              periodStarted ||
                              schedule.campaignsValidNumber === 0 ||
                              schedule.alerts?.includes(LocalScheduleAlert.Conflicted) ||
                              processingSchedules[schedule.id] ||
                              !!schedule.updateProcessStatus
                            }
                            data-tip
                            data-for={`approved-${schedule.id}`}
                          >
                            Approve
                          </StyledApproveButton>
                          <Tooltip
                            id={`approved-${schedule.id}`}
                            content={`You are about to approve ${schedule.campaignsValidNumber} campaigns. Are you sure?`}
                            approveMsg="Yes, Approve"
                            onDisapproveClick={() => {
                              hideTooltip(`#approved-${schedule.id}`);
                            }}
                            onApproveClick={async () => {
                              hideTooltip(`#approved-${schedule.id}`);
                              await onUpdateStatus(schedule.id, ApprovalStatus.Approved);
                            }}
                          />
                        </EntityApprovalButton>
                      </Buttons>
                      {loadProgressBar(isPendingApprovalInProgress, schedule.updateProcessStatus)}
                      {schedule.alerts?.length ? (
                        <>
                          <IconTooltipWrapperPlaceholder data-for={`alert-tooltip-pending${schedule.id}`} data-tip>
                            <AlertIcon name="error" />
                          </IconTooltipWrapperPlaceholder>
                          <Tooltip
                            id={`alert-tooltip-pending${schedule.id}`}
                            place="bottom"
                            content={[...new Set(schedule.alerts)]
                              .map((alert) => LocalScheduleAlertLabel[alert])
                              .join(', ')}
                            onHover
                          />
                        </>
                      ) : (
                        <IconTooltipWrapperPlaceholder />
                      )}
                      {loadSpacing(isPendingApprovalInProgress, schedule.updateProcessStatus)}
                    </ButtonsWrapper>
                  </TableItem>
                ))}
              </ScheduleTableData>
              {partialApproved?.length ? (
                <ScheduleTableData>
                  {partialApproved?.map((schedule: CampaignLocalSchedule) => (
                    <TableItem key={`${schedule.id}_CampaignLocalSchedule`}>
                      <ItemName> {schedule.zone?.name || 'National'}</ItemName>
                      <ButtonsWrapper>
                        <Buttons>
                          {ViewButton(schedule.zone?.id)}
                          {schedule.campaignsValidNumber ? (
                            <EntityApprovalButton entity={schedule} entityType={EntityType.Campaign}>
                              <StyledApproveButton
                                onClick={() => null}
                                disabled={
                                  periodStarted ||
                                  schedule.campaignsValidNumber === 0 ||
                                  schedule.alerts?.includes(LocalScheduleAlert.Conflicted) ||
                                  processingSchedules[schedule.id] ||
                                  !!schedule.updateProcessStatus
                                }
                                data-tip
                                data-for={`approved-${schedule.id}`}
                              >
                                Approve
                              </StyledApproveButton>
                              <Tooltip
                                id={`approved-${schedule.id}`}
                                content={`You are about to approve ${schedule.campaignsValidNumber} campaigns. Are you sure?`}
                                approveMsg="Yes, Approve"
                                onDisapproveClick={() => {
                                  hideTooltip(`#approved-${schedule.id}`);
                                }}
                                onApproveClick={async () => {
                                  hideTooltip(`#approved-${schedule.id}`);
                                  await onUpdateStatus(schedule.id, ApprovalStatus.Approved);
                                }}
                              />
                            </EntityApprovalButton>
                          ) : null}
                        </Buttons>
                        {loadProgressBar(isPendingApprovalInProgress, schedule.updateProcessStatus)}
                        {schedule.alerts?.length ? (
                          <>
                            <IconTooltipWrapperPlaceholder
                              data-for={`alert-tooltip-partiallyApproved${schedule.id}`}
                              data-tip
                            >
                              <AlertIcon name="error" />
                            </IconTooltipWrapperPlaceholder>
                            <Tooltip
                              id={`alert-tooltip-partiallyApproved${schedule.id}`}
                              place="bottom"
                              content={[...new Set(schedule.alerts)]
                                .map((alert) => LocalScheduleAlertLabel[alert])
                                .join(', ')}
                              onHover
                            />
                          </>
                        ) : (
                          <>
                            <IconTooltipWrapperPlaceholder
                              data-for={`alert-tooltip-partiallyApproved${schedule.id}`}
                              data-tip
                            >
                              <AlertIcon name="info" />
                            </IconTooltipWrapperPlaceholder>
                            <Tooltip
                              id={`alert-tooltip-partiallyApproved${schedule.id}`}
                              place="bottom"
                              content={
                                'This schedule was partially approved due to the two-step approval process restrictions.'
                              }
                              onHover
                            />
                          </>
                        )}
                        {loadSpacing(isPendingApprovalInProgress, schedule.updateProcessStatus)}
                      </ButtonsWrapper>
                    </TableItem>
                  ))}
                </ScheduleTableData>
              ) : null}
              <ScheduleTableData>
                {approved?.map((schedule: CampaignLocalSchedule) => (
                  <TableItem key={`${schedule.id}_CampaignLocalSchedule`}>
                    <ItemName> {schedule.zone?.name || 'National'}</ItemName>
                    <ButtonsWrapper>
                      {ViewButton(schedule.zone?.id)}
                      {schedule.alerts?.length ? (
                        <>
                          <IconTooltipWrapperPlaceholder
                            data-for={`alert-tooltip-notAllApproved${schedule.id}`}
                            data-tip
                          >
                            <AlertIcon name="error" />
                          </IconTooltipWrapperPlaceholder>
                          <Tooltip
                            id={`alert-tooltip-notAllApproved${schedule.id}`}
                            place="bottom"
                            content={[...new Set(schedule.alerts)]
                              .map((alert) => {
                                return schedule.campaignsValidNumber === 0 &&
                                  ![LocalScheduleAlert.Conflicted].includes(alert)
                                  ? LocalScheduleAlertLabel[LocalScheduleAlert.AllApprovalFailed]
                                  : LocalScheduleAlertLabel[alert];
                              })
                              .join(', ')}
                            onHover
                          />
                        </>
                      ) : schedule.campaignsNumber !== schedule.campaignsValidNumber ? (
                        <>
                          <IconTooltipWrapperPlaceholder data-for={`alert-tooltip-approved${schedule.id}`} data-tip>
                            <AlertIcon name="error" />
                          </IconTooltipWrapperPlaceholder>
                          <Tooltip
                            id={`alert-tooltip-approved${schedule.id}`}
                            place="bottom"
                            content={LocalScheduleAlertLabel[LocalScheduleAlert.NoApprovalFailed]}
                            onHover
                          />
                        </>
                      ) : (
                        <IconTooltipWrapperPlaceholder />
                      )}
                    </ButtonsWrapper>
                  </TableItem>
                ))}
              </ScheduleTableData>
            </ScheduleTable>
          )}
        </Container>
      </PageContainer>
    </>
  );
};

export default ScheduleManagement;
