import React, { useEffect } from 'react';
import { FilterIcon, Filters, StyledFilterBarSelect } from 'pages/shared/shared.style';
import { OfferTemplates } from 'utils/types/offers';
import { useUrlFilters } from 'hooks/use-url-filters';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import { store } from 'app/store';
import { termsConditionsPage } from 'app/genericSlices/termsConditions';
import { archiveStatusValues } from 'pages/settings/settings.const';
import useFeatureFlag from 'hooks/use-feature-flag';
import { useSelector } from 'react-redux';
import { Feature } from 'utils/types/features';
import { usePersistCaretPosition } from 'hooks/use-persist-caret-position';
import { TermFilterType } from '../../TermsConditions.consts';
import { marketConfig } from 'app/slices/config';

export const TermsConditionsFilterBar = () => {
  const ftArchiveTerms = useFeatureFlag(Feature.TermConditionArchive);
  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      termsConditionsPage.actions.setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({ ...res, [key]: Array.isArray(params[key]) ? params[key] : [params[key]] }),
          {},
        ),
      ),
    );
    store.dispatch(termsConditionsPage.loadTermsPage());
  });
  const { filters } = useSelector(termsConditionsPage.termsConditionsState);
  const [caretPosition, setCaretPosition] = usePersistCaretPosition(filters[TermFilterType.SearchQuery]);

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(filters);
    }
  }, []);
  const { config } = useSelector(marketConfig);
  const { enableDigitalDownload } = config;
  const filteredOfferTemplates = Object.values(OfferTemplates).filter(
    (template) => enableDigitalDownload || template.id !== '14'
  );

  return (
    <Filters>
      <FilterIcon name="filter" />
      {ftArchiveTerms && (
        <StyledFilterBarSelect
          placeholder="Select"
          label="Status"
          name="status"
          multiple
          items={archiveStatusValues as any[]}
          onChange={(selectedItems: any) => {
            urlFilters.filter(
              TermFilterType.Archive,
              Object.values(selectedItems).map((i: any) => i.id),
            );
          }}
          initialSelectedItems={filters[TermFilterType.Archive]}
          maxItems={1}
          selectWidth={160}
        />
      )}
      <StyledFilterBarSelect
        placeholder="Select"
        label="Offer Template"
        name="offer-template"
        multiple
        items={Object.values(filteredOfferTemplates) as any[]}
        onChange={(selectedItems: any) => {
          urlFilters.filter(
            TermFilterType.OfferTemplate,
            Object.values(selectedItems).map((i: any) => i.id),
          );
        }}
        initialSelectedItems={filters[TermFilterType.OfferTemplate]}
        withSearch
        reset
        maxItems={1}
        selectWidth={160}
      />
      <SearchTextField
        key={`${JSON.stringify(filters[TermFilterType.SearchQuery])}_SearchQuery`}
        name="terms-search"
        caretPosition={caretPosition}
        value={filters[TermFilterType.SearchQuery] ?? ''}
        onChange={(e) => {
          urlFilters.filter(TermFilterType.SearchQuery, e.target.value);
          setCaretPosition(e);
        }}
      />
    </Filters>
  );
};
