import styled from '@emotion/styled/macro';
import typography from 'styles/typography';
import { CheckboxContainer } from 'components/shared/checkbox/Checkbox.style';
import Tooltip from 'components/shared/tooltip/Tooltip';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import NewModal from 'components/shared/modal/NewModal';

export const UserManagementModalContainer = styled.div`
  display: grid;
  grid-template-areas:
    'top top'
    'left right'
    'footer footer';
  grid-template-columns: repeat(2, 320px);
  column-gap: ${typography.unit * 8}px;
  flex: 1;

  header {
    display: flex;
    grid-area: top;
    align-items: center;
  }
`;

export const UserManagementModalColumn = styled.div`
  display: flex;
  gap: ${typography.unit * 11}px;
  flex-direction: column;
  flex-grow: 1;
`;

export const UserManagementModalHorizontalColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${typography.unit * 7}px;
  justify-content: space-between;

  > * {
    flex-grow: 1;
  }
`;

export const UserManagementModalNames = styled.div`
  display: flex;
  gap: ${typography.unit * 5}px;
`;

export const UserManagementModalCompany = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    display: block;
    font-size: 13px;
    margin-bottom: ${typography.unit * 2}px;
  }
`;

export const NotificationRegisteration = styled.div`
  ${CheckboxContainer} {
    margin-bottom: ${typography.unit * 4}px;
  }

  span {
    display: block;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: ${typography.unit * 4}px;
  }
`;

export const UserManagementModalFooter = styled.footer`
  display: flex;
  gap: ${typography.unit * 5}px;
  margin-left: auto;
  grid-area: footer;
  align-items: center;
`;

export const StyledModal = styled(NewModal)`
  max-width: 750px;

  h3 {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const RoleSelectbox = styled(Selectbox)`
  height: fit-content;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledInfoTooltip = styled(InfoTooltip)`
  margin-bottom: 16px;
`;
