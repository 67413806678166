import React from 'react';
import { OfferTemplates } from 'utils/types/offers';
import { Bogo } from './bogo';
import { SingleItemDiscount } from './singleItemDiscount';
import { MultiItemDiscount } from './multiItemDiscount';
import { BuyBuyGet } from './buyBuyGet';
import { BuyGetGet } from './buyGetGet';
import { TemplateProps } from './Template.consts';
import { TemplateContainer } from './Template.style';
import { Substitution } from './substitution';
import { ComboPriceDeal } from './comboPriceDeal';
import { OrderDiscount } from './orderDiscount';
import { PriceDeal } from './priceDeal';
import { ProductCombo } from './productCombo';
import { NonFoodDigitalRewards } from './nonFoodDigitalRewards';

export * from './bogo';
export * from './singleItemDiscount';
export * from './multiItemDiscount';
export * from './substitution';
export * from './orderDiscount';
export * from './buyBuyGet';
export * from './buyGetGet';
export * from './priceDeal';
export * from './comboPriceDeal';
export * from './productCombo';
export * from './nonFoodDigitalRewards';

const Templates: Record<keyof typeof OfferTemplates, (props: TemplateProps) => JSX.Element> = {
  '1': Bogo,
  '2': SingleItemDiscount,
  '3': MultiItemDiscount,
  '4': Substitution,
  '5': OrderDiscount,
  '7': BuyBuyGet,
  '8': BuyGetGet,
  '10': PriceDeal,
  '11': ComboPriceDeal,
  '13': ProductCombo,
  '14': NonFoodDigitalRewards,
};

export const renderTemplate = (template: keyof typeof OfferTemplates, props: TemplateProps) => {
  const TemplateComponent = Templates[template];

  return TemplateComponent ? (
    <TemplateContainer key={template}>
      <TemplateComponent {...props} />
    </TemplateContainer>
  ) : null;
};
