import styled from '@emotion/styled';
import typography from 'styles/typography';

export const FormFooter = styled.footer`
  display: flex;
  width:530px;
  height:36px;
  align-items:center;
  margin-top: ${typography.unit * 5}px;
  gap: ${typography.unit * 5}px;
  justify-content:space-between;
`;


export const FormRow = styled.div`
display:flex;
width:220px;
justify-content:space-between;
`;
