import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import LockIcon from 'pages/shared/lockIcon/LockIcon';
import TextModalTitle from 'components/shared/text/textModalTitle/NewTextModalTitle';
import { useOperationCounter } from 'app/apollo/operationCounter';
import difference from 'lodash/difference';
import Backdrop from '../backdrop/Backdrop';
import { ModalProps } from './Modal.const';
import { Container, Content, Loading, MainTitle, ModalHeader } from './Campaign.style';
import { Loader } from '../loader';
import ButtonGroup from 'pages/campaigns/campaignManagement/components/campaignForm/components/buttonGroup/ButtonGroup';
import { FormMode } from 'utils/types';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';

const CampaignModal = ({
  children,
  mode,
  title,
  subtitle,
  onClose,
  isLocked = false,
  className,
  withLoader = true,
  ignoreOperations = [],
  offerSource = OfferSource.VCE,
  ...rest
}: ModalProps) => {
  const { reqs: pendingRequests } = useOperationCounter();

  useEffect((): (() => void) => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      <Backdrop {...(onClose && { onClick: onClose })} />
      <Container className={className} data-automation-id="modal" {...rest}>
        {withLoader && (
          <Loading isLoading={difference(pendingRequests, ignoreOperations).length > 0}>
            <Loader />
          </Loading>
        )}
        <ModalHeader>
          <MainTitle subtitle={subtitle}>
            {title && <TextModalTitle text={title} />}
            {isLocked && <LockIcon />}
            {mode != FormMode.New && mode != FormMode.Duplicate && (
              <ButtonGroup mode={mode} offerSource={offerSource} />
            )}
          </MainTitle>
          {subtitle ?? null}
        </ModalHeader>
        <Content data-automation-id="modal-content">{children}</Content>
      </Container>
    </>,
    document.querySelector('#modal'),
  );
};

export default CampaignModal;
