import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import Error from 'components/shared/error/Error';
import { InnerSelectbox } from 'components/shared/selectbox/InnerSelectbox';
import NewCheckbox from '../checkbox/NewCheckbox';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { campaignFormVersion, offerFormVersion } from './NewSelectbox.consts';

export const SelectboxContainer = styled.div<{ labelIsHorizontal: boolean; containerGap?: number }>`
  display: flex;
  flex-direction: ${({ labelIsHorizontal }) => labelIsHorizontal && 'column'};
  align-items: ${({ labelIsHorizontal }) => !labelIsHorizontal && 'center'};
  ${({ containerGap }) => containerGap && `gap: ${containerGap}px;`}
  [data-automation-id="chip"] {
    background-color: #ffbc0d !important;
    color: ${newGlobalTheme.colors.text.primary};
    border: none;
  }
`;

export const StyledLabel = styled.label<StyledWithThemeProps & { disabled: boolean }>`
  font-size: 13px;
  display: flex;
  align-items: start;
  margin-right: ${typography.unit * 3}px;
  white-space: nowrap;
`;

export const InputContainer = styled.div<{version?: string, disabled?: boolean}>`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  border-bottom: ${({version}) => (version === offerFormVersion || version === campaignFormVersion) ?
  `1px solid ${newGlobalTheme.colors.text.disabledSave};` : '1px solid #292929;'}
  ${({version}) => version === offerFormVersion  || version === campaignFormVersion && ` height: 36px`};
  border-bottom: ${({version, disabled}) => version === campaignFormVersion && disabled && `1px solid #ADADAD;`}

`;

export const StyledSelect = styled(InnerSelectbox)<any>`
  flex: 1;
  font-size: ${typography.fontSize}px;
  color: ${newGlobalTheme.colors.text.input};
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
`;

export const StyledError = styled(Error)`
  position: absolute;
  top: ${typography.unit * 7}px;
  margin-left: ${typography.unit / 2}px;
  white-space: nowrap;
`;

export const StyledOption = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  label {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const StyledCheckbox = styled(NewCheckbox)`
  margin-right: ${typography.unit * 2}px;
`;

export const LimitIndication = styled.div`
  font-size: 12px;
`;
