import styled from '@emotion/styled';
import typography from 'styles/typography';
import TextField from 'components/shared/textField/TextField';
import { DatePicker } from 'components/shared/picker/datePicker/DatePicker';
import Message from 'components/shared/notifications/message/Message';
import NewModal from 'components/shared/modal/NewModal';

export const StyledModal = styled(NewModal)`
  height: 350px;
  width: 510px;
  min-width: 510px;
  min-height: 350px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FormRow = styled.div`
  display: flex;
  margin-bottom: ${typography.unit * 8}px;
  align-items: baseline;
`;

export const PeriodName = styled(TextField)`
  width: 295px;
`;

export const StyledDatePicker = styled(DatePicker)`
  .MuiInputBase-root {
    input {
      width: 96px;
    }
  }

  [data-automation-id~='error-message'] {
    margin-top: ${typography.unit * 13}px;
  }
`;

export const StartDate = styled(StyledDatePicker)`
  margin-right: ${typography.unit * 12}px;
`;

export const FormFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: ${typography.unit * 5}px;
  gap: ${typography.unit * 5}px;
`;

export const StyledMessage = styled(Message)`
  width: 450px;
  align-items: flex-start;

  svg {
    margin-top: 1px;
    margin-right: ${typography.unit * 3}px;
  }
`;
