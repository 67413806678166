import React, { useEffect, useState } from 'react';
import { NewButtonContained } from 'components/shared/button';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { ResetPasswordFormProps } from './ResetPasswordForm.consts';
import {
  ChangePasswordSection,
  LoginContainerBody,
  StyledInfoTooltip,
  StyledError,
  ErrorContainer,
} from './ResetPasswordForm.style';
import NewTextField from 'components/shared/newTextField/NewTextField';

export const ResetPasswordForm = ({ state }: ResetPasswordFormProps) => {
  const {
    handleSubmit,
    formState: { isValid, errors },
    register,
    trigger,
    getValues,
    clearErrors,
  } = useForm({
    mode: 'onBlur',
  });

  const history = useHistory();
  const [disableButton, setDisableButton] = useState(true);

  const onSubmit = async (formData: any) => {
    const { username, session } = state;
    const res = await fetch(`/resetpw`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...formData, username, session }),
    });
    if (res.ok) {
      showToast(MessageType.Success, `Password successfully updated`);
      history.push('/');
      history.go(0);
    }
  };

  const handleKeyPress = async (e: KeyboardEvent) => {
    const password = getValues('password');
    const passwordConfirm = getValues('passwordConfirm');
    if (e.key === 'Enter') {
      if (isValid) {
        await onSubmit({ ['password']: password, ['passwordConfirm']: passwordConfirm });
      } else if (password !== passwordConfirm) {
        trigger('password');
        if (!errors.password) {
          trigger('passwordConfirm');
        }
      }
    }
  };

  const renderError = () => {
    if (errors.password) {
      return <StyledError errors={errors} name="password" className={'reset-password'} />;
    } else if (errors.passwordConfirm) {
      return <StyledError errors={errors} name="passwordConfirm" className={'reset-password'} />;
    }
    return null;
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [isValid]);

  return (
    <>
      <LoginContainerBody>
        <div>
          <p>Welcome to VCE!</p>
          <p>Please change password to continue</p>
        </div>
        <ChangePasswordSection>
          <label htmlFor="password">New Password</label>
          <StyledInfoTooltip>
            Password must include:
            <ul>
              <li>At least 6 Characters</li>
              <li>Upper and lower case letters</li>
              <li>One or more numbers</li>
              <li>Special character: ^ $ * . [ ] {} ( ) ? ! @ # % &amp; / \ , &gt; &lt; &quot; : ; | _ ~ ` = + -</li>
              <li>No spaces</li>
            </ul>
          </StyledInfoTooltip>
          <NewTextField
            register={register}
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            validation={{
              required: true,
              validate: {
                valid: (v: string) =>
                  v.match(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&^()\\/[\]{}<>:;`~_+\-="'|.,])[A-Za-z\d@$!%*#?&^()\\/[\]{}<>:;`~_+\-="'|.,]{6,}$/,
                  )
                    ? true
                    : 'Password does not meet the minimum requirements.',
              },
            }}
            autoFocus
          />
          <label htmlFor="passwordConfirm">Confirm Password</label>
          <NewTextField
            register={register}
            id="passwordConfirm"
            name="passwordConfirm"
            type="password"
            placeholder="Confirm Password"
            validation={{
              required: true,
              validate: {
                valid: (v: string) => (v === getValues('password') ? true : 'Passwords do not match'),
              },
            }}
            onChange={(v) => {
              if (v.target.value === getValues('password')) {
                clearErrors('passwordConfirm');
                if (!errors.password) {
                  setDisableButton(false);
                }
              } else {
                setDisableButton(true);
              }
            }}
          />
        </ChangePasswordSection>
        <NewButtonContained onClick={handleSubmit(onSubmit)} disabled={disableButton}>
          Reset Password
        </NewButtonContained>
      </LoginContainerBody>
      <ErrorContainer>{renderError()}</ErrorContainer>
    </>
  );
};
