import styled from '@emotion/styled';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import { Icon } from '../icon';

export const AccordionContainer = styled.div<StyledWithThemeProps>`
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const AccordionHeader = styled.header<StyledWithThemeProps>`
  display: flex;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.global.border};`};
  cursor: pointer;
  margin-bottom: ${typography.unit * 3}px;
`;

export const AccordionTitle = styled.h2<StyledWithThemeProps>`
  font-size: ${typography.fontSize}px;
  margin: 0 0 ${typography.unit * 2}px 0;
  line-height: ${typography.unit * 5}px;
`;

export const AccordionArrow = styled(Icon)<{ open: boolean }>`
  margin-left: auto;
  transition: transform ${typography.transition}s;
  transform: ${({ open }) => `rotate(${open ? '0' : '180deg'})`};
  width: 14px;
  height: 14px;
`;

export const AccordionContent = styled.div<{ open: boolean }>`
  transition: max-height ${typography.transition}s;
  max-height: ${({ open }) => `${open ? '100vh' : '0'}`};
  overflow: ${({ open }) => `${open ? 'visible' : 'hidden'}`};
`;
