export const prepareDoeTAndCObject = (formData: any, config: any) => {
  const templates: string[] = formData.template;
  const values: number[] = convertStringToIntArray(formData.doeTnCs);
  Object.values(OfferTemplatesConfig).forEach((configuration) => {
    if (formData[configuration.name]) {
      return (formData[configuration.name] = `[${formData[configuration.name]}]`);
    }
  });
  templates?.forEach((templateId) => {
    const templateName = OfferTemplatesConfig[templateId].name;
    const oldValues = convertStringToIntArray(config[templateName]);
    const updatedValues: number[] = [...values];
    if (oldValues) {
      updatedValues.push(...oldValues);
    }
    formData[templateName] = JSON.stringify(updatedValues);
  });

  delete formData.template;
  delete formData.doeTnCs;
};

export const convertStringToIntArray = (data: string) => {
  if (typeof data === 'number') {
    return [data];
  }
  if (data && typeof data === 'string') {
    return data?.split(',')?.map((item: string) => {
      return parseInt(item, 10);
    });
  }
  return;
};

export const OfferTemplatesConfig: {
  [key: string]: {
    name: string;
  };
} = {
  '1': {
    name: 'bogo',
  },
  '2': {
    name: 'singleItemDiscount',
  },
  '3': {
    name: 'multiItemDiscount',
  },
  '4': {
    name: 'substitution',
  },
  '5': {
    name: 'orderDiscount',
  },
  /* OMS-520 - Hide "Punch Card" references from UI
    '6': {
      id: '6',
      name: 'Punch Card',
    },
     */
  '7': {
    name: 'buyBuyGet',
  },
  '8': {
    name: 'buyGetGet',
  },
  '10': {
    name: 'priceDeal',
  },
  '11': {
    name: 'comboPriceDeal',
  },
  '13': {
    name: 'productCombo',
  },
};
