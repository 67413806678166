import styled from '@emotion/styled';
import { ApprovalStatusWithLabel } from 'utils/types';
import { newTypography } from 'styles/typography';
import { NewStatusLabelProps } from './NewStatusLabel.consts';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const NewStyledStatusLabel = styled.span<NewStatusLabelProps>`
  height: 18px;
  padding: ${newTypography.unit * 3}px ${newTypography.unit * 2}px;
  background-color: ${({ type }) => newGlobalTheme.colors.status[type as ApprovalStatusWithLabel]};
  font-family: ${newTypography.primaryFont};
  font-size: 12px;
  color: ${newGlobalTheme.colors.text.primary};
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ type }) => `2px solid ${newGlobalTheme.colors.statusBorder[type as ApprovalStatusWithLabel]}`};
  border-radius: 36px;
`;
