import React, { useState } from 'react';
import { GenericItem } from 'utils/types';
import { ButtonDropdownProps } from './ButtonDropdown.consts';
import { DropdownArrow, NewStyledButtonDropdownContainer, StyledButtonBase, StyledDropdown } from './ButtonDropdown.style';
import { handleKeyPress } from 'utils/keyPress';

export function NewButtonDropdown({
  className,
  position = 'top-right',
  items,
  onItemClick,
  children,
  ...rest
}: ButtonDropdownProps) {
  const [open, setOpen] = useState(false);

  const onItemChange = (item: GenericItem) => {
    if (onItemClick) {
      setOpen(false);
      onItemClick(item);
    }
  };

  const toggleDropdown = () => {
    setOpen(!open);
  };

  return (
    <NewStyledButtonDropdownContainer className={className} {...rest} tabIndex={0}
    onKeyDown={(event) => handleKeyPress(event, () => toggleDropdown())}>
      {open && <StyledDropdown items={items} onClick={onItemChange} position={position} />}
      <StyledButtonBase onClick={() => toggleDropdown()}>
        {children}
        <DropdownArrow name="newArrowUp" open={open} />
      </StyledButtonBase>
    </NewStyledButtonDropdownContainer>
  );
}
