import React from 'react';
import {
  AccordionHeader,
  HeaderSection,
  ListHeadline,
  SortSelectbox,
  StyledAccordion,
  StyledAccordionArrow,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledButtonText,
} from 'components/shared/accordion/itemsSelectionAccordion/ItemsSelectionAccordion.style';
import { ItemsSelectionAccordionProps } from 'components/shared/accordion/itemsSelectionAccordion/ItemsSelectionAccordion.consts';
import { SortingKey } from 'utils/sorting';
import { handleKeyPress } from 'utils/keyPress';

const ItemsSelectionAccordion = React.memo(
  ({
    id,
    headline,
    listName,
    expanded,
    onChange,
    onSortChange,
    children,
    removeAll,
    selectAll,
    className,
    showExpandArrow = true,
    sortOptions,
    disabled = false
  }: ItemsSelectionAccordionProps) => {
    const isOpen = !showExpandArrow || expanded;
    let subTitle = 'Select All';
    if(listName === 'non-custom-sets' && headline === 'Locations') {
      subTitle = 'Customize'
    }
    return (
      <StyledAccordion
        id={id}
        expanded={isOpen}
        onChange={onChange}
        className={className}
        data-automation-id={`${listName}-accordion`}
      >
        <StyledAccordionSummary tabIndex={-1}>
          <AccordionHeader>
            <HeaderSection
              data-automation-id={'HeaderSection'}
              tabIndex={headline ? 0 : -1}
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
                handleKeyPress(event, (e: any) => {
                  if (!isOpen) {
                    onChange(e, true);
                  } else {
                    onChange(e, false);
                  }
                })
              }
            >
              {showExpandArrow && <StyledAccordionArrow open={isOpen} name="arrowUpNew" />}
              <ListHeadline>{headline}</ListHeadline>
            </HeaderSection>
            <HeaderSection
              onClick={(e) => {
                e.stopPropagation();
                if (!isOpen) {
                  onChange(e, true);
                }
              }}
            >
              {sortOptions?.length && (
                <SortSelectbox
                  name="sort"
                  items={sortOptions}
                  initialSelectedItems={[sortOptions[0]]}
                  onChange={(items) => {
                    const val = (Object.values(items)[0] as any).name as SortingKey;
                    onSortChange(listName, val);
                  }}
                  selectWidth={80}
                />
              )}
              {removeAll && (
                <StyledButtonText disabled={disabled} onClick={removeAll}>
                  Remove All
                </StyledButtonText>
              )}
              {selectAll && <StyledButtonText onClick={() => selectAll(listName)}>{subTitle}</StyledButtonText>}
            </HeaderSection>
          </AccordionHeader>
        </StyledAccordionSummary>
        <StyledAccordionDetails>{children}</StyledAccordionDetails>
      </StyledAccordion>
    );
  },
);

export default ItemsSelectionAccordion;
