import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import typography from 'styles/typography';
import zIndex from 'styles/zIndex';
import { ButtonText } from '../button';
import Error from '../error/Error';
import { AdornmentPosition, StyleTextFieldProps } from './NewTextField.consts';

export const StyledContainer = styled.div<StyleTextFieldProps>`
  display: flex;
  flex-direction: ${({ labelIsHorizontal }) => labelIsHorizontal && 'column'};
  align-items: ${({ labelIsHorizontal }) => !labelIsHorizontal && 'center'};
  width: inherit;
  height: 36px;
`;

export const InputContainer = styled.div<StyleTextFieldProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
`;

export const StyledLabel = styled.label<StyleTextFieldProps>`
  font-size: 12px;
  font-weight: 700;
  color: ${({ disabled }) => (!disabled ? newGlobalTheme.colors.text.primary : newGlobalTheme.colors.text.disabled)};
  display: flex;
  align-items: start;
  margin-right: ${typography.unit * 3}px;
  white-space: nowrap;
`;

export const StyledError = styled(Error)`
  position: absolute;
  margin-top: ${typography.unit * 7}px;
  margin-left: ${typography.unit / 2}px;
  white-space: nowrap;
`;

export const InputWrapper = styled.div<StyleTextFieldProps>`
  display: flex;
  flex-direction: ${({ adornmentPosition }) => (adornmentPosition === AdornmentPosition.Start ? 'row' : 'row-reverse')};
  align-items: center;
  color: ${({ theme }) => theme.colors.text.primary};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  background-color: #FFF;

`;

export const StyledInput = styled.input<StyleTextFieldProps>`
  border: none;
  background: none;
  outline: none;
  font-size: 12px;
  font-weight: 400;
  font-family: Speedee;
  flex: 1;
  color: ${newGlobalTheme.colors.text.primary};
  height: 36px;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({width}) => width && `width: ${width}px`};

  &::placeholder {
    color: ${newGlobalTheme.colors.text.disabledSave};
  }

  ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave}` : `color: ${newGlobalTheme.colors.text.primary}`};
`;

export const StyledChildren = styled.div`
  display: flex;
`;

export const StyledViewButton = styled(ButtonText)`
  position: absolute;
  right: ${typography.unit * 4}px;

  &:focus-visible {
    outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
    outline-offset: 4px;
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }
`;
