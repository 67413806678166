import styled from '@emotion/styled';
import typography, { newTypography } from 'styles/typography';
import 'react-toastify/dist/ReactToastify.css';
import { StyledWithThemeProps } from 'utils/types';
import { Icon } from '../../icon';
import { StyledNotificationWithTheme } from '../notifications';
import { NewButtonClose } from '../../button';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import zIndex from 'styles/zIndex';

export const Container = styled.div<StyledNotificationWithTheme & { modalOpen: boolean }>`
  max-width: ${({ modalOpen }) => (modalOpen ? '602px' : '1316px')};
  max-height: 48px;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
  border: 1px solid ${({ theme, type }) => newGlobalTheme.colors.notifications.banner.primary[type]};
  background-color: ${({ theme, type }) => newGlobalTheme.colors.notifications.banner.background[type]};
  padding: ${typography.unit * 3}px ${typography.unit * 3}px;
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)<StyledNotificationWithTheme>`
  fill: ${({ theme, type }) => newGlobalTheme.colors.notifications.banner.primary[type]};
  width: 16px;
  margin-right: 8px;
`;

export const StyledMsg = styled.div<StyledWithThemeProps & { modalOpen: boolean }>`
  font-family: ${newTypography.primaryFont};
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text.primary};
  width: ${({ modalOpen }) => (modalOpen ? '519.37px' : '1189.37px')};
  height: 17px;
  font-style: normal;
  font-size: 14px;
  letter-spacing: -0.03em;
`;

export const StyledButtonClose = styled(NewButtonClose)`
  margin-left: auto;
  padding: 6px 8px;
  &:focus-visible {
    outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
    outline-offset: 4px;
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }
`;
