import styled from '@emotion/styled/macro';
import { NewButtonText } from 'components/shared/button';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';
import zIndex from 'styles/zIndex';
import { StyledWithThemeProps } from 'utils/types';

export const Avatar = styled.div<StyledWithThemeProps>`
  margin: 0 ${newTypography.unit * 4}px 0 0;
  background: ${({ theme }) => newGlobalTheme.colors.global.brandMain};
  width: ${newTypography.unit * 6}px;
  height: ${newTypography.unit * 6}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
`;

export const Profile = styled.div<StyledWithThemeProps>`
  margin: 0 ${newTypography.unit * 4}px 0 0;
  width: ${newTypography.unit * 6}px;
  height: ${newTypography.unit * 6}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
`;

export const UserMenuDropdown = styled.div<StyledWithThemeProps>`
  position: absolute;
  top: calc(100% + ${newTypography.unit * 2}px);
  width: 250px;
  min-height: 224px;
  border: 1px solid ${({ theme }) => newGlobalTheme.colors.global.border};
  border-radius: ${newTypography.borderRadius}px;
  box-shadow: ${({ theme }) => newGlobalTheme.colors.global.boxShadow};
  padding: ${newTypography.unit * 3}px 0 0 ${newTypography.unit * 4}px;
  display: flex;
  flex-direction: column;
  z-index: ${zIndex.dropdown};
  background: white;

  header {
    display: flex;
    align-items: center;

    h5 {
      font-size: 16px;
      font-weight: 400;
    }

    ${Avatar} {
      width: ${newTypography.unit * 8}px;
      height: ${newTypography.unit * 8}px;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    margin: ${newTypography.unit * 4}px 0;
    gap: ${newTypography.unit * 5}px;

    h6 {
      color: #5C5D60;
      font-size: 12px;
      font-weight: 400;
    }
  }

  footer {
    border-top: 1px solid ${({ theme }) => newGlobalTheme.colors.global.border};
    margin-top: auto;
    margin-left: -${newTypography.unit * 4}px;
    padding-left: ${newTypography.unit * 4}px;
    display: flex;
    align-items: center;
    height: ${newTypography.unit * 12}px;

    a {
      color: ${({ theme }) => newGlobalTheme.colors.text.primary};
    }
  }
`;

export const Welcome = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px ${newTypography.unit * 4}px 0 0;
`;

export const StyledLogoutButton = styled(NewButtonText)`
  width: 100%;
  justify-content: flex-start;
`;

export const StyledUserRole = styled.div`
  text-transform: capitalize;
`;
