import styled from '@emotion/styled';
import { newTypography } from 'styles/typography';
import { NewSearchTextField } from 'components/shared/searchTextField/NewSearchTextField';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import NewCheckbox from 'components/shared/checkbox/NewCheckbox';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  font-family: ${newTypography.primaryFont};
  border-top: 6px solid #F6BF35;
  border-radius: 8px 8px 0 0;
  padding: 20px;
  background: ${({ theme }) => newGlobalTheme.colors.global.tileBackground};
`;

export const IconWrapper = styled.div`
  margin-top: ${newTypography.unit * 1.5}px;
`;

export const StyledSearch = styled(NewSearchTextField)`
  margin: ${newTypography.unit * 1}px ${newTypography.unit * 10}px;
`;

export const StyledCheckbox = styled(NewCheckbox)`
  margin: ${newTypography.unit * 1}px ${newTypography.unit * 10}px;
`;
