import React from 'react';
import { TotalAmountProps } from './TotalAmount.consts';
import { StyledTotalAmount } from './TotalAmount.style';

const TotalAmount = ({ amount, className,tabIndex, prefixText = 'Total results of' }: TotalAmountProps) => {
  return (
    <StyledTotalAmount data-automation-id="total-amount" className={className} tabIndex={tabIndex}>
      {prefixText} {amount || 0}
    </StyledTotalAmount>
  );
};

export default TotalAmount;
