import { CampaignProps } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { ApprovalStatus, DateTimeConfig, FormMode } from 'utils/types';
import { CampaignAlert } from 'utils/types/campaigns';
import { VoucherState } from '../../CampaignForm.consts';

export interface CampaignFormFooterProps {
  mode: FormMode;
  campaignStatus: ApprovalStatus;
  alert: CampaignAlert;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  isLocked: boolean;
  isDraft: boolean;
  onSubmit: (isDraft?: boolean) => any;
  className?: string;
  theme?: string;
  fromCalendar?: boolean;
  dateTimeConfig: DateTimeConfig;
  inProgress?: boolean
  offerSource: OfferSource;
  voucherState?: VoucherState;
  isDirty?: boolean
}

export interface CampaignHeaderProps {
  mode: FormMode;
}

export enum CampaignActions {
  Edit = 'edit',
  SubmitForApproval = 'submit for approval',
  Archive = 'archive',
  Duplicate = 'duplicate',
  Approve = 'approve',
  Reject = 'reject',
  Revoke = 'revoke',
  StopAssociation = 'stop association',
  Unarchive = 'unarchive',
  Deploy = 'deploy',
  ReDeploy = 'reDeploy'
}

export interface CampaignExtendedProps extends CampaignProps {
  isLocalCampaign: boolean;
}
