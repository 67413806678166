import React, { useEffect, useState } from 'react';
import xorWith from 'lodash/xorWith';
import { CalendarMonth } from './components/calendarMonth/CalendarMonth';
import { DatesSelectorProps } from './DatesSelector.consts';
import { StyledDatesSelector } from './DatesSelector.style';

export const DatesSelector = ({
  startDate = new Date(),
  selectedDates: initialSelectedDates = [],
  months: initialMonthsDisplayed = 14,
  onChange,
  disablePast = true,
  datesToShow
}: DatesSelectorProps) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>(initialSelectedDates);
  const [displayedMonths, setDisplayedMonths] = useState<number>(initialMonthsDisplayed);
  const nextSetOfMonthsDisplayed = 12;

  const months = new Array(displayedMonths).fill(null).map((_, i) => {
    const newDate = new Date(startDate);
    newDate.setDate(1);
    return new Date(newDate.setMonth(newDate.getMonth() + i));
  });

  useEffect(() => {
    onChange(selectedDates);
  }, [selectedDates]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight + 10) {
      setDisplayedMonths((prev) => prev + nextSetOfMonthsDisplayed);
    }
  };

  return (
    <StyledDatesSelector data-automation-id="calendar-container" onScroll={handleScroll}>
      {months.map((d) => (
        <CalendarMonth
          key={d.toString()}
          year={d.getFullYear()}
          month={d.getMonth()}
          onClick={(date: Date) => {
            const newDates = xorWith([...selectedDates], [date], (a, b) => a.getTime() === b.getTime());
            setSelectedDates(newDates);
          }}
          disablePast={disablePast}
          selectedDates={selectedDates}
          datesToShow = {datesToShow}
        />
      ))}
    </StyledDatesSelector>
  );
};
