import styled from '@emotion/styled';
import { ButtonText } from 'components/shared/button/buttonText/ButtonText';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const StyledButtonText = styled(ButtonText)`
  font-size: 14px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  text-align: left;
  color: ${newGlobalTheme.colors.global.textSubheading};
  display:flex;
  align-items:center;
`;

export const RowFooter = styled.div`
display:flex;
flex-direction:row;
align-items:center;
justify-content:space-between;
width:222px;
margin-top:16px;
`;

export const OfferHeading = styled.div`
display:flex;
flex-direction:row;
align-items:center;
width:222px;
`;

export const NoOfferSelected = styled.div`
  width:100%;
  height: 188px;
  border: 1px dashed ${newGlobalTheme.colors.global.border};
  border-radius: 5px;
  display: flex;
  align-items:center;
  position: relative;
`

export const TextNoOfferSelected = styled.div`
font-family: Speedee;
margin-left:auto;
margin-right:auto;
font-size: 14px;
font-weight: 400;
line-height: 22.83px;
letter-spacing: -0.03em;
text-align: center;
color:${newGlobalTheme.colors.text.nonhighlighted}
`;

