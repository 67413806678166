import React from 'react';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { users } from 'app/slices/users';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { EntityType } from './EntityApproveButton.consts';

export const EntityApproveButton = (props: any) => {
  const { config } = useSelector(marketConfig);
  const { enableOfferSelfApproval, enableCampaignSelfApproval } = config;
  const { user } = useSelector(users);
  const { entity, children, entityType } = props;

  if (user.id === entity.createdBy?.id || user.id === entity.updatedBy?.id) {
    if (entityType === EntityType.Offer && enableOfferSelfApproval) {
      return <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin]}>{children}</RoleGuard>;
    }
    if (entityType === EntityType.Campaign && enableCampaignSelfApproval) {
      return <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin]}>{children}</RoleGuard>;
    }
    return null;
  }

  return (
    <RoleGuard
      roles={[
        UserRole.SysAdmin,
        UserRole.Admin,
        UserRole.Creator,
        ...(entityType === EntityType.Offer ? [UserRole.Trainee] : []),
      ]}
    >
      {children}
    </RoleGuard>
  );
};

export const EntityApprovalButton = (props: any) => {
  const { children } = props;
  return <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator]}>{children}</RoleGuard>;
};
