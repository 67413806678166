import styled from '@emotion/styled/macro';
import typography, { newTypography } from 'styles/typography';
import Accordion from '@mui/material/Accordion';
import { StyledWithThemeProps } from 'utils/types';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Selectbox } from 'components/shared/selectbox/Selectbox';
import { NewButtonText } from 'components/shared/button';
import { AccordionArrow } from 'components/shared/accordion/Accordion.style';

export const AccordionHeader = styled.div<StyledWithThemeProps>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const StyledAccordion = styled(Accordion)<{ expanded: boolean } & StyledWithThemeProps>`
  flex: ${({ expanded }) => `${expanded ? '1' : '0'}`};
  min-height: ${({ expanded }) => `${expanded ? '' : '65px !important'}`};
  width: 100%;
  margin: 0 !important;
  box-shadow: none !important;
  padding: ${newTypography.unit * 4}px ${newTypography.unit * 4}px ${newTypography.unit * 4}px ${newTypography.unit * 4}px;
  :first-of-type {
    border-top: none !important;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)<StyledWithThemeProps>`
  padding: 0 !important;
  min-height: 36px !important;
  flex-grow: 1;
  .MuiAccordionSummary-content {
    margin: 0;
    height: 20px;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 6px 0 10px 0;
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledAccordionArrow = styled(AccordionArrow)`
  margin-right: ${typography.unit * 2}px;
  width: 12px;
`;

export const ListHeadline = styled.div`
  font-family: ${newTypography.primaryFont};
  font-weight: 400;
  line-height: 17.7px;
`;

export const SortSelectbox = styled(Selectbox)`
  font-size: 12px;
  padding-left: ${typography.unit}px;
` as unknown as typeof Selectbox;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
  padding: 0 !important;
`;

export const StyledButtonText = styled(NewButtonText)`
  margin-left: ${newTypography.unit * 2}px;
  font-size: 14px;
  color: #006BAE;
  font-weight: 700;
  font-family: ${newTypography.primaryFont};
  line-height: 22.83px;
  &:disabled {
    color: #6F6F6F;
  }
`;
