import React from 'react';
import { LoaderColor, LoaderProps, LoaderSize } from './Loader.consts';
import { StyledLoader } from './Loader.styles';

export const Loader = ({
  size = LoaderSize.Medium,
  color,
  className,
  role = 'status', 
  ariaLabel = 'Loading', 
  ariaBusy = true, 
  ariaLive = 'assertive', 
}: LoaderProps & React.HTMLAttributes<HTMLSpanElement> & { 
  role?: string; 
  ariaLabel?: string; 
  ariaBusy?: boolean; 
  ariaLive?: 'assertive' | 'polite' | 'off'; // Restricting ariaLive to specific values
}) => 
 (
  <StyledLoader
    size={size}
    color={color}
    className={className}
    role={role}
    aria-label={ariaLabel}
    aria-busy={ariaBusy}
    aria-live={ariaLive}
    data-automation-id="loader"
  >
    <span style={{ position: 'absolute', left: '-9999px' }}>
      {ariaLabel}
    </span>
  </StyledLoader>
);
