import styled from '@emotion/styled';
import typography from 'styles/typography';
import { Icon, IconProps } from '../../icon';

export const InfoIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items:center;
`;

export const InfoIcon = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.global.inputPrimary};
  width: 12px;
  margin-left: ${typography.unit}px;
`;
