import styled from '@emotion/styled';
import { StyledWithThemeProps } from 'utils/types';
import { newTypography } from 'styles/typography';
import { Icon, IconProps } from 'components/shared/icon';
import { ThemeProps } from 'pages/settings/termsConditions/TermsConditions.consts';
import { NewButtonText } from 'components/shared/button';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

type ColorUnion = 'green' | 'red' | 'yellow' | 'blue' | 'grey' | 'orange';
export const StyledHeader = styled.header<ThemeProps>`
  display: flex;
  height: 95px;
  justify-content: space-between;
  align-items: center;
  padding-left: ${newTypography.unit * 9}px;
`;

export const LastUpdated = styled.span<{ align?: 'right' | 'left' }>`
  display: flex;
  justify-content: ${({ align }) => align || 'left'};
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 22.83px;
  color: ${newGlobalTheme.colors.text.primary};
`;

export const Board = styled.div<StyledWithThemeProps>`
  background-color: ${({ theme }) => newGlobalTheme.colors.global.selectedBackground};
  flex: 1;
  display: flex;
  overflow: auto;
  margin-top: 40px;
  font-family: ${newTypography.primaryFont};
  color: ${newGlobalTheme.colors.text.primary};
`;

export const WrapperBoard = styled.div<StyledWithThemeProps>`
  display: flex;
  margin: auto;
`;

export const InnerBoard = styled.div`
  margin-top: 32px
`;

export const OverviewBase = styled.div<StyledWithThemeProps>`
  background-color: ${({ theme }) => newGlobalTheme.colors.global.background};
  border-radius: ${newTypography.borderRadius}px;
  display: flex;
  flex-direction: column;
`;

export const OverviewHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 1004px;
`;

export const OverviewHeader = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 22.83px;
  margin-left: ${newTypography.unit * 6.5}px;
`;

export const OverviewSubHeader = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 22.83px;
`;

export const SideOverviewHeader = styled(OverviewHeader)`
  margin-bottom: ${newTypography.unit * 3.5}px;
  margin-left: 0px;
`;

export const CampaignsOverview = styled(OverviewBase)`
  width: 1068px;
  height: 1045px;
  margin-right: ${newTypography.unit * 8}px;
  padding: ${newTypography.unit * 5}px ${newTypography.unit * 4}px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OffersOverview = styled(OverviewBase)`
  width: 288px;
  height: 291px;
  padding: ${newTypography.unit * 5}px ${newTypography.unit * 7}px;
  margin-bottom: ${newTypography.unit * 8}px;
`;

export const SideOverviewContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

export const Box = styled.div<
  StyledWithThemeProps & {
    color?: ColorUnion;
    borderColor?: ColorUnion;
  }
>`
  background-color: ${({ color, theme }) => color && newGlobalTheme.colors.dashboard[color]};
  width: 225px;
  display: flex;
  flex-direction: column;
  padding: ${newTypography.unit * 2}px ${newTypography.unit * 3}px;
  border-radius: ${newTypography.borderRadius}px;
  border: ${({ color, theme }) => !color && `1px solid ${newGlobalTheme.colors.global.border}`};
  cursor: pointer;
  transition: all ${newTypography.transition}s;

  &:hover {
    box-shadow: ${({ theme }) => newGlobalTheme.colors.global.boxShadow};
  }
`;

export const NoRedemptionHeader = styled.div`
  height: 72px;
  cursor: pointer;
`;

export const NoRedemptionTableContainer = styled.div`
  height: 658px;
  padding: 4px;
  background-color: rgba(245, 245, 245, 1);
  border-radius: ${newTypography.borderRadius * 1.4}px;
`;

export const StatusBigBox = styled(Box)`
  height: 147px;
  border-top: ${({ color, theme }) => color && `6px solid ${newGlobalTheme.colors.dashboard.border[color]}`};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StatusSmallBox = styled(Box)`
  height: 84px;
  border-top: ${({ borderColor, theme }) =>
    borderColor && `4px solid ${newGlobalTheme.colors.dashboard.border[borderColor]}`};
  border-radius: 8px;
  padding: ${newTypography.unit * 2.5}px ${newTypography.unit * 5.5}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const OverviewSmallBox = styled(StatusSmallBox)`
  width: 230px;
  justify-content: center;
  align-items: baseline;
  cursor: auto;
  &:hover {
    box-shadow: none;
  }
  margin-bottom: ${newTypography.unit * 4.25}px;
`;

export const OffersOverviewSmallBox = styled(StatusSmallBox)`
  justify-content: center;
  align-items: baseline;
  margin-bottom: ${newTypography.unit * 4.25}px;
  font-weight: 400;
  line-height: 22.83px;
`;

export const StatusLargeBox = styled(Box)`
  height: 244px;
  border-top: ${({ borderColor, theme }) =>
    borderColor && `4px solid ${newGlobalTheme.colors.dashboard.border[borderColor]}`};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: baseline;
  padding: 10px 22px;
`;

export const StatusMidLargeBox = styled(StatusLargeBox)`
  height: 163px;
`;

export const Number = styled.span<StyledWithThemeProps & { isAlert?: boolean }>`
  font-size: 24px;
`;

export const BigNumber = styled.div<StyledWithThemeProps & { isAlert?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 102px;
  height: 43px;
  font-size: 56px;
  font-weight: 700;
  line-height: 22.83px;
`;

export const SmallNumber = styled.div<StyledWithThemeProps & { isAlert?: boolean; cursor?: string }>`
  font-size: 28px;
  line-height: 22.83px;
  font-weight: 700;
  color: rgba(41, 41, 41, 1);
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
`;

export const RedemptionSmallNumber = styled(SmallNumber)`
  margin-top: 5px;
`;

export const AlertLabel = styled.div<StyledWithThemeProps & { isAlert?: boolean }>`
  display: flex;
  align-items: center;
  height: 33px;
  font-size: 14px;
  font-weight: 400;
  line-height: ${({ color }) => (color ? '22.83px' : '18px')};
  color: ${({ color, theme }) => (color ? newGlobalTheme.colors.text.primary : newGlobalTheme.colors.global.error)};
`;

export const LabelText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22.83px;
`;

export const NoRedemptionAlertLabel = styled(AlertLabel)`
  height: 72px;
  font-size: 28px;
  font-weight: 700;
  margin: 0 20px;
  justify-content: space-between;
`;

export const NoRedemptionAlert = styled.div`
  display: flex;
`;

export const NoRedemptionAlertLabelSpan = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22.83px;
  margin-left: 10px;
  margin-top: 5px;
  color: rgba(41, 41, 41, 1);
`;

export const CampaignSection = styled.div<StyledWithThemeProps>`
  width: 1038px;
  padding: ${newTypography.unit * 4}px ${newTypography.unit * 7}px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
`;

export const StatusRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PendingApprovalStartsInBox = styled(Box)`
  width: 200px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${newTypography.unit}px ${newTypography.unit * 2}px;
  border: 0;
  cursor: pointer;
  margin-top: ${newTypography.unit}px;

  &:hover {
    box-shadow: ${({ theme }) => newGlobalTheme.colors.global.boxShadow};
  }
`;

export const AlertsHeader = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: 5px;
`;

export const AlertIcon = styled(Icon)<IconProps>`
  width: 16px;
  margin-right: ${newTypography.unit * 2}px;
  fill: ${({ theme }) => newGlobalTheme.colors.global.error};
`;

export const RedemptionsHeader = styled(OverviewSubHeader)`
  margin-bottom: ${newTypography.unit * 4}px;
`;

export const RedemptionsHeaderSpan = styled(RedemptionsHeader)`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
`;

export const NoRedemptionsSection = styled(CampaignSection)`
  flex: 1;

  .MuiDataGrid-root {
    border: none;
    height: 578px !important;

    .MuiDataGrid-columnHeader {
      padding-left: 20px !important;
      line-height: 16px !important;
    }

    .MuiDataGrid-columnHeaderTitleContainer {
      padding-left: 0 !important;
      font-size: 14px !important;
    }

    .MuiDataGrid-row {
      min-height: 45px !important;
      max-height: 45px !important;
      font-size: 14px;
    }

    .MuiDataGrid-cell {
      padding-left: 20px !important;
      padding-right: 0 !important;
      line-height: 16px !important;
      min-height: 45px !important;
      max-height: 45px !important;
    }
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: 700 !important;
  }
`;

export const CampaignNameButton = styled(NewButtonText)`
  max-width: 300px;
  font-size: 14px;
  line-height: 16px;
`;
