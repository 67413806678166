import styled from '@emotion/styled/macro';
import { newTypography } from 'styles/typography';
import zIndex from 'styles/zIndex';
import { StyledWithThemeProps } from 'utils/types';
import { NewButtonText } from 'components/shared/button';
import { Icon } from 'components/shared/icon';
import InfiniteScroll from 'react-infinite-scroll-component';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const NotificationsMenuDropdown = styled.div<StyledWithThemeProps>`
  position: absolute;
  top: calc(70% + ${newTypography.unit * 2}px);
  right: ${newTypography.unit * 20}px;
  width: 394px;
  height: 398px;
  border: 1px solid ${({ theme }) => newGlobalTheme.colors.global.border};
  box-shadow: ${({ theme }) => newGlobalTheme.colors.global.boxShadow};
  padding: ${newTypography.unit * 3}px ${newTypography.unit * 4}px;
  display: flex;
  flex-direction: column;
  z-index: ${zIndex.dropdown};
  background: ${({ theme }) => newGlobalTheme.colors.global.background};
  border-top: 60px solid #ffbc0f;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h5 {
      padding-right: 75px;
      font-family: ${newTypography.primaryFont};
      font-size: 18px;
      font-weight: 700;
      line-height: 22.83px;
      letter-spacing: -0.03em;
      text-align: left;
    }
  }
`;
export const NoNotificationsContainer = styled.div<StyledWithThemeProps>`
 h5 {
      font-family: ${newTypography.primaryFont} !important;;
      padding-right: 150px !important;
      font-size: 18px !important;;
      font-weight: 700 !important;;
      line-height: 22.83px !important;;
      letter-spacing: -0.03em !important;;
      text-align: left !important;;
    }
`;
export const NotificationsListWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: -${newTypography.unit * 2}px;

  ::-webkit-scrollbar {
     width: 21px;
  }

  ::-webkit-scrollbar-thumb {
    position:inherit;
    border: 7px transparent solid;
    background-clip: padding-box;
    border-radius: 10px;
    height: 155px;
    top: 148px;
    right:10px;
  }
`;

export const NotificationsList = styled(InfiniteScroll)`
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
  gap: ${newTypography.unit * 5}px;
  padding-bottom: 20px;
   ::-webkit-scrollbar-thumb {
  opacity:0;
  }
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${newTypography.unit}px;
  background-color: 50px solid #ffbc0f;
  font-family: ${newTypography.primaryFont};
  position: absolute;
  top: -33px;
  left: 0;
  right: 0;
  text-align: center;
  font-family: ${newTypography.primaryFont};
  font-size: 16px;
  font-weight: 400;
`;

export const StyledSeparator = styled.div<StyledWithThemeProps>`
  height: 10px;
  width: 5px;
  align-self: center;
  margin: 0 1px;
`;

export const StyledActionBorder = styled.div`
  width: 60px;
  height: 19px;
  top: 110px;
  gap: 0px;
  border-radius: 5.02px;
  border: 1.26px;
  opacity: 0px;
  border: 1.26px solid #c08b00;
  text-align: center;
  &:focus {
    opacity: 0.2;
    outline: 0.1px solid;
  }
`;

export const StyledAction = styled(NewButtonText)`
padding-top: 4px;
padding-left: 22px;
width: 36px;
height: 14.63px;
top: 113.31px;
left: 1262px;
gap: 0px;
opacity: 0px;
font-family: Speedee;
font-size: 9px;
font-weight: 700;
line-height: 12px;
letter-spacing: -0.03em;
text-align: center;
color: #292929;
`;

export const NotificationContainer = styled.div`
  display: flex;
  padding-top: 8px;
  [data-title]:after {
    left: -160%;
  }
`;

export const StyledIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin: ${newTypography.unit}px ${newTypography.unit * 3}px ${newTypography.unit}px ${newTypography.unit}px;
`;

export const StyledUnreadNotificationIcon = styled(Icon)`
  width: 22px;
  height: 22px;
  fill: ${({ theme }) => newGlobalTheme.colors.text.primary};
`;

export const StyledBellIcon = styled(Icon)`
  width: 22px;
  height: 22px;
  fill: ${({ theme }) => newGlobalTheme.colors.text.primary};
`;

export const StyledNonNotification = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
