import React, { useState } from 'react';
import { FormMode } from 'utils/types';
import { SetItemsSelectionFormFooterProps } from 'pages/shared/setItemsSelectionForm/setItemsSelectionFormFooter/SetItemsSelectionFormFooter.consts';
import Error from 'components/shared/error/Error';
import { store } from 'app/store';
import { closeModal } from 'app/slices/modals';
import { hideTooltip } from 'utils/tooltip';
import { ButtonsContainer, CancelButton, EditButton, Footer, SaveButton } from './SetItemsSelectionFormFooter.style';
import NewTooltip from 'components/shared/tooltip/NewTooltip';

const DELETE_TOOLTIP_ID = '#delete-tooltip';

const SetItemsSelectionFormFooter = ({
  onSave,
  formMode,
  onDelete,
  onDuplicate,
  onViewUsage,
  onEdit,
  onClose,
  formState,
  isReadOnly = false,
  isNonProduct = false,
}: SetItemsSelectionFormFooterProps) => {
  const { isDirty, isValid, errors } = formState;
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const handleAction = (actionFn: () => void) => {
    setButtonsDisabled(true);
    try {
      actionFn();
    } finally {
      setButtonsDisabled(false);
    }
  };

  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false);

      const handleDelete = (actionFn: () => void) => {
        if (!isDeleteButtonClicked) {
          setIsDeleteButtonClicked(true);
          actionFn();
          hideTooltip(DELETE_TOOLTIP_ID);
        }
      };

  const GeneralButtons = (
    <>
      {isDirty && (
        <NewTooltip
          id="cancel-tooltip"
          content="Are you sure you want to cancel?"
          onDisapproveClick={() => hideTooltip('#cancel-tooltip')}
          onApproveClick={() => (onClose ? onClose() : store.dispatch(closeModal()))}
        />
      )}
      <CancelButton
        data-tip
        data-for="cancel-tooltip"
        onClick={() => (!isDirty ? (onClose ? onClose() : store.dispatch(closeModal())) : null)}
      >
        Cancel
      </CancelButton>
      <SaveButton disabled={!isDirty || !isValid || buttonsDisabled} onClick={() => handleAction(onSave)}>
        Save
      </SaveButton>
    </>
  );

  const FormSectionToComponent: Record<FormMode, JSX.Element> = {
    [FormMode.View]: (
      <>
        <CancelButton onClick={() => (onClose ? onClose() : store.dispatch(closeModal()))}>Close</CancelButton>
        <EditButton disabled={buttonsDisabled || isReadOnly} onClick={onEdit}>
          Edit
        </EditButton>
      </>
    ),
    [FormMode.New]: GeneralButtons,
    [FormMode.Select]: GeneralButtons,
    [FormMode.Edit]: GeneralButtons,
    [FormMode.Duplicate]: GeneralButtons,
    [FormMode.SelectionView]: (
      <CancelButton onClick={() => (onClose ? onClose() : store.dispatch(closeModal()))}>Close</CancelButton>

    ),
    [FormMode.QuickView]: null,
  };
  return (
    <Footer>
      <div>
        {Object.keys(errors)
          .filter((field) => field !== 'name')
          .map((errorKey) => (
            <Error name={errorKey} key={errorKey} className={"SetItemSelectionForm"} errors={errors} />
          ))}
      </div>
      <ButtonsContainer>{FormSectionToComponent[formMode]}</ButtonsContainer>
    </Footer>
  );
};
export default SetItemsSelectionFormFooter;
