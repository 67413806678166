import React, { useState } from 'react';
import { TooltipProps } from './Tooltip.consts';
import {
  StyledTooltip,
  StyledContent,
  ButtonsContainer,
  StyledButtonClose,
  StyledButtonText,
  StyledTextArea,
  CancelButton,
} from './NewTooltip.style';

const Tooltip = ({
  id,
  content,
  onClose,
  approveMsg = 'Cancel',
  disapproveMsg = 'No',
  isWithResponse = false,
  responsePlaceholder = '',
  onDisapproveClick,
  onApproveClick,
  onApproveWithResponseClick,
  className,
  place = 'top',
  onHover = false,
  eventOff = 'blur',
  children,
  version,
  ...rest
}: TooltipProps) => {
  const [response, setResponse] = useState(null);
  return (
    <StyledTooltip
      id={id}
      className={className}
      eventOff={eventOff}
      event={onHover ? null : 'click'}
      effect="solid"
      place={place}
      type="light"
      data-automation-id="tooltip"
      version={version}
      {...rest}
    >
      <StyledContent version={version} shouldMargin={Boolean(onClose)}>
        {onClose && <StyledButtonClose version={version} onClick={onClose} />}
        {content ?? null}
        {children ?? null}
      </StyledContent>
      {isWithResponse && (
        <StyledTextArea onChange={(e) => setResponse(e.target.value)} rows={2} placeholder={responsePlaceholder} />
      )}
      {(onDisapproveClick || onApproveClick) && (
        <ButtonsContainer>
          {onDisapproveClick && (
            <StyledButtonText
              onClick={() => {
                onDisapproveClick();
              }}
            >
              {disapproveMsg}
            </StyledButtonText>
          )}
          {(onApproveClick || onApproveWithResponseClick) && (
            <CancelButton
              onClick={isWithResponse ? () => onApproveWithResponseClick(response) : () => onApproveClick()}
            >
              {approveMsg}
            </CancelButton>
          )}
        </ButtonsContainer>
      )}
    </StyledTooltip>
  );
};

export default Tooltip;
