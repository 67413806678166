import React from 'react';
import { TextFieldProps } from '../textField/TextField.consts';
import { StyledOfferFormTextField } from './OfferFormTextField.style';
import { getIsControlWithError } from 'utils/form';


export const OfferFormTextField = (props: TextFieldProps) => {
    let hasError;
    if (props.errors && props.name) {
        hasError = getIsControlWithError(props.name, props.errors);
    }
    return <StyledOfferFormTextField hasError={hasError} version={props.version} {...props}/>;
  };
