import { VoucherConfigSectionProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/voucherConfigSection/VoucherConfigSection.consts';
import {
  GamingToggleSwitch,
  GamingTypeWrapper,
  IwinStyledSelectBox,
  Quantity,
  SectionContainer,
  StyledSelectBox,
  VoucherConfigRow,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/voucherConfigSection/VoucherConfigSection.style';
import { useFormContext, useFormState } from 'react-hook-form';
import RadioGroup from 'components/shared/radioGroup/RadioGroup';
import { capitalize } from 'utils/text';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { campaignsGqls } from 'pages/campaigns/campaignManagement/Campaigns.gqls';
import { Game, GamePlatform, GamePlatformFeatureToggle, GameStatus, GamingVoucherType } from 'utils/types/games';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { getMarketDatetime, convertUtcDateToTimezoneDate } from 'utils/date';
import { ApprovalStatus, FormMode } from 'utils/types';
import { isInArray } from 'utils/array';
import { CampaignType } from 'utils/types/campaigns';
import { shouldShowFieldInViewMode } from 'pages/campaigns/campaignManagement/components/campaignForm/CampaignForm.utils';
import { DistributionType } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import { FetchPolicies, ValidationMessages } from 'utils/types/common';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';

const VoucherConfigSection = ({ isDisabled, mode }: VoucherConfigSectionProps) => {
  const { register, unregister, control, watch, setValue, getValues, trigger } = useFormContext();
  const { errors } = useFormState();
  const { features, config } = useSelector(marketConfig);
  const [previousSelectedGame, setPreviousSelectedGame] = useState(undefined);

  const { startTimezone, endTimezone } = config;
  const campaign = getValues();
  const voucherConfigPlatformPath = 'voucherConfig.platform';
  const scheduleCampaignStartPath = 'schedule.campaignStart';
  const scheduleCampaignEndPath = 'schedule.campaignEnd';
  const voucherConfigGamingPath = 'voucherConfig.gaming';
  const voucherConfigGamePath = 'voucherConfig.game';
  const voucherConfigDistributionPath = 'voucherConfig.distribution';

  const [type, gamingPlatform, voucherTypeGaming, selectedGameName, distributionType] =
    watch([
      'type',
      voucherConfigPlatformPath,
      voucherConfigGamingPath,
      voucherConfigGamePath,
      voucherConfigDistributionPath,
    ]);
  const { data } = useQuery(campaignsGqls.queries.getGames, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    skip: !gamingPlatform,
    variables: {
      data: {
        filters: {
          OR: [
            {
              AND: [{ status: { is: GameStatus.Created } }, { platform: { is: gamingPlatform } }],
            },
            ...(isInArray([FormMode.View, FormMode.Edit], mode)
              ? [{ name: { is: campaign?.voucherConfig?.game ?? '' } }]
              : []),
          ],
        },
      },
    },
  });

  const { data: originalCampaignFromDb } = useQuery(campaignsGqls.queries.getById, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    skip: !campaign.id,
    variables: {
      id: campaign.id,
    },
  });

  const selectedGame = useMemo<Game>(
    () => data?.getGames.items.find((game: Game) => game.name === selectedGameName),
    [data, selectedGameName],
  );

  const isGamingPlatformActive = (gamePlatform: GamePlatform) =>
    !GamePlatformFeatureToggle[gamePlatform] || features[GamePlatformFeatureToggle[gamePlatform]];

  const platformsOptions =
    Object.entries(GamePlatform)
      .filter(([, val]) => isGamingPlatformActive(val))
      .map(([key, val]) => ({
        id: val,
        name: key,
      })) ?? [];

  const currentDate = new Date();
  const platformGamesOptions =
    [ApprovalStatus.PendingApproval, ApprovalStatus.Draft, ApprovalStatus.Rejected].includes(campaign.status) &&
    mode === FormMode.Edit
      ? data?.getGames.items.filter((game: Game) => new Date(game.endDateTime) > currentDate)
      : data?.getGames.items;

  const minGameQuantity = originalCampaignFromDb?.getCampaign.voucherConfig?.quantity
    ? parseInt(originalCampaignFromDb.getCampaign.voucherConfig.quantity, 10)
    : 1;

  const isAfterGameStart =
    startTimezone &&
    selectedGame &&
    getMarketDatetime(startTimezone).getTime() >
      convertUtcDateToTimezoneDate(selectedGame.startDateTime, startTimezone).getTime();

  useEffect(() => {
    if (gamingPlatform === GamePlatform.Other) {
      unregister(voucherConfigGamePath);
    }
  }, [gamingPlatform]);

  useEffect(() => {
    if (voucherTypeGaming) {
      setValue(voucherConfigPlatformPath, getValues(voucherConfigPlatformPath) || GamePlatform.Other);
    }
  }, [voucherTypeGaming]);

  useEffect(() => {
    if (distributionType) {
      setValue(voucherConfigDistributionPath, getValues(voucherConfigDistributionPath) || DistributionType.Digital);
    }
  }, [distributionType]);

  useEffect(() => {
    if (type !== CampaignType.Voucher) {
      unregister(voucherConfigPlatformPath);
      unregister(voucherConfigGamePath);
      unregister(voucherConfigGamingPath);
      unregister(voucherConfigDistributionPath);
    } else {
      register(voucherConfigGamingPath);
    }
  }, [type]);

  useEffect(() => {
    if (previousSelectedGame && !selectedGameName) {
      setValue('restaurantEligibility.restaurants', null);
      setValue('restaurantEligibility.restaurantGroups', null);
      setValue('restaurantEligibility.excludeRestaurants', null);
      setValue('restaurantEligibility.excludeRestaurantGroups', null);
      setValue('restaurantEligibility.isNationwide', false)
    }
    setPreviousSelectedGame(selectedGameName);
  }, [selectedGameName]);

  useEffect(() => {
    trigger(voucherConfigGamePath);
  }, [selectedGame]);

  const selectboxProps = {
    placeholder: 'Select',
    control: control,
    errors: errors,
    name: voucherConfigGamePath,
    label: 'Game',
    disabled: isDisabled,
    valueField: 'name',
    onChange: (game: Game) => {
      const { restaurantEligibility, startDateTime, endDateTime } = game;
      setValue('restaurantEligibility', restaurantEligibility);
      const newStartDateLimit = convertUtcDateToTimezoneDate(startDateTime, startTimezone);
      const newEndDateLimit = convertUtcDateToTimezoneDate(endDateTime, endTimezone);

      if (getMarketDatetime(startTimezone) < newStartDateLimit) {
        setValue(scheduleCampaignStartPath, newStartDateLimit);
        setValue(scheduleCampaignEndPath, newEndDateLimit);
      } else {
        setValue(scheduleCampaignStartPath, getMarketDatetime(startTimezone, 5));
        setValue(scheduleCampaignEndPath, newEndDateLimit);
      }
    },
    items: platformGamesOptions,
    labelIsHorizontal: true,
    validation: {
      required: ValidationMessages.RequiredField,
      validate: (gameName: string) => {
        const game = platformGamesOptions?.find((option: Game) => option.name === gameName);
        if (game && isInArray([GameStatus.Stopped, GameStatus.Canceled], game.status)) {
          return `The game is ${game.status.toLowerCase()}`;
        }
        return null;
      },
    },
    selectWidth: 257,
    initialSelectedItems: selectedGameName ? [selectedGameName] : undefined,
    getIsOptionDisabled: (game: Game) => game.status !== GameStatus.Created,
  };

  return type === CampaignType.Voucher && shouldShowFieldInViewMode(mode, getValues(voucherConfigGamingPath)) ? (
    <SectionContainer>
      <VoucherConfigRow platform={gamingPlatform}>
        <GamingToggleSwitch
          checked={voucherTypeGaming}
          label="Gaming"
          disabled={isDisabled}
          onClick={() => {
            unregister(voucherConfigPlatformPath);
            unregister(voucherConfigGamePath);
            setValue(voucherConfigGamingPath, !voucherTypeGaming);
          }}
        />
        {voucherTypeGaming && (
          <GamingTypeWrapper>
            <StyledSelectBox
              control={control}
              errors={errors}
              name="voucherConfig.platform"
              label="Platform"
              placeholder="Select"
              disabled={isDisabled}
              items={platformsOptions}
              labelIsHorizontal
              selectWidth={160}
              defaultValue={GamePlatform.Other}
              initialSelectedItems={
                gamingPlatform
                  ? [typeof gamingPlatform === 'object' ? gamingPlatform.id : gamingPlatform]
                  : [GamePlatform.Other]
              }
              version="campaign-form"
              containerGap={8}
            />
            <RadioGroup
              control={control}
              name="voucherConfig.gamingType"
              radioList={Object.values(GamingVoucherType).map((gamingVoucherType) => ({
                value: gamingVoucherType,
                label: capitalize(gamingVoucherType),
              }))}
              defaultValue={GamingVoucherType.Single}
              disabled={isDisabled}
            />
          </GamingTypeWrapper>
        )}
      </VoucherConfigRow>
      {voucherTypeGaming && gamingPlatform === GamePlatform.iWin && (
        <VoucherConfigRow height={80}>
          {platformGamesOptions && <IwinStyledSelectBox {...selectboxProps} version="campaign-form" disabled={isDisabled}/>}
          {!platformGamesOptions && <NewSelectbox {...selectboxProps} items={undefined} version="campaign-form"
              containerGap={8} />}
          <Quantity
            register={register}
            errors={errors}
            disabled={
              mode === FormMode.View ||
              (mode === FormMode.Edit &&
                isInArray(
                  [ApprovalStatus.Active, ApprovalStatus.Deployed, ApprovalStatus.AssociationStopped],
                  campaign?.status,
                ) &&
                (isAfterGameStart || !originalCampaignFromDb?.getCampaign?.voucherConfig?.quantity))
            }
            placeholder="Unlimited when empty"
            label="Quantity"
            name="voucherConfig.quantity"
            labelIsHorizontal
            type="number"
            onChange={(e) =>
              setValue('voucherConfig.quantity', e.target.value.length ? parseInt(e.target.value, 10) : null, {
                shouldTouch: true,
                shouldValidate: true,
                shouldDirty: true,
              })
            }
            version="campaign-form"
            validation={{
              min: {
                value: minGameQuantity,
                message: `Minimum quantity is ${minGameQuantity}`,
              },
              pattern: { value: /^[1-9][0-9]*$/, message: 'Quantity should be an integer' },
              setAsNumber: true,
            }}
          />
        </VoucherConfigRow>
      )}
    </SectionContainer>
  ) : null;
};

export default VoucherConfigSection;
