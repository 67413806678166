import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';
import { StyledErrorProps } from './Error.consts';

export const StyledError = styled.div<StyledErrorProps & {className?: string}>`
  color: ${newGlobalTheme.colors.text.error};
  font-family: ${newTypography.primaryFont};
  font-size: ${({ className}) => ["SetItemSelectionForm"].includes(className) ? 14 : 10 }px;
  font-weight: ${({ className}) => ["SetItemSelectionForm"].includes(className) ? 400 : 300 };
  line-height: ${({ className}) => ["SetItemSelectionForm"].includes(className) ? 22.83 : 12.64 }px;;
  text-align: left;
  letter-spacing: -0.03em;
`;
