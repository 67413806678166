import React from 'react';
import { IDText, PriorityText, StyledDiv, StyledItemContent, StyledItems } from './PriorityTitle.style';

export interface PriorityItem {
  id: number;
  name: string;
  value: number;
}

const PriorityTitle: React.FC<{ priority: PriorityItem[] }> = ({ priority }) => {
  return (
    <StyledDiv>
      {priority.map((item, index) => (
        <StyledItems key={item.id} color='grey' border='yellow'>
              <StyledItemContent>
                  <PriorityText>Priority</PriorityText>
                  <IDText>{item.id}</IDText>
              </StyledItemContent>
        </StyledItems>
      ))}
    </StyledDiv>
  );
};

export default PriorityTitle;
