import {
  LastUpdated,
  SideOverviewContent,
  SideOverviewHeader,
  StatusLargeBox,
  SmallNumber,
  StatusMidLargeBox,
} from 'pages/dashboard/Dashboard.style';
import { formatDate } from 'utils/date';
import { tabName } from 'components/header/Header.consts';
import React from 'react';
import {
  UpdatesOverviewContainer,
  UpdatesOverviewLabelText,
  UpdatesOverviewSubHeader,
  UpdatesSection,
} from 'pages/dashboard/components/updatesOverview/UpdatesOverview.style';
import { useSelector } from 'react-redux';
import { marketConfig } from 'app/slices/config';
import { UpdatesOverviewProps } from 'pages/dashboard/components/updatesOverview/UpdatesOverview.consts';
import { handleKeyPress } from 'utils/keyPress';

const UpdatesOverview = ({ updates, routeToSpecificPath }: UpdatesOverviewProps) => {
  const { config } = useSelector(marketConfig);
  const { dateFormat, timeFormat, startTimezone } = config;

  return (
    <UpdatesOverviewContainer aria-label='updates' data-automation-id="updates">
      <SideOverviewHeader>Updates</SideOverviewHeader>
      <SideOverviewContent>
        <UpdatesSection data-automation-id="updates-products">
          <UpdatesOverviewSubHeader>Products</UpdatesOverviewSubHeader>
          <StatusLargeBox
            color="grey"
            tabIndex={0}
            borderColor="yellow"
            onClick={() => routeToSpecificPath(tabName.ProductChanges)}
            onKeyDown={(event) => handleKeyPress(event, () => routeToSpecificPath(tabName.ProductChanges))}
            role="link"
          >
            <div>
              <SmallNumber>{updates.products.productsUpdated}</SmallNumber>
              <UpdatesOverviewLabelText>Product Updates</UpdatesOverviewLabelText>
            </div>
            <div>
              <SmallNumber>{updates.products.campaignsImpacted}</SmallNumber>
              <UpdatesOverviewLabelText>Campaigns Impacted</UpdatesOverviewLabelText>
            </div>
            <div>
              <SmallNumber>{updates.products.offersImpacted}</SmallNumber>
              <UpdatesOverviewLabelText>Offers Impacted</UpdatesOverviewLabelText>
            </div>
          </StatusLargeBox>
          <LastUpdated>
            Last Update:
            {updates.products.lastUpdated &&
              ` ${formatDate(updates.products.lastUpdated, { dateFormat, timeFormat }, startTimezone)}`}
          </LastUpdated>
        </UpdatesSection>
        <UpdatesSection data-automation-id="updates-locations">
          <UpdatesOverviewSubHeader>Locations</UpdatesOverviewSubHeader>
          <StatusMidLargeBox
            color="grey"
            tabIndex={0}
            borderColor="yellow"
            onClick={() => routeToSpecificPath(tabName.LocationChanges)}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
              handleKeyPress(event, () => routeToSpecificPath(tabName.LocationChanges))
            }
            role="link"
          >
            <div>
              <SmallNumber>{updates.locations.locationsUpdated}</SmallNumber>
              <UpdatesOverviewLabelText>Location Updates</UpdatesOverviewLabelText>
            </div>
            <div>
              <SmallNumber>{updates.locations.campaignsImpacted}</SmallNumber>
              <UpdatesOverviewLabelText>Campaigns Impacted</UpdatesOverviewLabelText>
            </div>
          </StatusMidLargeBox>
          <LastUpdated>
            Last Update:
            {updates.locations.lastUpdated &&
              ` ${formatDate(updates.locations.lastUpdated, { dateFormat, timeFormat }, startTimezone)}`}
          </LastUpdated>
        </UpdatesSection>
      </SideOverviewContent>
    </UpdatesOverviewContainer>
  );
};
export default UpdatesOverview;
