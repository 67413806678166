import styled from '@emotion/styled';
import { NewButtonText } from 'components/shared/button';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { LoginContainerBody as _LoginContainerBody } from '../../Login.style';

export const LoginContainerBody = styled(_LoginContainerBody)``;
export const PasswordSection = styled.div`
  display: flex;
  flex-direction: column;
  button {
    justify-content: flex-end;
    font-size: 16px;
    font-weight: 400;
    color: ${newGlobalTheme.colors.text.secondary};
  }
`;

export const StyledNewButtonText = styled(NewButtonText)`
  margin-top: 4px;
`;

export const RightTextButtonWrapper = styled.div`
  display: flex; 
  flex-direction: row; 
  justify-content: flex-end; 
`
