import styled from '@emotion/styled/macro';
import { newTypography } from 'styles/typography';
import { Icon } from 'components/shared/icon';
import { StyledWithThemeProps } from 'utils/types';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import zIndex from 'styles/zIndex';

export const HelpMenuContainer = styled.div`
  display: flex;
  padding-top: 8px;
  margin-right: ${newTypography.unit * 3}px;
`;

export const HelpIcon = styled(Icon)`
  width: 22px;
  fill: ${({ theme }) => newGlobalTheme.colors.text.primary};
  cursor: pointer;
`;

export const HelpMenuDropdown = styled.div<StyledWithThemeProps>`
  position: absolute;
  top: calc(100% + ${newTypography.unit * 2}px);
  right: ${newTypography.unit * 19}px;
  width: 200px;
  height: 90px;
  border: 1px solid ${({ theme }) => newGlobalTheme.colors.global.border};
  border-radius: ${newTypography.borderRadius / 2}px;
  box-shadow: ${({ theme }) => newGlobalTheme.colors.global.boxShadow};
  background: ${({ theme }) => newGlobalTheme.colors.global.background};
  z-index: ${zIndex.dropdown};
`;

export const HelpMenuItem = styled.div<StyledWithThemeProps>`
  display: flex;
  height: 45px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  a {
    color: ${({ theme }) => newGlobalTheme.colors.text.primary};
    width: 100%;
  }
`;

export const Divider = styled.div<StyledWithThemeProps>`
  border-bottom: 1px solid ${({ theme }) => newGlobalTheme.colors.global.border};
`;

export const StyledIcon = styled(Icon)`
  width: 14px;
  fill: ${({ theme }) => newGlobalTheme.colors.text.secondary};
  margin: 0 ${newTypography.unit * 4}px;
`;

export const StyledLink = styled.a`
&:focus-visible {
  outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
  outline-offset: 0px;
  z-index: ${zIndex.tabNavigation};
  transition: none;
}
`;
