import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';
 export const StyledTotalAmount = styled.div`
   font-weight: 400;
   font-size:12px;
   font-family: ${newTypography.primaryFont};
   font-style: italic;
   color: ${newGlobalTheme.colors.global.primaryTextColor};
 `;
