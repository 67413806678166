import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import newTypography from 'styles/typography';
import { StyledWithNewThemeProps } from 'utils/types';

export const QuickFilterContainer = styled.div<StyledWithNewThemeProps>`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 ${newTypography.unit * 4}px;
  transition: background-color ${newTypography.transition}s ease;
  color: ${newGlobalTheme.colors.global.primaryTextColor};

  &:hover {
    background-color: ${({ theme }) => theme.colors.tabStrip.backgroundHover};
    font-weight: 600;
  }
`;

export const Number = styled.span<{ isError: boolean; }>`
  font-size: 28px;
  color: ${({ isError }) => isError && newGlobalTheme.colors.global.error};
  margin-right: ${newTypography.unit * 2}px;
  font-weight: 700;
`;
