import styled from '@emotion/styled';
import typography from 'styles/typography';
import TextField from 'components/shared/textField/TextField';
import { StyledWithThemeProps } from 'utils/types';
import NewModal from 'components/shared/modal/NewModal';

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${typography.unit * 4}px;
  align-items: baseline;
`;

export const EntityCheckboxFormRow = styled(FormRow)`
  margin-bottom: ${typography.unit * 3}px;
`;

export const TagName = styled(TextField)`
  width: 295px;
  margin-top: ${typography.unit * 5}px;
`;

export const SectionTitle = styled.div<StyledWithThemeProps>`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: ${typography.unit * 2}px;
  margin-top: ${typography.unit * 6}px;

  &::after {
    content: '*';
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
`;

export const StyledModal = styled(NewModal)`
  height: 450px;
  width: 433px;
  min-width: 433px;

  & > header {
    padding-bottom: ${typography.unit * 5}px;

    h3 {
      margin-bottom: ${typography.unit}px;
    }
  }
`;
