import React from 'react';
import { Global, css, useTheme } from '@emotion/react';
import zIndex from 'styles/zIndex';
import typography, { newTypography } from './typography';
import { appTheme } from './themes/defaultTheme';

function GlobalStyle() {
  const theme = useTheme() as appTheme;
  return (
    <Global
      styles={css`
        html {
          width: 100%;
          height: 100%;
        }

        body {
          margin: 0;
          padding: 0;
          font-family: ${newTypography.primaryFont};
          width: 100%;
          height: 100%;
          background-color: ${theme.colors.global.background};
          color: ${theme.colors.text.primary};
          overflow: hidden;
          font-size: 14px;

          [data-grammarly-shadow-root='true'] {
            mix-blend-mode: normal !important;
          }
        }

        ::-webkit-scrollbar {
          background: transparent;
          width: 7px;
          height: 7px;
        }

        ::-webkit-scrollbar-thumb {
          background: #dddee1;
          border-radius: 4px;
        }

        * {
          box-sizing: border-box;
        }

        a {
          text-decoration: none;

          &:focus {
            outline: none;
          }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0;
        }

        p {
          margin: 0;
        }

        #root {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        [data-title]:hover:after {
          opacity: 1;
          transition: all 0.1s ease 0.5s;
          visibility: visible;
        }
        [data-title]:after {
          content: attr(data-title);
          background-color: ${theme.colors.global.background};
          color: ${theme.colors.text.primary};
          font-size: 13px;
          position: absolute;
          padding: 1px 5px 2px 5px;
          bottom: -${typography.unit * 7}px;
          white-space: nowrap;
          box-shadow: ${theme.colors.global.boxShadow};
          opacity: 0;
          border: 1px solid ${theme.colors.global.border};
          border-radius: ${typography.borderRadius}px;
          z-index: ${zIndex.tooltip};
          visibility: hidden;
        }
        [data-title] {
          position: relative;
        }

        /*TODO: Clear all irrelevant font weight*/
          
        @font-face {
          font-family: 'Speedee';
          src: local('Speedee'), url(fonts/Speedee/Speedee-Light.ttf) format('truetype');
          font-weight: 300;
        }

        @font-face {
          font-family: 'Speedee';
          src: local('Speedee'), url(fonts/Speedee/Speedee-Regular.ttf) format('truetype');
          font-weight: 400;
        }

        @font-face {
          font-family: 'Speedee';
          src: local('Speedee'), url(fonts/Speedee/Speedee-Bold.ttf) format('truetype');
          font-weight: 700;
        }

        @font-face {
          font-family: 'Speedee';
          src: local('Speedee'), url(fonts/Speedee/Speedee-Black.ttf) format('truetype');
          font-weight: 900;
        }

        @font-face {
          font-family: 'Proxima Nova';
          src: local('Proxima Nova'), url(fonts/ProximaNova/ProximaNova-Light.ttf) format('truetype');
          font-weight: 300;
        }

        @font-face {
          font-family: 'Proxima Nova';
          src: local('Proxima Nova'), url(fonts/ProximaNova/ProximaNova-Regular.ttf) format('truetype');
          font-weight: 400;
        }

        @font-face {
          font-family: 'Proxima Nova';
          src: local('Proxima Nova'), url(fonts/ProximaNova/ProximaNova-Semibold.ttf) format('truetype');
          font-weight: 500;
        }

        @font-face {
          font-family: 'Proxima Nova';
          src: local('Proxima Nova'), url(fonts/ProximaNova/ProximaNova-Bold.ttf) format('truetype');
          font-weight: 600;
        }

        @font-face {
          font-family: 'Proxima Nova';
          src: local('Proxima Nova'), url(fonts/ProximaNova/ProximaNova-Extrabld.ttf) format('truetype');
          font-weight: 700;
        }

        @font-face {
          font-family: 'Montserrat';
          src: local('Montserrat'), url(fonts/Montserrat/Montserrat-Light.ttf) format('truetype');
          font-weight: 300;
        }

        @font-face {
          font-family: 'Montserrat';
          src: local('Montserrat'), url(fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
          font-weight: 400;
        }

        @font-face {
          font-family: 'Montserrat';
          src: local('Montserrat'), url(fonts/Montserrat/Montserrat-Semibold.ttf) format('truetype');
          font-weight: 500;
        }

        @font-face {
          font-family: 'Montserrat';
          src: local('Montserrat'), url(fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
          font-weight: 600;
        }

        @font-face {
          font-family: 'Montserrat';
          src: local('Montserrat'), url(fonts/Montserrat/Montserrat-Extrabld.ttf) format('truetype');
          font-weight: 700;
        }
      `}
    />
  );
}

export default GlobalStyle;
