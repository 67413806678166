import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  SectionContainer,
  StyledToggleSwitch,
  StyledSelectbox,
  StyledContainer,
  StyledCheckboxMakeNational,
} from 'pages/campaigns/campaignManagement/components/campaignForm/components/localCampaignSection/LocalCampaignSection.style';
import { isInArray } from 'utils/array';
import { FormMode, GenericEntity, OrderDirection } from 'utils/types';
import { LocationSet } from 'utils/types/locations';
import locationSetsGqls from 'pages/settings/locationSets/LocationSets.gqls';
import { LocationSetsFilters } from 'pages/settings/locationSets/LocationSets.consts';
import { useQuery } from '@apollo/client';
import { LocalCampaignSectionProps } from 'pages/campaigns/campaignManagement/components/campaignForm/components/localCampaignSection/LocalCampaignSection.consts';
import { FetchPolicies, ValidationMessages } from 'utils/types/common';
import { set } from 'lodash';
import { store } from 'app/store';
import { setData } from 'app/slices/modals';


const LocalCampaignSection = ({ mode, isDisabled, className }: LocalCampaignSectionProps) => {
  const { getValues, control, setValue, watch } = useFormContext();
  const campaign = getValues();

  const localScheduleZone = 'localSchedule.zone';

  const [isLocalCampaign, zone, isNational] = watch(['isLocalCampaign', localScheduleZone, 'isNational']);

  const { data: zonesData } = useQuery<{ getLocationSets: GenericEntity<LocationSet> }>(
    locationSetsGqls.queries.getZones,
    {
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      skip: !isLocalCampaign || mode === FormMode.View,
      variables: {
        data: {
          filters: { [LocationSetsFilters.CustomSets]: false, [LocationSetsFilters.HideZeroLocations]: true },
          order: { name: OrderDirection.ASC },
        },
      },
    },
  );

  const shouldShowSection = isInArray([FormMode.New, FormMode.Duplicate], mode) || isLocalCampaign;

  const resetOnIsLocalChange = () => {
    setValue('isNational', false);
    setValue('type', null);
    set(control._defaultValues, localScheduleZone, null);
    resetOnIsNationalChange();
  };

  const resetOnIsNationalChange = () => {
    setValue('offerVersion', null);
    setValue(localScheduleZone, null);
    setValue('localSchedule.period', null);
    setValue('restaurantEligibility.restaurants', null);
    setValue('restaurantEligibility.restaurantGroups', null);
    setValue('restaurantEligibility.excludeRestaurants', null);
    setValue('restaurantEligibility.excludeRestaurantGroups', null);
    setValue('restaurantEligibility.isNationwide', false);
  };

  useEffect(() => {
    setValue('isLocalCampaign', campaign?.isLocalCampaign || Boolean(campaign?.localSchedule?.period));
    setValue(
      'isNational',
      campaign?.isNational || (Boolean(campaign?.localSchedule?.period) && !Boolean(campaign?.localSchedule?.zone)),
    );
  }, []);
  const shouldRenderZoneContent =
    isLocalCampaign && (zonesData?.getLocationSets?.items || mode === FormMode.View);

  const zoneItems = zonesData?.getLocationSets?.items;
  const zoneValidation = isLocalCampaign && !isNational
    ? { required: ValidationMessages.RequiredField }
    : {};
   return (
    <>
      {shouldShowSection && (
        <SectionContainer className={className} data-automation-id="local-campaign-section">
          <StyledToggleSwitch
            control={control}
            name="isLocalCampaign"
            size="xlarge"
            disabled={isInArray([FormMode.View, FormMode.Edit], mode)}
            label="Bulk Campaign"
            onChange={resetOnIsLocalChange}
            
          />
         

           {shouldRenderZoneContent && (
             <StyledContainer>
               <StyledSelectbox
                 control={control}
                 labelIsHorizontal={false}
                 placeholder={zone ? zone.name : 'Select'}
                 name="localSchedule.zone"
                 label="Zone"
                 items={zoneItems}
                 disabled={isDisabled || isNational}
                 clearOnDisabled={!isDisabled}
                 validation={zoneValidation}
                 withSearch
                 initialSelectedItems={zone ? [typeof zone === 'object' ? zone.id : zone] : []}
                 onChange={() => {
                   setValue('offerVersion', null);
                   setValue('localSchedule.period', null);
                 }}
                 selectWidth={216}
                 version="campaign-form"
                 containerGap={8}
               />
               <StyledCheckboxMakeNational
                 checked={isNational}
                 onClick={() => {
                   resetOnIsNationalChange();
                   setValue('isNational', !isNational);
                   // update modal state isFormDirty
                   store.dispatch(
                    setData({
                      data: {
                        isFormDirty: true,
                      },
                    }),
                  );
                 }}
                 label="Make National"
                 disabled={isDisabled}
               />
             </StyledContainer>
           )}

        </SectionContainer>
      )}
    </>
  );
};

export default LocalCampaignSection;
