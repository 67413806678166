import React, { useEffect, useState } from 'react';
import { NewTextPageTitle } from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName } from 'components/header/Header.consts';
import { useSelector } from 'react-redux';
import { store } from 'app/store';
import { Modals, openModal } from 'app/slices/modals';
import { FormMode, OrderDirection, ProductSetType } from 'utils/types';
import { setFilters, products as productsSlice, setOrder, getProductSetsFilters } from 'app/slices/products';
import { useUrlFilters } from 'hooks/use-url-filters';
import { ActionType } from 'components/shared/actionsCell/ActionsCell.consts';
import { ProductSet } from 'utils/types/products';
import Table from 'components/shared/table/Table';
import { hideActionsCell, showActionsCell } from 'components/shared/table/Table.util';
import { getProductSetsTableProps } from 'pages/settings/productSets/ProductSetsTableProps';
import { GridSortModel } from '@mui/x-data-grid-pro';
import isEqual from 'lodash/isEqual';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import { Actions, TableContainer, StyledHeader, StyledTotalAmountWithTable } from 'pages/shared/shared.style';
import PageContainer from 'pages/shared/pageContainer/PageContainer';
import AddNewButton from 'pages/shared/addNewButton/AddNewButton';
import { ProductSetsFilters } from 'pages/settings/productSets/ProductSets.consts';
import { useQuery } from '@apollo/client';
import { usePersistCaretPosition } from 'hooks/use-persist-caret-position';
import useQueryInterval from 'hooks/use-query-polling';
import { mapOrder } from 'utils/mapping';
import productSetsGqls from './ProductSets.gqls';
import { FetchPolicies } from 'utils/types/common';
import { MessageType } from 'components/shared/notifications/notifications';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';

const ProductSets = () => {
  const { order, filters } = useSelector(productsSlice);
  const [gridSortModel, setGridSortModel] = useState<GridSortModel>(mapOrder(order));
  const { data, loading, error, fetchMore, startPolling, stopPolling } = useQuery(productSetsGqls.queries.getProductSetsProductSetsPage, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: getProductSetsFilters(filters),
        order,
        limit: 25,
      },
    },
  });

  useEffect(() => {
    if (error) {
      showToast(MessageType.Error, "Error loading product sets");
    }
  }, [error]);

  const {
    getProductSetsProductSetsPage: { items: productSets, total },
  } = data || { getProductSetsProductSetsPage: { items: [] } };
  useQueryInterval(600000, startPolling, stopPolling);

  const urlFilters = useUrlFilters((params: any) => {
    store.dispatch(
      setFilters(
        Object.keys(params).reduce(
          (res: any, key: any) => ({ ...res, [key]: Array.isArray(params[key]) ? params[key] : [params[key]] }),
          {},
        ),
      ),
    );
  });

  const [caretPosition, setCaretPosition] = usePersistCaretPosition(filters[ProductSetsFilters.SearchQuery]);

  const openCreateProductSetModal = () => {
    store.dispatch(
      openModal({
        modal: Modals.ProductSetModal,
        data: {},
        props: { mode: FormMode.New },
      }),
    );
  };

  const onSortModelChange = (sortModel: { [key: string]: OrderDirection }, gridSortModal: GridSortModel) => {
    if (!isEqual(sortModel, order)) {
      store.dispatch(setOrder(sortModel));
      setGridSortModel(gridSortModal);
    }
  };

  const onActionClick = (actionType: ActionType, set: ProductSet) => {
    store.dispatch(
      openModal({
        modal: Modals.ProductSetModal,
        props: {
          mode: actionType,
          productSet: set,
          ...(set.type.toLowerCase() === ProductSetType.NonFoodProduct && { isNonProduct: true }),
        },
      }),
    );
  };

  const fetchNextProductSets = async () => {
    if (productSets.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: getProductSetsFilters(filters),
            order,
            limit: 25,
            offset: productSets.length,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (!Object.keys(urlFilters.params).length) {
      urlFilters.filterMulti(filters);
    }
  }, []);

  return (
    <PageContainer>
      <StyledHeader data-automation-id="header">
        <NewTextPageTitle text={tabName.ProductSets} />
        <Actions data-automation-id="actions">
          <SearchTextField
            key={`${JSON.stringify(filters[ProductSetsFilters.SearchQuery])}_SearchQuery`}
            name="product-sets-search"
            caretPosition={caretPosition}
            value={filters[ProductSetsFilters.SearchQuery] ?? ''}
            onChange={(e) => {
              urlFilters.filter('searchQuery', e.target.value);
              setCaretPosition(e);
            }}
          />
          <AddNewButton onClick={openCreateProductSetModal} />
        </Actions>
      </StyledHeader>
      <TableContainer>
        <StyledTotalAmountWithTable amount={total} />
        <Table
          tableProps={{ ...getProductSetsTableProps(onActionClick), rows: productSets, loading }}
          onRowOver={(e: React.SyntheticEvent<HTMLDivElement>) => showActionsCell(e.currentTarget.dataset.id)}
          onRowOut={(e: React.SyntheticEvent<HTMLDivElement>) => hideActionsCell(e.currentTarget.dataset.id)}
          onRowsScrollEnd={fetchNextProductSets}
          getRowId={(row) => row.id}
          onSortModelChange={onSortModelChange}
          gridSortModel={gridSortModel}
        />
      </TableContainer>
    </PageContainer>
  );
};

export default ProductSets;
