import { useLazyQuery } from '@apollo/client';
import { showToast } from 'components/shared/notifications/toastContainerWrapper/ToastContainerWrapper';
import { MessageType } from 'components/shared/notifications/notifications';
import { offerBanksGqls } from 'pages/campaigns/doeCampaigns/offer.bank.gqls';
import { useEffect } from 'react';
import { OrderDirection } from 'utils/types';
import { FetchPolicies } from 'utils/types/common';

export const useOfferBanksQuery = (showErrorToast: boolean) => {
  const [load, { error }] = useLazyQuery(offerBanksGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        order: { name: OrderDirection.ASC },
        offset: 0,
        limit: 50,
      },
    },
  });

  useEffect(() => {
    if (error && showErrorToast) {
      showToast(MessageType.Error, 'Error loading offer banks');
    }
  }, [error, showErrorToast]);

  const loadOptions = async (search: string = '', prevOptions: any) => {
    const offset = prevOptions ? Math.floor(prevOptions.length / 50) : 0;
    const { data } = await load({
      fetchPolicy: FetchPolicies.CacheAndNetwork,
      nextFetchPolicy: FetchPolicies.CacheAndNetwork,
      notifyOnNetworkStatusChange: true,
      variables: {
        data: {
          filters: { offerBankId: search },
          order: { name: OrderDirection.ASC },
          offset: offset,
          limit: 50,
        },
      },
    });
    const items = data?.getOfferBanks?.items || [];
    const total = data?.getOfferBanks?.total || 0;
    const hasMore = items.length > 0 && prevOptions.length + items.length < total;
    return {
      options: items.map((rec: any) => ({
        id: rec.offerBankId,
        name: rec.offerBankId,
      })),
      hasMore: hasMore,
    };
  };
  return { loadOptions };
};
