import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DragableStyledItem } from './components/box/DragableItem';
import { ItemName, StyledDragAndDrop } from './DragAndDrop.style';
import { CSS } from '@dnd-kit/utilities';
import { Icon } from '../icon/Icon';
interface Item {
  id: number;
  name: string;
  priorityId: number;
}

export type Color = 'green' | 'red' | 'yellow' | 'blue' | 'grey';
export type borderColor = 'yellow' | 'grey' | 'darkGrey'
const DragAndDropList: React.FC<{ items: Item[]; disabled: boolean; color: Color, border: borderColor }> = ({ items, disabled, color, border }) => {
  return (
    <StyledDragAndDrop data-automation-id={`drag-and-drop`}>
      <SortableContext items={items.map((item, index) => item.id)} strategy={verticalListSortingStrategy}>
        {items.map((item, index) => (
          <DraggableItem key={item.id} item={item} ariaLabel={`priority ${index + 1} ${item.name}`} index={index} disabled={disabled} color={color} border={border}/>
        ))}
      </SortableContext>
    </StyledDragAndDrop>
  );
};

const DraggableItem: React.FC<{ item: Item; index: number; disabled: boolean; color: Color, border: borderColor, ariaLabel?: string }> = ({
  item,
  index,
  disabled,
  color,
  border,
  ariaLabel
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item.id, disabled: disabled });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  return (
    <DragableStyledItem
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      color={color}
      border={border}
      aria-label={ariaLabel}
      disabled={disabled}
      data-automation-id={`drag-${item.name}`}
    >
      <ItemName>
        {!disabled && <Icon name ='toggle' width={20} height={13}/>}
        {item.name}
      </ItemName>
    </DragableStyledItem>
  );
};

export default DragAndDropList;
