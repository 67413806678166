import { OfferSource } from 'pages/offers/offerManagement/Offers.const';
import { ApprovalStatus, DateTimeConfig, FormMode } from 'utils/types';
import { VoucherState } from '../../CampaignForm.consts';

export interface ScheduleSectionProps {
  mode: FormMode;
  status: ApprovalStatus;
  isDisabled: boolean;
  dateTimeConfig: DateTimeConfig;
  className?: string;
  theme?: string;
  offerSource?: OfferSource;
  voucherState?: VoucherState;
}

export const validityDays = [
  { id: 'sunday', name: 'SU' },
  { id: 'monday', name: 'MO' },
  { id: 'tuesday', name: 'TU' },
  { id: 'wednesday', name: 'WE' },
  { id: 'thursday', name: 'TH' },
  { id: 'friday', name: 'FR' },
  { id: 'saturday', name: 'SA' },
];

export interface DateToShown {
  startDate?: string | Date;
  endDate?: string | Date;
}
