import React, { useRef, useState } from 'react';
import useOnClickOutside from 'hooks/use-onclick-outside';
import {
  ActionsContainer,
  HasMore,
  HasMoreContainer,
  NewStyledButtonText,
  StyledDropdown,
  StyledIcon,
  StyledListButtonText,
  StyledListIcon,
} from './ActionsCell.style';
import { ActionsCellProps, ActionTypeToIconName, ActionTypeToIconWidth, ActionTypeToLabel } from './ActionsCell.consts';

const ActionsCell = ({ actionTypes, onActionClick, className, rowId }: ActionsCellProps) => {
  const actionsToShow = actionTypes.slice(0, 3);
  const moreActions = actionTypes.length > 3 && actionTypes.slice(3);
  const [showMoreActions, setShowMoreActions] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setShowMoreActions(false));

  return (
    <ActionsContainer className={className}>
      {actionsToShow.map((action) => (
        <NewStyledButtonText
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onActionClick(action);
          }}
          key={action}
          data-title={ActionTypeToLabel[action]}
          onFocus={() => {
            const cell = document.getElementById(`${rowId}`);
            if (cell) {
              cell.style.opacity = '1'; // Make the cell visible
              cell.style.pointerEvents = 'auto'; // Allow interactions with the cell
              cell.style.position = 'static'; // Restore its original position
            }
          }}
          onBlur={() => {
            const cell = document.getElementById(`${rowId}`);
            if (cell) {
              cell.style.opacity = '0'; // Make the cell invisible
              cell.style.pointerEvents = 'none'; // Prevent any interaction with the cell
              cell.style.position = 'absolute'; // Remove it from the normal document flow
            }
          }}
        >
          <StyledIcon name={ActionTypeToIconName[action]} width={ActionTypeToIconWidth[action]}/>
        </NewStyledButtonText>
      ))}
      {moreActions && (
        <HasMoreContainer>
          <HasMore onClick={() => setShowMoreActions(!showMoreActions)}>
            <StyledIcon name="more" width={6} />
          </HasMore>
          {showMoreActions && (
            <StyledDropdown ref={ref}>
              {moreActions.map((action) => (
                <StyledListButtonText
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onActionClick(action);
                    setShowMoreActions(false);
                  }}
                >
                  <StyledListIcon name={ActionTypeToIconName[action]} width={ActionTypeToIconWidth[action]} />
                  {ActionTypeToLabel[action]}
                </StyledListButtonText>
              ))}
            </StyledDropdown>
          )}
        </HasMoreContainer>
      )}
    </ActionsContainer>
  );
};

export default ActionsCell;
