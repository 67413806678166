import React, { useState } from 'react';
import { NewTextPageTitle } from 'components/shared/text/textPageTitle/TextPageTitle';
import { tabName } from 'components/header/Header.consts';
import { store } from 'app/store';
import { formatDate } from 'utils/date';
import { LocationChangesFilterBar } from 'pages/reports/locationChanges/components/locationChangesFilterBar/LocationChangesFilterBar';
import { getFilters, locationChanges as locationChangesSlice, setOrder } from 'app/slices/locationChanges';
import Table from 'components/shared/table/Table';
import { getLocationChangesTableProps } from 'pages/reports/locationChanges/LocationChangesTableProps';
import { closeModal, Modals, openModal } from 'app/slices/modals';
import { DateTimeConfig, OrderDirection } from 'utils/types';
import { marketConfig } from 'app/slices/config';
import { useSelector } from 'react-redux';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { Actions, TableContainer, StyledHeader, StyledTotalAmountWithTable } from 'pages/shared/shared.style';
import isEqual from 'lodash/isEqual';
import DownloadCsvButton from 'pages/shared/downloadCsvButton/DownloadCsvButton';
import { useToastError } from 'hooks/use-toast-error';
import { useQuery } from '@apollo/client';
import { mapOrder } from 'utils/mapping';
import useQueryInterval from 'hooks/use-query-polling';
import PageContainer from '../../shared/pageContainer/PageContainer';
import locationChangesGqls from './LocationChanges.gqls';
import { AuditChange } from '../shared/shared.consts';
import { campaignImpact as campaignImpactSlice } from 'app/genericSlices/impacts';
import { client } from 'app/apollo';
import { LoaderWrapper } from 'pages/campaigns/calendar/components/calendarView/CalendarView.style';
import { Loader } from 'components/shared/loader';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { FetchPolicies } from 'utils/types/common';

const LocationChanges = () => {
  const { config } = useSelector(marketConfig);
  const { filters, order } = useSelector(locationChangesSlice);
  const [isLoading, setIsLoading] = useState(false);
  const { data, error, fetchMore, loading, startPolling, stopPolling } = useQuery(locationChangesGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: getFilters(filters),
        order,
        limit: 25,
        queryKey: 'query1',
      },
    },
  });
  const {
    getAudit: { items: changes, total },
  } = data || { getAudit: { items: [] } };
  useToastError(error, 'Error loading location changes');
  useQueryInterval(600000, startPolling, stopPolling);

  const { dateFormat, timeFormat, startTimezone } = config;
  const dateTimeConfig: DateTimeConfig = { dateFormat, timeFormat, startTimezone };
  const [gridSortModel, setGridSortModel] = useState<GridSortModel>(mapOrder(order));

  const createRows = async () => {
    const { data: data2 } = await client.query({
      query: locationChangesGqls.queries.getAllLocationChanges,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          filters: getFilters(filters),
          order,
          limit: null,
          queryKey: 'query2',
        },
      },
    });
    const {
      getAudit: { items: newChanges },
    } = data2 || { getAudit: { items: [] } };
    const rows = [['Location Name', 'ID', 'Change Type', 'Time']];
    await newChanges?.forEach(
      (row: { entityName: string; entityId: number; headline: string; createdAt: string | Date }) =>
        rows.push([
          row.entityName,
          `"${row.entityId}"`,
          `"${row.headline}"`,
          `"${formatDate(row.createdAt, dateTimeConfig, dateTimeConfig.startTimezone)}"`,
        ]),
    );
    return rows;
  };

  const onUsageClick = async (row: AuditChange) => {
    const campaignIds: any = row?.changes?.usages?.campaigns?.length ? row.changes.usages.campaigns : [];
    store.dispatch(
      openModal({
        modal: Modals.AuditImpactModal,
        props: {
          title: 'Location Impact',
          campaignIds,
          entityId: row.entityId,
          onCancel: () => {
            store.dispatch(campaignImpactSlice.actions.resetFilters());
            store.dispatch(closeModal());
          },
        },
      }),
    );
  };

  const fetchNextChanges = async () => {
    if (changes.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: getFilters(filters),
            offset: changes.length,
            limit: 25,
            order,
            queryKey: 'query1',
          },
        },
      });
    }
  };

  const onSortModelChange = (sortModel: { [key: string]: OrderDirection }, gridSortModal: GridSortModel) => {
    if (!isEqual(sortModel, order)) {
      store.dispatch(setOrder(sortModel));
      setGridSortModel(gridSortModal);
    }
  };

  const updateLoadingState = (isLoadingValue: boolean) => {
    setIsLoading(isLoadingValue);
  };

  return (
    <PageContainer>
      <StyledHeader data-automation-id="header">
        <NewTextPageTitle text={tabName.LocationChanges} />
        <Actions data-automation-id="actions">
          <LocationChangesFilterBar />
          <DownloadCsvButton
            createRows={createRows}
            updateLoadingState={updateLoadingState}
            fileName="LocationChanges"
          />
        </Actions>
      </StyledHeader>
      <TableContainer>
        {isLoading && (
          <LoaderWrapper>
            <Loader size={LoaderSize.Large} />
          </LoaderWrapper>
        )}
        <StyledTotalAmountWithTable amount={total} />
        <Table
          tableProps={{ ...getLocationChangesTableProps(onUsageClick, dateTimeConfig), rows: changes, loading }}
          onRowsScrollEnd={fetchNextChanges}
          getRowId={(row) => row.id}
          onSortModelChange={onSortModelChange}
          gridSortModel={gridSortModel}
        />
      </TableContainer>
    </PageContainer>
  );
};

export default LocationChanges;
