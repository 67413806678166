import styled from '@emotion/styled';
import typography from 'styles/typography';

export const StyledTitle = styled.h3`
  font-size: 16px;
  font-weight: 400;
  font-family: ${typography.secondaryFont};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
`;
