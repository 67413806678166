export const handleKeyPress = <T extends HTMLElement>(event: React.KeyboardEvent<T>, callback: any) => {
  if (event.key === 'Enter' || event.key === ' ' ||  event.keyCode === 13) {
    event.preventDefault();
    callback();
  }
};

export const handleKeyPressDiv = (event: React.KeyboardEvent<HTMLDivElement>, callback: any) => {
  handleKeyPress(event, callback);
};

export const handleKeyPressLi = (event: React.KeyboardEvent<HTMLLIElement>, callback: any) => {
  handleKeyPress(event, callback);
};
