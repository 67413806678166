import { useForm, useFormContext } from 'react-hook-form';
import { TemplateProps } from '../Template.consts';
import {
  StyledDiv,
  StyledLabel,
  StyledText,
  StyledVoucherGroupBoxColumn,
  StyledVoucherGroupBoxRow,
  StyledVoucherGroupDetailsBox,
  StyledVoucherGroupSelectBox,
} from '../Template.style';
import { useQuery } from '@apollo/client';
import { VoucherGroup } from 'utils/types/voucherGroups';
import { FormMode, OrderDirection } from 'utils/types';
import offersGqls from 'pages/offers/offerManagement/Offers.gqls';
import { FetchPolicies } from 'utils/types/common';
import { useEffect, useState } from 'react';
import ProgressLoader from 'pages/shared/progressIndicator/ProgressLoader';

export const NonFoodDigitalRewards = ({ disabled, modalMode, setVoucherDetails }: TemplateProps) => {
  const {
    control,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();
  const [voucherGroup] = watch(['versions.0.templateValues.nonFoodDigitalRewards']);
  const formMethods = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
  const [retryAttempt, setRetryAttempt] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchFailed, setIsFetchFailed] = useState(false);
  const voucherValue = 'versions.0.templateValues.nonFoodDiscountRewards';
  const [selectedVoucherGroup, setSelectedVoucherGroup] = useState(formMethods.getValues(voucherValue));
  const [intialVoucherValue] = useState(getValues(voucherValue));
  const {
    data: voucherGroupsData,
    loading,
    error,
    refetch,
  } = useQuery<{ getAllVoucherGroups: [VoucherGroup] }>(offersGqls.queries.getAllVoucherGroups, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: {},
        order: { name: OrderDirection.ASC },
        limit: 20000,
      },
    },
  });

  const getLocaleDateString = (date: Date) => {
    // return new Date(date).toLocaleDateString('en-US')
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const items =
    voucherGroupsData?.getAllVoucherGroups?.map((item: VoucherGroup) => ({
      id: item.voucherGroupId,
      name: item.voucherGroupName,
      startDate: getLocaleDateString(new Date(item.startAtUtc)),
      endDate: getLocaleDateString(new Date(item.endAtUtc)),
    })) || [];

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (retryAttempt === 0 && !isFetchFailed && error) {
      refetch();
      setRetryAttempt(1);
    } else if (retryAttempt === 1 && error) {
      setIsFetchFailed(true);
    }
  }, [retryAttempt, isFetchFailed, error, refetch]);

  useEffect(() => {
    if (!selectedVoucherGroup && items.length > 0) {
      const watchedVoucherGroup = getValues(voucherValue);
      if (watchedVoucherGroup) {
        setSelectedVoucherGroup(items.find((item) => item.id === watchedVoucherGroup));
      }
    }
  }, [items, getValues, selectedVoucherGroup]);

  useEffect(() => {
    if (selectedVoucherGroup) {
      setVoucherDetails({
        name: selectedVoucherGroup.name,
        startDate: selectedVoucherGroup.startDate,
        endDate: selectedVoucherGroup.endDate,
      });
    }
  }, [selectedVoucherGroup, setVoucherDetails]);

  return (
    <>
      <div>
        <StyledVoucherGroupBoxColumn>
          <StyledVoucherGroupBoxRow>
            <StyledLabel>Voucher Group</StyledLabel>
            <StyledVoucherGroupSelectBox
              key={`voucherGroups_${items.length}`}
              name="versions.0.templateValues.nonFoodDiscountRewards"
              control={control}
              disabled={disabled || (intialVoucherValue && modalMode === FormMode.Edit)}
              validation={{ required: true }}
              items={items}
              placeholder="Select Voucher Group"
              selectWidth={300}
              errors={(() => {
                if (!isLoading && (items.length === 0 || (isFetchFailed && error))) {
                  return 'No voucher group available for selection';
                }
                if (selectedVoucherGroup && new Date(selectedVoucherGroup.endDate) < new Date()) {
                  return 'Selected voucher group is expired';
                }
                return errors;
              })()}
              defaultValue={voucherGroup}
              initialSelectedItems={voucherGroup ? [voucherGroup] : undefined}
              onChange={(selectedVoucherGroupFromList: any) => {
                setSelectedVoucherGroup(selectedVoucherGroupFromList);
              }}
            ></StyledVoucherGroupSelectBox>
            {isLoading && <ProgressLoader size={24} toolTipRequired={false} type="loader" thickness={4} />}
          </StyledVoucherGroupBoxRow>
          {selectedVoucherGroup && (
            <StyledVoucherGroupDetailsBox className="voucher-group-details-container">
              <StyledVoucherGroupBoxRow>
                <StyledLabel>Voucher Name: </StyledLabel>
                <StyledText>{selectedVoucherGroup.name}</StyledText>
              </StyledVoucherGroupBoxRow>
              <StyledDiv />
              <StyledVoucherGroupBoxRow>
                <StyledLabel>Start Date: </StyledLabel>
                <StyledText>{selectedVoucherGroup.startDate}</StyledText>
                <StyledLabel>End Date: </StyledLabel>
                <StyledText>{selectedVoucherGroup.endDate}</StyledText>
              </StyledVoucherGroupBoxRow>
            </StyledVoucherGroupDetailsBox>
          )}
        </StyledVoucherGroupBoxColumn>
      </div>
    </>
  );
};
