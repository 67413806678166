import styled from '@emotion/styled';
import { newTypography } from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import InfoTooltip from 'components/shared/tooltip/info/InfoTooltip';
import Error from 'components/shared/error/Error';

import { LoginContainerBody as _LoginContainerBody } from '../../Login.style';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const LoginContainerBody = styled(_LoginContainerBody)`
  font-family: ${newTypography.primaryFont};

  p {
    font-family: ${newTypography.primaryFont};
    text-align: center;
  }
`;

export const ChangePasswordSection = styled.div<StyledWithThemeProps>`
  padding: ${newTypography.unit * 2}px ${newTypography.unit * 3}px;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  border-radius: 3px;

  label {
    margin: 0 0 ${newTypography.unit * 4}px 0;
    display: inline-block;

    &:last-of-type {
      margin: ${newTypography.unit * 4}px 0;
    }
  }

  ul {
    padding-inline: ${newTypography.unit * 4}px;
    margin-top: 0;
  }
`;

export const StyledInfoTooltip = styled(InfoTooltip)`
  display: inline-flex;
  margin: 0;
  position: relative;
  left: 4px;
`;

export const StyledError = styled(Error)`
  font-size: 14px;
  text-align: center;
  margin-bottom: ${newTypography.unit * 5}px;
`;

export const ErrorContainer = styled.div`
  height: 50px;
  font-family: ${newTypography.primaryFont};

  .reset-password {
    color: ${newGlobalTheme.colors.global.error};
  }
`;
