import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { LoaderColor, LoaderSizeRecord, LoaderProps } from './Loader.consts';
import gifLoader from '../../../assets/svgs/loader.gif';

const rotate = keyframes`
  0% {transform: rotate(0deg) scale(1)}
  50% {transform: rotate(180deg) scale(0.8)}
  100% {transform: rotate(360deg) scale(1)}
`;

export const StyledLoader = styled.span<LoaderProps & React.HTMLAttributes<HTMLSpanElement>>`
  background-image: url(${gifLoader});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: ${({ size }) => LoaderSizeRecord[size]}px;
  height: ${({ size }) => LoaderSizeRecord[size]}px;
  display: inline-block;
`;

export const StyledLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

export const StyledLoaderContainerCampaign = styled.div<{ isEmpty: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  width: 100%;
  height: ${({ isEmpty }) => (isEmpty ? '50%' : '100%')};
`;
