import styled from '@emotion/styled';
import typography from 'styles/typography';
import Dropdown from '../../dropdown/Dropdown';
import { ButtonOutlined } from '../buttonOutlined';
import { positions } from './MoreActionsDropdown.consts';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const MoreActionButton = styled(ButtonOutlined)`
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  justify-content: space-between;
  padding: ${typography.unit}px ${typography.unit * 3}px;
`;

export const MoreActionStyledDropDown = styled(Dropdown)<{ position: positions }>`
  text-transform: capitalize;
  position: absolute;
  top: ${({ position }) => (position.startsWith('top') ? 'unset' : '32px')};
  bottom: ${({ position }) => (position.startsWith('top') ? '32px' : 'unset')};
  right: ${({ position }) => (position.endsWith('left') ? '0px' : 'unset')};
  left: ${({ position }) => (position.endsWith('right') ? '0px' : 'unset')};
  padding: 12px 0px;
  gap: 8px;
  display: flex;
  flex-direction: column; 
  border-radius: 5px;
  box-shadow: 0px 0px 8.3px 10px rgba(0, 0, 0, 0.16);
  border: none; 
  & li {
    width: 134px; 
    padding: 0px 16px; 
    height: 28px; 
     :hover {
        background: ${newGlobalTheme.colors.status.draft};
      }
  }
`;


export const MoreActionDropdownContainer = styled.div<{open?: boolean}>`
  position: relative;
  width: 100%;
  border-radius: 5px;
  ${({open}) => open && `border: 1px solid ${newGlobalTheme.colors.global.textSubheading};`}
  display: flex; 
  align-items: center; 
  flex-direction: row; 
  justify-content: center; 
  width: 27px;
  height: 27px;
  padding: 11px 4px;
  svg {
    min-width: 19px; 
    min-height: 19px; 
  }
`;

export const MoreActionButtonBase = styled(ButtonOutlined)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;
