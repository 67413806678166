import React, { useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'hooks/use-onclick-outside';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { MarketConfigurationGuard } from 'components/zoneGuard/MarketConfigurationGuard';
import { FeatureGuard } from 'components/featureGuard/FeatureGuard';
import { Tab, TabStripProps } from './TabStrip.consts';
import { StyledNav, StyledSubNav } from './NewTabStrip.style';
import TabStripItem from './components/TabStripItem/NewTabStripItem';
import { newTypography } from 'styles/typography';

const TabStrip = ({ tabs, selectedTabId, onClick, className, isOfferTab }: TabStripProps) => {
  const [selectedTab, setSelectedTab] = useState<string | number>(selectedTabId || tabs[0].id);
  const [openTab, setOpenTab] = useState<Tab>(null);
  const ref = useRef();

  const getIsSelected = (tab: Tab) =>
    selectedTab === tab.id || tab.children?.findIndex((childTab) => childTab.id === selectedTab) > -1;

  const getIsOpen = (tab: Tab) => openTab && openTab.id === tab.id;

  const handleClick = (tab: Tab, isMainTab: boolean) => {
    if (tab.children) {
      // eslint-disable-next-line no-unused-expressions
      openTab && openTab.id === tab.id ? setOpenTab(null) : setOpenTab(tab);
    } else {
      setSelectedTab(tab.id);
      // eslint-disable-next-line no-unused-expressions
      isMainTab && setOpenTab(null);
    }
    // eslint-disable-next-line no-unused-expressions
    onClick && onClick(tab.id);
  };

  useOnClickOutside(ref, () => setOpenTab(null));

  useEffect(() => {
    if (selectedTabId) {
      setSelectedTab(selectedTabId);
    }
  }, [selectedTabId]);

  // Update page container padding based on whether a submenu is open
  useEffect(() => {
    const pageContainer = document.querySelector('[data-automation-id="page-container"]') as HTMLElement;
    if (pageContainer) {
      pageContainer.style.transition = `padding-top ${newTypography.transition}s ease`;
      pageContainer.style.paddingTop = openTab ? '15px' : '';
    }else{
      console.log('Page container not exist');
    }
  }, [openTab]);
  return (
    <StyledNav className={className} ref={ref} data-automation-id="tab">
      {tabs.map((tab: Tab, index: number) => (
        <MarketConfigurationGuard configurations={tab.marketConfigurations} key={`${tab.id}_${index}`}>
          <FeatureGuard features={tab.features}>
            <TabStripItem
              tab={tab}
              isSelected={getIsSelected(tab)}
              isExpandable={tab.children?.length > 0}
              isOpen={getIsOpen(tab)}
              onClick={() => handleClick(tab, true)}
              isOfferTab={isOfferTab}
              />
          </FeatureGuard>
        </MarketConfigurationGuard>
      ))}

      <StyledSubNav isOpen={Boolean(openTab)} data-automation-id="tab">
        {openTab?.children.map((childTab, index: number) => (
          <RoleGuard roles={childTab.roles} key={childTab.id}>
            <MarketConfigurationGuard configurations={childTab.marketConfigurations} key={`${childTab.id}_${index}`}>
              <FeatureGuard features={childTab.features}>
                <TabStripItem
                  tab={childTab}
                  isSelected={getIsSelected(childTab)}
                  isExpandable={childTab.children?.length > 0}
                  isOpen={getIsOpen(childTab)}
                  onClick={() => handleClick(childTab, true)}
                  fontSize={16}
                  paddingLeft={20}
                />
              </FeatureGuard>
            </MarketConfigurationGuard>
          </RoleGuard>
        ))}
      </StyledSubNav>
    </StyledNav>
  );
};

export default TabStrip;
