import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import { newTypography } from 'styles/typography';
import { Icon } from 'components/shared/icon';
import { StyledArrowProps, StyleTabStripItemProps } from './TabStripItem.consts';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import zIndex from 'styles/zIndex';


const getOfferTabSelectedStyle = (isSelected = true) => {
  return isSelected ?  `border-bottom: 2px solid ${newGlobalTheme.colors.global.brandMain}; font-weight: 700; 
    ` : `border-bottom: 2px solid transparent; color: ${newGlobalTheme.colors.text.disabledSave}; font-weight: 400;`
}

const getRegularTabSelectedStyle = (isSelected = true) => {

 return isSelected ? newGlobalTheme.colors.global.brandMain : 'transparent'
}
export const StyledTitle = styled.div<StyleTabStripItemProps & { fontSize?: number; paddingLeft?: number, isOfferTab?: boolean }>`
  display: flex;
  height: 100%;
  align-items: center;
  cursor: pointer;
  padding: ${({ paddingLeft }) => (paddingLeft ? `12px ${paddingLeft}px 0 ${paddingLeft}px` : `12px 25px 0 25px`)};
  color: ${newGlobalTheme.colors.text.primary};
  line-height: 22.83px;
  font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 18)}px;
  text-transform: capitalize;
  position: relative;
  transition: all ${newTypography.transition}s;

  ${({isOfferTab, isSelected}) => isOfferTab ? `
     display: flex; 
     justify-content: center;
     width: 48px; 
    ${getOfferTabSelectedStyle(isSelected)}
  ` : `
    &:hover {
      background-color: ${newGlobalTheme.colors.tabStrip.backgroundHover};
    }
    border-bottom: 6px solid ${getRegularTabSelectedStyle(isSelected)};
  `}

 
`;

export const StyledLink = styled(Link, {
  shouldForwardProp: (propName) => isPropValid(propName),
})<StyleTabStripItemProps>`
  height: 100%;
  pointer-events: ${({ isSelected }) => isSelected && 'none'};
  &:focus-visible {
    outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
    outline-offset: 4px;
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }
`;

export const StyledArrowUp = styled(Icon, {
  shouldForwardProp: (propName) => isPropValid(propName),
})<StyledArrowProps>`
  fill: #5E5E5E;
  width: 8.65px;
  height: 8.65px;
  transform: ${({ isOpen }) => !isOpen && `rotate(180deg)`};
  transition: all ${newTypography.transition}s;
  margin-left: 10px;
`;
