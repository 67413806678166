import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { ButtonTextHeader } from 'components/shared/button';
import styled from '@emotion/styled';


export const StyledButtonText = styled(ButtonTextHeader)`
font-family: Speedee;
font-size: 14px;
font-weight: 400;
line-height: 28px;
letter-spacing: -0.03em;
text-align: left;
color:${newGlobalTheme.colors.global.primaryTextColor}
`;

export const StyledIcon = styled.div`
margin-right:20px;
width:10px;
height:10px;
display:flex;
align-items:center;
`;


export const StyledMenuItem = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
width:95px;
height:28px;
`;