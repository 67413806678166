import styled from '@emotion/styled';
import { Icon, IconProps } from 'components/shared/icon';
import typography from 'styles/typography';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { NewButtonText } from 'components/shared/button';

export const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 530px;
  height: 36px;
`;

export const StyledIcon = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.global.inputPrimary};
  margin-right: ${typography.unit * 2}px;
  cursor: pointer;
  width: 18px;
  align-items: center;
`;

export const SelectOfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: absolute;
  top: 0;
  left: 0;
`;

export const SelectLocationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
`;
export const StyledCheckbox = styled(Checkbox)`
  margin-left: auto;
`;

export const StyledButtonText = styled(NewButtonText)`
  font-size: 14px;
  font-weight: 700;
  line-height: 22.83px;
  letter-spacing: -0.03em;
  margin-bottom: 8px;
  text-align: left;
  color: ${newGlobalTheme.colors.global.textSubheading};
`;
export const LoaderSpace = styled.div`
  width: 25px;
`;
