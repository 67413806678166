import { appTheme } from 'styles/themes/defaultTheme';

export enum LoaderSize {
  ExtraSmall = 'extraSmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum LoaderColor {
  Primary = 'primary',
  Secondary = 'secondary',
}

export const LoaderSizeRecord: Record<LoaderSize, number> = {
  [LoaderSize.ExtraSmall]: 100,
  [LoaderSize.Small]: 120,
  [LoaderSize.Medium]: 150,
  [LoaderSize.Large]: 200,
};

export interface LoaderProps {
  size?: LoaderSize;
  color?: LoaderColor;
  theme?: appTheme;
  className?: string;
}
