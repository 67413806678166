import styled from '@emotion/styled';
import { newTypography } from 'styles/typography';
import { OverviewBase, OverviewSubHeader } from 'pages/dashboard/Dashboard.style';
import { StyledWithThemeProps } from 'utils/types';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const UpdatesOverviewContainer = styled(OverviewBase)`
  width: 288px;
  height: 630px;
  padding: ${newTypography.unit * 5}px ${newTypography.unit * 7}px;
`;

export const UpdatesOverviewSubHeader = styled(OverviewSubHeader)`
  margin-bottom: ${newTypography.unit * 2}px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.03em;
  height: 18px;
`;

export const UpdatesOverviewLabelText = styled.div`
  color: ${newGlobalTheme.colors.text.primary};
  font-weight: 400;
  line-height: 22.83px;
`;

export const UpdatesSection = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: ${newTypography.unit * 4}px;
  }
`;

export const Separator = styled.div<StyledWithThemeProps>`
  width: 224px;
  border-top: 1px solid ${({ theme }) => newGlobalTheme.colors.global.border};
`;
