import styled from '@emotion/styled';
import { StyledWithThemeProps } from 'utils/types';
import  { newTypography } from 'styles/typography';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { Color, borderColor } from '../../DragAndDrop';

export const DragableStyledItem = styled.div<
  StyledWithThemeProps & { color?: Color; disabled?: boolean, border?: borderColor  }
>`

  width: 100%; 
  height: 100px;
  padding: 50px 0px 41.5px 34px; 
  line-height: 30px; 
  border-radius: 8px;
  background-color: ${({color}) =>  color ? newGlobalTheme.colors.dashboard[color] : newGlobalTheme.colors.dashboard.grey};
  font-family: ${newTypography.primaryFont}; 
  border-top: 6px solid ${({border}) =>  border ? newGlobalTheme.colors.dashboard.border[border] : newGlobalTheme.colors.dashboard.border.darkGrey};
  border: ${({ color, theme }) => !color && `1px solid ${theme.colors.global.border}`};
  transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  font-size: 21px;
  display: flex;
  font-weight: 700;
  align-items: center;
  justify-content: left;
  &:hover {
    cursor: ${({ disabled }) => (disabled ? `cursor` : `grab`)};
    box-shadow: ${({disabled, theme }) => !disabled && theme.colors.global.boxShadow};
  }
  &:active {
    cursor: ${({ disabled }) => (disabled ? `cursor` : `grabbing`)};
  }
 
`;
