import { InnerSelectboxPaginate } from './InnerSelectboxPaginate';
import typography from 'styles/typography';
import styled from '@emotion/styled';

export const StyledSelectPaginate = styled(InnerSelectboxPaginate)<{ isDisabled: boolean }>`
  flex: 1;
  font-size: ${typography.fontSize}px;
  color: ${({ theme }) => theme.colors.text.primary};
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
  ${({ isDisabled }) =>
    isDisabled &&
    `
      pointer-events: auto;
      cursor: not-allowed;
    `}
`;
