import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import typography from 'styles/typography';
import { StyledLabelProps, StyledToggleSwitchProps, ToggleSwitchContainerProps } from './ToggleSwitch.consts';

const sizes = {
  small: {
    ball: {
      width: '9px',
      height: '9px',
      top: '1px',
      left: '1px',
      transition: 'translateX(11px)',
    },
    slide: {
      width: '24px',
      height: '13px',
    },
  },
  medium: {
    ball: {
      width: '13px',
      height: '13px',
      top: '1px',
      left: '1px',
      transition: 'translateX(13px)',
    },
    slide: {
      width: '30px',
      height: '17px',
    },
  },
  large: {
    ball: {
      width: '14px',
      height: '14px',
      top: '3px',
      left: '3px',
      transition: 'translateX(14px)',
    },
    slide: {
      width: '34px',
      height: '20px',
    },
  },
  xlarge: {
    ball: {
      width: '13px',
      height: '13px',
      top: '2px',
      left: '2px',
      transition: 'translateX(14px)',
    },
    slide: {
      width: '30px',
      height: '17px',
    },
  },
};

export const ToggleSwitchContainer = styled.div<ToggleSwitchContainerProps>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  line-height: 21px;
  margin-right: 20px;
`;

export const Ball = styled.span<StyledToggleSwitchProps>`
  position: absolute;
  background-color: ${({ checked, disabled, theme }) =>
    checked ? theme.colors.checkbox.iconColor : disabled ? theme.colors.global.border : theme.colors.text.white};
  width: ${({ size }) => sizes[size].ball.width};
  height: ${({ size }) => sizes[size].ball.height};
  top: ${({ size }) => sizes[size].ball.top};
  left: ${({ size }) => sizes[size].ball.left};
  border-radius: 50%;
  transition: all ${typography.transition}s;
  transform: ${({ checked, size }) => (checked ? sizes[size].ball.transition : 'none')};
`;

export const Slide = styled.div<StyledToggleSwitchProps>`
  width: ${({ size }) => sizes[size].slide.width};
  height: ${({ size }) => sizes[size].slide.height};
  border-radius: 10px;
  position: relative;
  background-color: ${({ checked, disabled, theme }) =>
    checked
      ? disabled
        ? theme.colors.checkbox.disabledCheckedBg
        : newGlobalTheme.colors.button.secondary
      : newGlobalTheme.colors.toggle.disabled};
  transition: all ${typography.transition}s;
  cursor: pointer;
  border: ${({ checked }) => (checked ? '1px solid #C08B00' : '1px solid #ADADAD')};
`;

export const StyledLabel = styled.label<StyledLabelProps>`
  min-height: 20px;
  text-transform: capitalize;
  font-size: 13px;
  margin-right: ${({ labelPosition }) => (labelPosition === 'before' ? `${typography.unit * 2}px` : '0')};
  margin-left: ${({ labelPosition }) => (labelPosition === 'after' ? '10px' : '0')};
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;
`;
