import styled from '@emotion/styled';
import Modal from 'components/shared/modal/Modal';
import InfiniteScroll from 'react-infinite-scroll-component';
import typography from 'styles/typography';
import { StyledWithThemeProps } from 'utils/types';
import OfferVersionItem from 'pages/campaigns/campaignManagement/components/campaignForm/components/offerVersionItem/OfferVersionItem';

export const StyledModal = styled(Modal)`
  width: 1030px;
  height: 85vh;
  h3{
  font-family: 'Speedee';
    font-size: 28px;
    font-weight: 700;
    line-height: 22.83px;
    letter-spacing: -0.03em;
    text-align: left;
    overflow: visible;}
  font-family: 'Speedee'; important!

`;

export const OfferSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'Speedee';
`;

export const OffersContainer = styled.div<StyledWithThemeProps>`
  width: 100%;
  height: 100%;
  border: ${({ theme }) => `1px solid ${theme.colors.global.border}`};
  overflow-y: auto;
  display: flex;
  padding-top:20px;
  margin: ${typography.unit * 5}px 0 ${typography.unit * 8}px 0;
`;

export const OfferSelectionFooter = styled.footer`
  display: flex;
  justify-content:flex-end;
  gap: ${typography.unit * 6}px;
`;

export const StyledOfferItem = styled(OfferVersionItem)<{ isSelected: boolean }>`
  border: 1px solid ${({ isSelected, theme }) =>isSelected ? `${theme.colors.global.inputPrimary}` : `${theme.colors.global.background}`};
  cursor: pointer;
`;

export const OffersList = styled(InfiniteScroll)`
  display: flex;
  flex-wrap: wrap;
  padding-left:55px;
  column-gap: 55px;
  row-gap: ${typography.unit * 5}px;
  overflow-y: auto;
`;

export const NoOffers = styled.div`
  margin: ${typography.unit * 10}px auto;
  display: flex;
`;
