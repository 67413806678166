import React, { useEffect, useRef, useState } from 'react';
import { NewButtonContained, NewButtonOutlined } from 'components/shared/button';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FormState } from '../../Login.consts';
import { CustomError as ErrorComponent } from '../../Login.style';
import { LoginFormProps } from './LoginForm.consts';
import { PasswordSection, LoginContainerBody, StyledNewButtonText, RightTextButtonWrapper } from './LoginForm.style';
import useDynamicSVGImport from 'hooks/use-dynamic-svg-import';
import NewTextField from 'components/shared/newTextField/NewTextField';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const LoginForm = ({ state, dispatch }: LoginFormProps) => {
  const { register, handleSubmit, getValues, watch } = useForm({});
  const [loginProccessing, setLoginProccessing] = useState(false);
  const location = useLocation();
  const [autofill, setAutofill] = useState(false);
  useDynamicSVGImport('eyePasswordHide');

  const onSubmit = async (formData: any) => {
    formData = btoa(JSON.stringify(formData));
    setLoginProccessing(true);
    let res = await fetch(`/login${location.search}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ eFormData: formData }),
      credentials: 'include'
    });
    if (res.ok) {
      window.location.href = res.url;
    } else {
      res = await res.json();
      dispatch({ type: 'SUBMIT_LOGIN', payload: res });
    }
    setLoginProccessing(false);
  };

  const enteredUsername = watch('username');
  const enteredPassword = watch('password');
  const handleKeyPress = async (e: KeyboardEvent) => {
    const username = getValues('username');
    const password = getValues('password');
    const activeElement = document.activeElement;
    if (e.key === 'Enter') {
      if (activeElement === document.getElementById('loginButton') || activeElement.tagName === 'INPUT') {
        if (username && password) {
          dispatch({ type: 'SET_ERROR', payload: { error: null } });
          await onSubmit({ username, password });
        } else {
          dispatch({ type: 'SET_ERROR', payload: { error: 'Username & password are required to log in' } });
        }
      }
    }
  };

  useEffect(() => {
    setAutofill(false);
    setTimeout(() => {
      const usernameElement = document.getElementById('loginUsername');
      const passwordElement = document.getElementById('loginPassword');
      const usernameBackground = window.getComputedStyle(usernameElement).backgroundColor;
      const passwordBackground = window.getComputedStyle(passwordElement).backgroundColor;
      if (
        usernameBackground !== newGlobalTheme.colors.text.backgroundColorRGB &&
        passwordBackground !== newGlobalTheme.colors.text.backgroundColorRGB
      ) {
        setAutofill(true);
      } else {
        setAutofill(false);
      }
    }, 1000);
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [enteredUsername, enteredPassword]);

  const checkDisabled = () => {
    if (autofill) {
      return false;
    } else {
      if (
        !(enteredUsername && enteredUsername.length > 0 && enteredPassword && enteredPassword.length > 0) ||
        loginProccessing
      ) {
        return true;
      }
    }
  };

  const errorRef = useRef(null);

  useEffect(() => {
    if (state.error && errorRef.current) {
      errorRef.current.focus();
    }
  }, [state.error]);

  return (
    <LoginContainerBody>
      <p>
        Welcome to <b>VCE.</b> Please login to continue.
      </p>
      <NewButtonContained
        disabled={loginProccessing}
        onClick={() => {
          setLoginProccessing(true);
          window.location.href = `/initiateSSO${location.search ?? ''}`;
        }}
        autoFocus={state.autoFocus}
      >
        Login with McD SSO
      </NewButtonContained>
      <p>
        <i>- or -</i>
      </p>
      <NewTextField placeholder="Email" register={register} name="username" id="loginUsername" />
      <PasswordSection>
        <NewTextField placeholder="Password" type="password" register={register} name="password" id="loginPassword" />
        <RightTextButtonWrapper>
          <StyledNewButtonText onClick={() => dispatch({ type: 'SET_FORM', payload: FormState.ForgotPassword })} >
            Forgot Password
          </StyledNewButtonText>
        </RightTextButtonWrapper>
      </PasswordSection>
      <NewButtonOutlined id="loginButton" onClick={handleSubmit(onSubmit)} disabled={checkDisabled()}>
        Login
      </NewButtonOutlined>
      <ErrorComponent ref={errorRef} tabIndex={state.error ? 0 : undefined}>
        {state.error && state.error}
      </ErrorComponent>
    </LoginContainerBody>
  );
};