import styled from '@emotion/styled';
import { Icon } from 'components/shared/icon';
import {  NewButtonText } from 'components/shared/button';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const StyledIcon = styled(Icon)`
  width: 16px;
  color: ${newGlobalTheme.colors.global.textSubheading}; 
`;

export const StyledButtonText = styled(NewButtonText)`
    font-size: 12px; 
    font-weight: 700; 
`;

export const AddRowWrapper = styled.div`
  display: flex; 
  flex-direction: row; 
  gap: 8px; 
  padding: 8px 12px 8px 0px;
  height: 36px; 
  align-items: center;
  color: ${newGlobalTheme.colors.global.textSubheading}; 
`
