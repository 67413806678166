import styled from "@emotion/styled";
import TextArea from "./TextArea";
import { newGlobalTheme } from "styles/themes/newGlobalTheme";


export const StyledOfferFormTextArea = styled(TextArea)<{hasError?: boolean}>`
  font-family: Speedee; 
  font-weight: 700;
    textarea {
    ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave}; cursor: default;` : `color: ${newGlobalTheme.colors.text.primary};`}
      ${({hasError}) => hasError && `border: 1px solid ${newGlobalTheme.colors.global.error} !important;`}

      font-family: Speedee;
        &:focus {
        border: 1px solid ${({disabled}) => disabled ? `${newGlobalTheme.colors.global.borderColor} !important;` : `${newGlobalTheme.colors.text.disabledSave} !important; `}
        border: 1px solid ${({hasError}) => hasError && `${newGlobalTheme.colors.global.error} !important;`}
        }

        padding: 12px 16px;
        font-size: 12px;
        line-height: 20px; 
        border-radius: 5px;
        ${({disabled}) => disabled ? `border: 1px solid ${newGlobalTheme.colors.global.borderColor};` : `border: 1px solid  ${newGlobalTheme.colors.text.disabledSave}; `}

        ::placeholder {
          color: ${newGlobalTheme.colors.toggle.disabled};
        }
    }

    ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave}; cursor: default;` : `color: ${newGlobalTheme.colors.text.primary};`}

  
    label {
    font-size: 12px; 
      ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave} !important;` : `color: ${newGlobalTheme.colors.text.primary} !important;`}
    }


    div {
        font-size: 12px; 
      ${({disabled}) => disabled ? `color: ${newGlobalTheme.colors.text.disabledSave};` : `color: ${newGlobalTheme.colors.text.primary};`}
      
    }

  
`
