import React, { useEffect } from 'react';
import { ButtonContained, ButtonText } from 'components/shared/button';
import { Modals, openModal } from 'app/slices/modals';
import { useSelector } from 'react-redux';
import { store } from 'app/store';
import { TagsValidEntities } from 'utils/types/tags';
import { ImageFilterType, ImageProps, ImageSource } from 'pages/settings/images/Images.consts';
import { StyledLoader } from 'pages/shared/shared.style';
import { LoaderSize } from 'components/shared/loader/Loader.consts';
import { SearchTextField } from 'components/shared/searchTextField/SearchTextField';
import { imagesSelection } from 'app/genericSlices/images';
import { ArchiveStatus } from 'pages/settings/settings.const';
import { useTagsQuery } from 'hooks/use-tags-query';
import { imagesGqls } from 'pages/settings/images/Images.gqls';
import { useQuery } from '@apollo/client';
import { useToastError } from 'hooks/use-toast-error';
import { FormMode, OrderDirection } from 'utils/types';
import {
  ImageItem,
  ImagesContainer,
  ImageSelectionFormFooter,
  ImagesFilterBar,
  ImagesList,
  NoImages,
  StyledImageButtonText,
  StyledModal,
  StyledViewImageSelection,
  TagsSelectbox,
} from './ImagesSelectionModal.style';
import { FetchPolicies } from 'utils/types/common';
import { cloneDeep } from 'lodash';
import { viewImageClicked } from 'utils/offer';

export const ImagesSelectionModal = (imageSelectionProps: any) => {
  const reduxData = useSelector((state: any) => {
    return cloneDeep({ ...state.modals.data });
  });
  const imageSourceValue =
    reduxData?.mode === 'edit' && reduxData?.offerSource === 'DOE' ? ImageSource.DOE : ImageSource.VCE;
  const { load: loadTags, tags } = useTagsQuery([TagsValidEntities.Image]);
  const filters = {
    ...useSelector(imagesSelection.imagesState)?.filters,
    [ImageFilterType.Archive]: [ArchiveStatus.Unarchived],
  };
  const { selectedImage } = useSelector(imagesSelection.imagesState);
  const { data, error, fetchMore } = useQuery(imagesGqls.queries.getAll, {
    fetchPolicy: FetchPolicies.CacheAndNetwork,
    nextFetchPolicy: FetchPolicies.CacheAndNetwork,
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        filters: imagesSelection.getFilters(filters),
        order: { id: OrderDirection.DESC },
        limit: 24,
        imageSource: imageSourceValue,
      },
    },
  });
  const {
    getImages: { items: images, total },
  } = data || { getImages: {} };
  useToastError(error, 'Error loading images');

  const fetchNextImages = async () => {
    if (images.length < total) {
      await fetchMore({
        variables: {
          data: {
            filters: imagesSelection.getFilters(filters),
            offset: images.length,
            limit: 24,
            order: { id: OrderDirection.DESC },
          },
        },
      });
    }
  };

  useEffect(() => {
    store.dispatch(imagesSelection.actions.setSelectedImage(null));
    store.dispatch(
      imagesSelection.actions.setFilter({
        filter: {
          [ImageFilterType.Archive]: [ArchiveStatus.Unarchived],
        },
      }),
    );
    loadTags();
  }, []);

  return (
    <StyledModal title="Image Selection" withLoader={false}>
      <div>
        <ImagesFilterBar>
          <TagsSelectbox
            items={tags}
            multiple
            name="tags"
            label="Tags"
            reset
            placeholder="Select"
            initialSelectedItems={filters[ImageFilterType.Tags]?.map((id: any) => Number(id))}
            onChange={(items) => {
              store.dispatch(
                imagesSelection.actions.setFilter({
                  filter: ImageFilterType.Tags,
                  value: Object.values(items).map((i: any) => i.id),
                }),
              );
            }}
            withAmount
          />
          <SearchTextField
            name="images-search"
            value={filters[ImageFilterType.SearchQuery] ?? ''}
            onChange={(e) => {
              store.dispatch(
                imagesSelection.actions.setFilter({
                  filter: ImageFilterType.SearchQuery,
                  value: e.target.value,
                }),
              );
            }}
          />
        </ImagesFilterBar>
        <ImagesContainer id="images-modal-scroll">
          {!images ? (
            <StyledLoader size={LoaderSize.Large} />
          ) : (
            <>
              {total === 0 ? (
                <NoImages>No Images</NoImages>
              ) : (
                <ImagesList
                  scrollableTarget="images-modal-scroll"
                  dataLength={images.length}
                  next={fetchNextImages}
                  hasMore={images.length < total}
                  loader={<StyledLoader size={LoaderSize.Large} />}
                >
                  {images.map((image: ImageProps) => (
                    <ImageItem
                      selected={selectedImage?.id === image.id}
                      key={image.id}
                      tabIndex={0}
                      data-automation-id="image-item"
                      role="button"
                      onClick={() => store.dispatch(imagesSelection.actions.setSelectedImage(image))}
                      onKeyDown={() => store.dispatch(imagesSelection.actions.setSelectedImage(image))}
                    >
                      {imageSourceValue === ImageSource.VCE ? (
                        <StyledImageButtonText
                          data-automation-id="offer-selection-image-view"
                          onClick={() =>
                            store.dispatch(
                              openModal({
                                modal: Modals.ImageModal,
                                props: {
                                  mode: FormMode.View,
                                  image: image,
                                  viewOnly: true,
                                  onClose: () =>
                                    store.dispatch(
                                      openModal({
                                        modal: Modals.ImageSelectionModal,
                                        data: imageSelectionProps,
                                      }),
                                    ),
                                },
                              }),
                            )
                          }
                          data-title="View"
                        >
                          <StyledViewImageSelection name="view" />
                        </StyledImageButtonText>
                      ) : (
                        <StyledImageButtonText
                          data-automation-id="offer-selection-image-view"
                          onClick={async () =>
                            store.dispatch(
                              openModal({
                                modal: Modals.DoeImagesModal,
                                props: {
                                  mode: FormMode.View,
                                  image: await viewImageClicked(image.id),
                                  viewOnly: true,
                                  onClose: () =>
                                    store.dispatch(
                                      openModal({
                                        modal: Modals.ImageSelectionModal,
                                        props: {
                                          data: {
                                            selectedLanguage: imageSelectionProps?.data?.selectedLanguage,
                                            offer: imageSelectionProps?.data?.offer,
                                            mode: imageSelectionProps?.data?.mode,
                                          },
                                        },
                                        data: imageSelectionProps,
                                      }),
                                    ),
                                },
                              }),
                            )
                          }
                          data-title="View"
                        >
                          <StyledViewImageSelection name="view" />
                        </StyledImageButtonText>
                      )}
                      <img src={image.file} alt="placeholder" loading="lazy" crossOrigin="anonymous" />
                      <div>{image.name}</div>
                    </ImageItem>
                  ))}
                </ImagesList>
              )}
            </>
          )}
        </ImagesContainer>
        <ImageSelectionFormFooter>
          <ButtonText
            onClick={() => {
              store.dispatch(imagesSelection.actions.setFilters({}));
              store.dispatch(openModal({ modal: Modals.OfferModal,  props: {  tempValuesChanged: imageSelectionProps.data.tempValuesChanged } }));
            }}
          >
            Cancel
          </ButtonText>
          <ButtonContained
            disabled={!selectedImage}
            onClick={() => {
              store.dispatch(imagesSelection.actions.setFilters({}));
              store.dispatch(
                openModal({
                  modal: Modals.OfferModal,
                  data: { image: selectedImage, templateProducts: undefined },
                  props: {  tempValuesChanged: true }
                }),
              );
            }}
          >
            Save
          </ButtonContained>
        </ImageSelectionFormFooter>
      </div>
    </StyledModal>
  );
};
