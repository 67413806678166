import React from 'react';
import { StyledTitle as NewStyledTitle, OfferStyledTitle } from './NewTextPageTitle.style';
import { StyledTitle } from './TextPageTitle.style';
import { TextTitleProps } from './TextPageTitle.const';

const TextPageTitle = (props: TextTitleProps) => {
  const { text, className, tabIndex } = props;
  return <StyledTitle className={className}>{text}</StyledTitle>;
};

export const NewTextPageTitle = (props: TextTitleProps) => {
  const { text, className, tabIndex } = props;
  return <NewStyledTitle className={className} tabIndex={tabIndex}>{text}</NewStyledTitle>;
};

export const OfferTextPageTitle = (props: TextTitleProps) => {
  const { text, className, tabIndex } = props;
  return <OfferStyledTitle className={className} tabIndex={tabIndex}>{text}</OfferStyledTitle>;
};

export default TextPageTitle;
