import styled from '@emotion/styled';
import RadioItem from 'components/shared/radioGroup/radioItem/RadioItem';
import typography from 'styles/typography';
import NewRadioItem from './radioItem/NewRadioItem';

export const RadioGroupContainer = styled.div<{ direction: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ direction }) => (direction === 'row' ? 'center' : 'flex-start')};
`;

export const StyledRadioItem = styled(NewRadioItem)<{ direction: 'row' | 'column' }>`
  margin-right: ${({ direction }) => direction === 'row' && `${typography.unit * 5}px`};
  margin-bottom: ${({ direction }) => direction === 'column' && `${typography.unit}px`};
`;
