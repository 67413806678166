export const showActionsCell = (rowId: string | number) => {
  const cell = document.getElementById(`Actions-${rowId}`);
  if (cell) {
    cell.style.opacity = '1'; // Make the cell visible
    cell.style.pointerEvents = 'auto'; // Allow interactions with the cell
    cell.style.position = 'static'; // Restore its original position
  }
};

export const hideActionsCell = (rowId: string | number) => {
  const cell = document.getElementById(`Actions-${rowId}`);
  if (cell) {
    cell.style.opacity = '0'; // Make the cell invisible
    cell.style.pointerEvents = 'none'; // Prevent any interaction with the cell
    cell.style.position = 'absolute'; // Remove it from the normal document flow
  }
};
