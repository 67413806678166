import styled from '@emotion/styled';
import { LoginContainerBody as _LoginContainerBody } from '../../Login.style';

export const LoginContainerBody = styled(_LoginContainerBody)`
  h3 {
    font-weight: 600;
    text-align: center;
  }
`;

export const CenterTextButtonWrapper = styled.div`
  display: flex; 
  flex-direction: row; 
  justify-content: center; 
`
