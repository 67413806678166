import React from 'react';
import { ActionType, GenericItem, GenericSet } from 'utils/types';
import {
  SelectedDisplayMode,
  SetListItemProps,
} from 'pages/shared/setItemsSelectionForm/setListItem/SetListItem.consts';
import { capitalize } from 'utils/text';
import {
  getSetItems,
  isInitiallyNonCustomEmptySet,
} from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.utils';
import { ListType } from 'pages/shared/setItemsSelectionForm/SetItemsSelectionForm.consts';
import {
  AccordionHeader,
  StyledAccordion,
  StyledAccordionArrow,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledListItem,
  StyledSetListItemHeader,
} from './SetListItem.style';
import { handleKeyPress } from 'utils/keyPress';

const SetListItem = React.memo(
  ({
    itemSet,
    setOf,
    listType,
    onItemSetActionClick,
    onItemActionClick,
    onExcludeItemClick,
    onExcludeSetClick,
    onExpandSetClick,
    className,
    nestedSet = false,
    underCustomSet = itemSet.custom,
    titleFormatter = ({ set }) => `${set.name} (${set[setOf]?.length ?? 0} ${capitalize(setOf)})`,
    selectedItemsIds = new Set(),
    selectedFringesIds = new Set(),
    selectedItemSetsIds = new Set(),
    excludedItemsIds,
    excludedSetsIds,
    expandedSetsIds,
    underExcludedSet = excludedSetsIds?.has(Number(itemSet.id)),
    selectedItemsDisplayMode = listType === ListType.Available
      ? SelectedDisplayMode.Disable
      : SelectedDisplayMode.Display,
    selectedSetsDisplayMode = listType === ListType.Available
      ? SelectedDisplayMode.Disable
      : SelectedDisplayMode.Display,
    selectedNestedSetsDisplayMode = listType === ListType.Available
      ? SelectedDisplayMode.Disable
      : SelectedDisplayMode.Display,
    itemFormatter,
    forcedExcludedSetsIds,
    forcedExcludedItemsIds,
    supportSetFringes,
  }: SetListItemProps) => {
    const selectedSection = listType === ListType.Selected;

    const getSetActionType = (set: GenericSet) => {
      if (!onItemSetActionClick) {
        return null;
      }

      if (selectedSection) {
        if (
          onExcludeSetClick &&
          nestedSet &&
          !set.dummy &&
          (selectedItemSetsIds.has(Number(set.nonCustomParentSetId)) || underCustomSet)
        ) {
          return excludedSetsIds?.has(Number(set.id)) || underExcludedSet
            ? ActionType.RemoveExcluded
            : ActionType.AddExcluded;
        }
        if (selectedItemSetsIds.has(Number(set.id))) {
          return ActionType.Remove;
        }
      } else {
        return ActionType.Add;
      }
    };

    const getItemActionType = (item: GenericItem) => {
      if (!onItemActionClick) {
        return null;
      }

      const isFringeItem = selectedFringesIds.has(Number(item.id));
      const isIncludedInSelectedSet = selectedItemSetsIds.has(Number(itemSet.id));
      if (
        selectedSection &&
        onExcludeItemClick &&
        (isIncludedInSelectedSet || isFringeItem || (itemSet.dummy && underCustomSet))
      ) {
        return excludedItemsIds?.has(Number(item.id)) || excludedSetsIds.has(Number(itemSet.id)) || underExcludedSet
          ? ActionType.RemoveExcluded
          : ActionType.AddExcluded;
      }

      return selectedSection ? ActionType.Remove : ActionType.Add;
    };

    const itemsToDisplay = [...itemSet[setOf]];
    if (listType === ListType.Selected && itemSet.dummy) {
      itemsToDisplay.push(...itemSet.fringes);
    }

    const setItems = itemsToDisplay
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((item) => (
        <StyledListItem
          key={`sp1-${item.id}`}
          item={item}
          actionType={getItemActionType(item)}
          onClick={(action) => onItemActionClick(action, item, itemSet)}
          onExcludeClick={onExcludeItemClick}
          disabled={
            underExcludedSet ||
            forcedExcludedItemsIds?.has(Number(item.id)) ||
            excludedSetsIds?.has(Number(itemSet.id)) ||
            (excludedItemsIds?.has(Number(item.id)) && !onExcludeItemClick) ||
            (selectedItemsDisplayMode === SelectedDisplayMode.Disable && selectedItemsIds?.has(Number(item.id)))
          }
          itemFormatter={itemFormatter}
        />
      ));

    const setNestedSets = itemSet.sets?.map((set) => (
      <SetListItem
        key={`sp2-${set.id}`}
        itemSet={set}
        nestedSet
        underCustomSet={itemSet.custom || underCustomSet}
        underExcludedSet={excludedSetsIds?.has(Number(itemSet.id)) || underExcludedSet}
        setOf={setOf}
        listType={listType}
        onItemSetActionClick={onItemSetActionClick}
        onItemActionClick={onItemActionClick}
        onExcludeItemClick={onExcludeItemClick}
        onExcludeSetClick={onExcludeSetClick}
        onExpandSetClick={onExpandSetClick}
        titleFormatter={titleFormatter}
        selectedItemsIds={selectedItemsIds}
        selectedFringesIds={selectedFringesIds}
        selectedItemSetsIds={selectedItemSetsIds}
        selectedItemsDisplayMode={selectedItemsDisplayMode}
        selectedSetsDisplayMode={selectedSetsDisplayMode}
        selectedNestedSetsDisplayMode={selectedNestedSetsDisplayMode}
        excludedSetsIds={excludedSetsIds}
        excludedItemsIds={excludedItemsIds}
        expandedSetsIds={expandedSetsIds}
        itemFormatter={itemFormatter}
        forcedExcludedItemsIds={forcedExcludedItemsIds}
        forcedExcludedSetsIds={forcedExcludedSetsIds}
        supportSetFringes={supportSetFringes}
      />
    ));

    const calcCurrentSetSize = () => {
      const currentSetSize = itemSet.sets?.length ?? 0;
      if (currentSetSize > 0) {
        return currentSetSize;
      }

      if (selectedSection) {
        const items = getSetItems(itemSet, setOf, underCustomSet);
        return items.length ?? 0;
      }

      const items = getSetItems(itemSet, setOf, false);
      if (underCustomSet) {
        return items?.length ?? 0;
      }
      return items.length ?? 0;
    };

    const shouldDisableSet = nestedSet
      ? (selectedNestedSetsDisplayMode === SelectedDisplayMode.Disable && selectedItemSetsIds?.has(Number(itemSet.id))) ||
        (isInitiallyNonCustomEmptySet(itemSet, itemSet.custom) && !selectedSection) ||
        forcedExcludedSetsIds?.has(Number(itemSet.id))
      : (selectedSetsDisplayMode === SelectedDisplayMode.Disable && selectedItemSetsIds?.has(Number(itemSet.id))) ||
        (isInitiallyNonCustomEmptySet(itemSet, itemSet.custom) && !selectedSection) ||
        forcedExcludedSetsIds?.has(Number(itemSet.id));
    const hasCollapsedContent = !isInitiallyNonCustomEmptySet(itemSet) || itemSet.sets?.length !== 0;
    const expanded = expandedSetsIds.has(Number(itemSet.id));
    return (
      <StyledAccordion
        expanded={expanded}
        nestedSet={nestedSet}
        className={className}
        TransitionProps={{ unmountOnExit: true, mountOnEnter: true, timeout: 0 }}
        data-automation-id="accordion"
      >
        <StyledAccordionSummary tabIndex={-1} nestedSet={nestedSet}>
          <AccordionHeader
            aria-label={itemSet?.name}
            role={"button"}
            tabIndex={0}
            expanded={expanded}
            nestedSet={nestedSet}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
              handleKeyPress(event, () => hasCollapsedContent && onExpandSetClick(Number(itemSet.id)))
            }
            onClick={() => hasCollapsedContent && onExpandSetClick(Number(itemSet.id))}
          >
            <StyledAccordionArrow open={expanded} name="arrowUpNew" />
            <StyledSetListItemHeader
              className={className}
              set={itemSet}
              actionType={getSetActionType(itemSet)}
              onExcludeClick={(e, action, set) => {
                onExcludeSetClick(action, set);
                e.stopPropagation();
              }}
              onIconClick={(e) => {
                onItemSetActionClick(selectedSection ? ActionType.Remove : ActionType.Add, itemSet);
                e.stopPropagation();
              }}
              setSize={calcCurrentSetSize()}
              wholeSetSelected={selectedSection && selectedItemSetsIds.has(Number(itemSet.id))}
              titleFormatter={titleFormatter}
              disabled={shouldDisableSet}
              supportSetFringes={supportSetFringes}
            />
          </AccordionHeader>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {setItems}
          {setNestedSets}
        </StyledAccordionDetails>
      </StyledAccordion>
    );
  },
);

export default SetListItem;
