import styled from '@emotion/styled';
import { NewButtonContained } from 'components/shared/button';
import { Icon } from 'components/shared/icon/Icon';
import { IconProps } from 'components/shared/icon/Icon.consts';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: ${newTypography.unit * 3}px;
  & button {
    font-size: 14px;
    font-weight: 700;
  }
`;

export const SaveButton = styled(NewButtonContained)`
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  background-color: ${newGlobalTheme.colors.button.secondary};
  height: 32px;
  :hover {
    background-color: ${newGlobalTheme.colors.button.secondary};
  }
  &:disabled {
    background-color: #f7e1a4;
  }
  border: 1px solid #c08b00;
`;

export const CancelButton = styled(NewButtonContained)`
  color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  background-color: ${newGlobalTheme.colors.global.background};
  height: 32px;
  border: 1px solid #6f6f6f;
  border-radius: 5px;
  :hover {
    background-color: inherit;
  }
`;

export const EditButton = styled(NewButtonContained)`
 color: ${({ theme }) => newGlobalTheme.colors.text.primary};
  background-color: ${newGlobalTheme.colors.button.secondary};
  border: none;
  height: 32px;

  :hover {
    background-color: ${newGlobalTheme.colors.button.secondary};
  }
  &:disabled {
    background-color: #f7e1a4;
  }
  border: 1px solid #c08b00;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  position: absolute; 
  top: 15px; 
  right: 20px; 
  z-index: 20; 
  padding: 8px;
`;

export const DotsContainer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  width: 27px;
  height: 27px;
  padding: 11px 4px 11px 4px;
  gap: 8px;
  border-radius: 5px;
  border:${({ isVisible }) => (isVisible ? '1px solid #006bae' : 'none')};
  top: 18px;
  z-index: 20;
  display: flex;
  align-items: center;
  right: 18px;
  justify-content: flex-end;
`;


export const DotsIcon = styled.div` 
width: 19px;
height: 19px;
padding: 0px 0.78px 0px 0.78px;
gap: 0px;
opacity: 0px;
angle: 90 deg;
cursor: pointer;
`;

export const StyledDotsIcon = styled(Icon) <IconProps>`
width: 17.44px;
top: 18.22px;
left: 7px;
gap: 0px;
opacity: 0px;
angle: 90 deg;
cursor: pointer;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 150%; 
  background-color: white;
  box-shadow: 0px 0px 8.3px 10px #00000029;
  width: 134px;
  height: 130px;
  gap: 8px;
  border-radius: 5px 5px 5px 5px;
  opacity: 0px;
  padding: 12px 0;
  align-items: flex-start;
  & button {
    display: block;
    height: 28px;
    width: 134px;
    padding: 0 16px;
    text-align: left;
    background: none;
    border: none;
    color: #292929;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.03em;
    text-align: left;
    &:hover {
      background-color: #FFFFFF;
    }

    &:disabled {
      color: #999;
      cursor: not-allowed;
    }
   &:focus {
    outline: 2px solid #007BFF; 
    outline-offset: 0.1px;
}
  }
`;
