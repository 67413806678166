import styled from '@emotion/styled';
import { StyledWithThemeProps } from 'utils/types';
import { NewSelectbox } from 'components/shared/selectbox/NewSelectbox';
import OfferModal from 'components/shared/modal/OfferModal';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const StyledModal = styled(OfferModal)`
  min-height: 575px; 
  max-height: 575px; 
  .ModalHeader {
      margin-bottom: 16px; 
  }
  justify-content: center; 
`;

export const StyledImpactTablesContainer = styled.div`
`;

export const StyledImpactTables = styled.div`
  display: flex;
  width: 100%;

`;

export const UsageFormContainer = styled.div<StyledWithThemeProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px; 
  width: 602px;
  padding: 0px 36px; 
  div[role="alert"] {
      margin-top: 42px;
      color: ${newGlobalTheme.colors.text.error} !important; 
  }

`;

export const UsageTemplateSection = styled.div`
    display: flex; 
    flex-direction: column; 
    gap: 16px; 
    color: ${newGlobalTheme.colors.text.primary}; 
`

export const UsageButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 36px 36px 0px 36px; 
`;

export const UsageSelectbox = styled(NewSelectbox)`
  width: 100%; 
`;

export const UsageStyledSpan = styled.span`
  font-family: Speedee;
  font-size: 14px;
  color: ${newGlobalTheme.colors.text.primary}; 
`;
