import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import Tooltip from 'components/shared/tooltip/Tooltip';
import ReactTooltip from 'react-tooltip';
import { hideTooltip } from 'utils/tooltip';
import { BackgroundCircle, ProgressIndicatorWrapper, StyledProgress } from './ProgressLoader.style';

export interface ContainerProps {
  type?: 'loader' | 'progress';
  size?: number;
  thickness?: number;
  message?: string;
  onHover?: boolean;
  marginLeft?: number;
  toolTipRequired?: boolean;
}

const ProgressLoader = ({
  type,
  size,
  message,
  onHover,
  marginLeft,
  toolTipRequired = true,
  thickness = 3,
}: ContainerProps) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress + 10) % 100);
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <StyledProgress marginLeft={marginLeft} data-testid="progress-indicator">
        <ProgressIndicatorWrapper data-for="progress-tooltip" data-tip>
          <BackgroundCircle size={size} thickness={thickness} />
          <CircularProgress
            color="inherit"
            size={size}
            variant={type === 'loader' ? 'indeterminate' : 'determinate'}
            value={progress}
            thickness={thickness}
          />
        </ProgressIndicatorWrapper>
        {toolTipRequired && (
          <Tooltip
            id="progress-tooltip"
            place="bottom"
            content={message}
            onHover={onHover}
            onClose={
              !onHover
                ? () => {
                    hideTooltip('#progress-tooltip');
                    ReactTooltip.hide();
                  }
                : null
            }
          />
        )}
      </StyledProgress>
    </>
  );
};
export default ProgressLoader;
