import styled from '@emotion/styled';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';
import { newTypography } from 'styles/typography';
import zIndex from 'styles/zIndex';
import { StyledWithNewThemeProps } from 'utils/types';

export const LoginPage = styled.div<StyledWithNewThemeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url('/assets/mcd_login_background.png');
  background-size: 2491px 1624px;
`;

export const LoginContainer = styled.div<StyledWithNewThemeProps>`
  width: 505px;
  height: 622px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  background: ${newGlobalTheme.colors.global.background};
  z-index: ${zIndex.hoverBackdrop};

  p {
    text-align: center;
  }
`;

export const LoginContainerHeader = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding-top: 46px;
  margin-bottom: 20px;
`;

export const LoginContainerBody = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  height: 100%;
  padding: 0 ${newTypography.unit * 8.125}px;
  font-family: ${newTypography.primaryFont};
  font-size: ${newTypography.fontSize}px;
`;

export const CustomError = styled.div<StyledWithNewThemeProps>`
  min-height: ${newTypography.unit * 5}px;
  color: ${newGlobalTheme.colors.global.error};
  font-family: ${newTypography.primaryFont};
  text-align: center;
  font-size: 14px;
    &:focus-visible {
    outline: 2px solid ${newGlobalTheme.colors.global.tabNavigation};
    outline-offset: 4px;
    z-index: ${zIndex.tabNavigation};
    transition: none;
  }
`;
