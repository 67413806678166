import styled from '@emotion/styled';
import { Icon, IconProps } from '../icon';
import {NewSearchTextField} from '../newTextField/NewTextField';
import { newGlobalTheme } from 'styles/themes/newGlobalTheme';

export const StyledIcon = styled(Icon)<IconProps>`
  display: flex;
`;

export const StyledTextField = styled(NewSearchTextField)`
  width: 234px;
`;
