import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
import { ApprovalStatus, SSE } from 'utils/types';
import { CampaignAlert, CampaignType } from 'utils/types/campaigns';
import { clientQuery } from 'utils/api/clientQuery';
import { dashbaoardGqls } from 'pages/dashboard/Dashboard.gqls';
import type { RootState } from '../store';
import { RedemptionOverviewDropdownEnum } from 'pages/dashboard/components/redemptionOverview/RedemptionOverview.const';
import sseService from '../../utils/sseService';

export interface NoRedemptionsCampaignData {
  id: number;
  externalId: number;
  title: string;
  type: CampaignType;
  offerName: string;
}

export interface RedemptionsOverview {
  type: string;
  lastUpdated: string | Date;
  totalRedemptions: {
    overall: number;
    [RedemptionOverviewDropdownEnum.Today]: number;
    [RedemptionOverviewDropdownEnum.Yesterday]: number;
    [RedemptionOverviewDropdownEnum.Last7Days]: number;
    [RedemptionOverviewDropdownEnum.Last30Days]: number;
  };
}

export interface UpdatesOverview {
  products: {
    lastUpdated: string | Date;
    productsUpdated: number;
    campaignsImpacted: number;
    offersImpacted: number;
  };
  locations: {
    lastUpdated: string | Date;
    locationsUpdated: number;
    campaignsImpacted: number;
  };
}

export interface DashboardData {
  data: {
    campaigns: {
      lastUpdated: string | Date;
      [ApprovalStatus.Active]: number;
      [ApprovalStatus.Rejected]: number;
      [ApprovalStatus.DeploymentFailed]: number;
      [ApprovalStatus.PendingApproval]: number;
      pendingApprovalNotice: number[];
      noRedemptions: {
        lastUpdated: string | Date;
        data: {
          total: number;
          campaigns: NoRedemptionsCampaignData[];
        };
      };
    };
    redemptions: RedemptionsOverview;
    offers: {
      [ApprovalStatus.Rejected]: number;
      [ApprovalStatus.PendingApproval]: number;
      lastUpdated: string | Date;
    };
    alerts: {
      [CampaignAlert.UpdateFailed]: number;
      [CampaignAlert.RevokeFailed]: number;
      [CampaignAlert.Conflicted]: number;
      [CampaignAlert.StopAssociationFailed]: number;
    };
    updates: UpdatesOverview;
  };
}

export const defaultDashboardData: DashboardData = {
  data: {
    campaigns: {
      lastUpdated: null,
      [ApprovalStatus.Active]: 0,
      [ApprovalStatus.Rejected]: 0,
      [ApprovalStatus.DeploymentFailed]: 0,
      [ApprovalStatus.PendingApproval]: 0,
      pendingApprovalNotice: [],
      noRedemptions: {
        lastUpdated: null,
        data: {
          total: 0,
          campaigns: [],
        },
      },
    },
    redemptions: {
      type: SSE.Redemptions,
      lastUpdated: null,
      totalRedemptions: {
        overall: 0,
        [RedemptionOverviewDropdownEnum.Today]: 0,
        [RedemptionOverviewDropdownEnum.Yesterday]: 0,
        [RedemptionOverviewDropdownEnum.Last7Days]: 0,
        [RedemptionOverviewDropdownEnum.Last30Days]: 0,
      },
    },
    offers: {
      lastUpdated: null,
      [ApprovalStatus.Rejected]: 0,
      [ApprovalStatus.PendingApproval]: 0,
    },
    alerts: {
      [CampaignAlert.UpdateFailed]: 0,
      [CampaignAlert.RevokeFailed]: 0,
      [CampaignAlert.Conflicted]: 0,
      [CampaignAlert.StopAssociationFailed]: 0,
    },
    updates: {
      products: {
        lastUpdated: null,
        productsUpdated: 0,
        campaignsImpacted: 0,
        offersImpacted: 0,
      },
      locations: {
        lastUpdated: null,
        locationsUpdated: 0,
        campaignsImpacted: 0,
      },
    },
  },
};

const updateRedemptions = (redemptionsData: RedemptionsOverview) => ({
  type: 'dashboard/updateRedemptionsData',
  payload: redemptionsData,
});

export const startSSEConnection = () => (dispatch: Dispatch<any>) => {
  const handleRedemptions = (event: any) => {
    const data: RedemptionsOverview = JSON.parse(event.data);
    dispatch(updateRedemptions(data));
  };

  if (sseService.sse) {
    try {
      sseService.sse?.addEventListener(SSE.Redemptions, handleRedemptions);
    } catch (error) {
      console.error('Error adding event listener for Dashboard:', error);
    }
    return () => {
      try {
        sseService.sse?.removeEventListener(SSE.Redemptions, handleRedemptions);
      } catch (error) {
        console.error('Error removing event listener for Dashboard:', error);
      }
    };
  }
};

export const loadDashboardData = createAsyncThunk(
  'dashboard/init',
  async (): Promise<any> => {
    const dashboardData = await clientQuery(
      dashbaoardGqls.queries.getDashboardOverview,
      {}
    );
    return dashboardData.data.getDashboardOverview;
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: defaultDashboardData as DashboardData,
  reducers: {
    updateRedemptionsData: (state, action) => {
      state.data.redemptions = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadDashboardData.fulfilled, (state, action) => {
      state.data = action.payload;
    });

    builder.addCase(loadDashboardData.rejected, (state) => {
      console.error('Error loading dashboard data!');
      return state;
    });
  },
});

export const dashboard = (state: RootState) => state.dashboard;

export default dashboardSlice.reducer;
