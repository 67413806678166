import styled from '@emotion/styled/macro';
import { StyledWithThemeProps } from 'utils/types';
import zIndex from 'styles/zIndex';
import { Icon, IconProps } from '../icon';
import typography from '../../../styles/typography';
import { ButtonText, NewActionButtonText} from '../button';

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;

  [data-title]:after {
    left: 5px;
    top: -100%;
    bottom: unset;
  }
`;

export const StyledIcon = styled(Icon)<IconProps>`
  fill: ${({ theme }) => theme.colors.text.secondary};
  cursor: pointer;

  &:hover {
    fill: ${({ theme }) => theme.colors.global.inputPrimary};
  }
`;

export const StyledListIcon = styled(StyledIcon)<IconProps>`
  margin-right: ${typography.unit * 2}px;
`;

export const StyledButtonText = styled(ButtonText)`
  margin-right: ${typography.unit * 3}px;
`;

export const NewStyledButtonText = styled(NewActionButtonText)`
  margin-right: ${typography.unit * 3}px;
}
`;

export const StyledListButtonText = styled(ButtonText)`
  color: ${({ theme }) => theme.colors.text.secondary};
  padding: ${typography.unit * 3}px;

  &:hover {
    color: ${({ theme }) => theme.colors.global.inputPrimary};

    svg {
      fill: ${({ theme }) => theme.colors.global.inputPrimary};
    }
  }
`;

export const HasMoreContainer = styled.div`
  position: relative;
`;

export const HasMore = styled(ButtonText)`
  margin-left: ${typography.unit * 3}px;
`;

export const StyledDropdown = styled.div<StyledWithThemeProps>`
  border: ${({ theme }) => `1px solid ${theme.colors.global.border};`};
  border-radius: ${typography.borderRadius}px;
  position: absolute;
  top: 30px;
  left: 10px;
  z-index: ${zIndex.dropdown};
`;
