import React from 'react';
import useDynamicSVGImport from 'hooks/use-dynamic-svg-import';
import { IconProps } from './Icon.consts';
import { ReactComponent as InfoIcon } from 'assets/svgs/info.svg';
import { ReactComponent as ErrorIcon } from 'assets/svgs/error.svg';
import { ReactComponent as CloseIcon } from 'assets/svgs/close.svg';
import { ReactComponent as NewCloseIcon } from 'assets/svgs/newClose.svg';
import { ReactComponent as NewCloseSelectIcon } from 'assets/svgs/newCloseSelect.svg';

const svgIcons: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
  info: InfoIcon,
  error: ErrorIcon,
  close: CloseIcon,
  newClose: NewCloseIcon,
  newCloseSelect: NewCloseSelectIcon,
};

export const Icon = ({ name, width = 12, staticImport = false, ...rest }: IconProps) => {
  let SvgIcon;
  if (staticImport) {
    ({ SvgIcon } = useStaticSVGImport(name));
  } else {
    ({ SvgIcon } = useDynamicSVGImport(name));
  }
  return <>{SvgIcon && <SvgIcon width={width} data-automation-id={`icon-${name}`} {...rest} />}</>;
};

export default function useStaticSVGImport(name: string) {
  const SvgIcon = svgIcons[name];
  return { SvgIcon };
}
