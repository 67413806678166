import styled from '@emotion/styled';
import typography, { newTypography } from 'styles/typography';
import { Icon, IconProps } from 'components/shared/icon';
import Tooltip from 'components/shared/tooltip/Tooltip';
import TabStrip from 'components/shared/tabStrip/TabStrip';
import { StyledWithThemeProps } from 'utils/types';
import { ButtonText } from 'components/shared/button';
import StatusLabel from 'components/shared/statusLabel/StatusLabel';

export const HeaderContainer = styled.div`
  width: 100%;
  border-radius: 5px;
  padding: ${typography.unit * 4}px;
  display: flex;
  flex-direction: column;
  margin: 0 0px 0px 0 !important;
`;

export const Container = styled.div<StyledWithThemeProps>`
  width: 408px;
  height: 365px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  display: flex;
  flex-direction: column;
  transition: all ${typography.transition}s ease;

  &:hover {
    box-shadow: ${({ theme }) => theme.colors.global.boxShadow};
    #headerContainer{
     background-color: gray;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: ${typography.unit}px;
  position: relative;
`;

export const HeaderActions = styled.div`
  display: flex;
`;

export const StyledButtonText = styled(ButtonText)`
  margin-left: ${typography.unit * 4}px;
`;

export const StyledStatusLabel = styled(StatusLabel)`
  position: absolute;
  top: -${typography.unit * 4}px;
  left: -${typography.unit * 4}px;
  border-top-left-radius: ${typography.borderRadius}px;
`;

export const TermName = styled.span<StyledWithThemeProps>`
  font-size: 16px;
  font-family: ${newTypography.primaryFont};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.text.primary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledIcon = styled(Icon)<IconProps>`
  width: 18px;
 fill: #fff;
  cursor: pointer;

  :hover {
    fill: ${({ theme }) => theme.colors.global.inputPrimary};
  }
`;

export const StyledArchiveIcon = styled(StyledIcon)`
  width: 22px; 
  cursor: pointer;
  :hover {
    fill: ${({ theme }) => theme.colors.global.inputPrimary};
  }
`;

export const OfferTemplates = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${typography.unit * 7}px;
`;

export const StyledOfferTemplatesText = styled.span<StyledWithThemeProps>`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-right: ${typography.unit * 4}px;
`;

export const StyledFirstOffer = styled.span<StyledWithThemeProps>`
  font-size: ${typography.fontSize}px;
  margin-right: ${typography.unit * 2}px;
  text-transform: capitalize;
`;

export const Translations = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: ${typography.unit * 4}px;
  padding-left: ${typography.unit * 5}px;
  padding-right: ${typography.unit * 5}px;

  overflow-y: auto;
`;

export const StyledTabStrip = styled(TabStrip)`
  height: 30px;
  font-size: 14px;

  a {
    padding: 0 ${typography.unit * 2}px;
  }
`;

export const LastUpdatedText = styled.span<StyledWithThemeProps>`
  font-size: 12px;
  text-transform: capitalize;
  align-self: flex-end;
`;

export const TranslationContent = styled.div<StyledWithThemeProps>`
  flex: 1;
  border: 1px solid ${({ theme }) => theme.colors.global.border};
  padding: ${typography.unit * 3}px;
  overflow-y: auto;
  font-size: ${typography.fontSize}px;
  line-height: 22px;
  word-break: break-word;
`;

export const StyledTooltip = styled(Tooltip)`
  opacity: 1 !important;
`;

export const TermConditionId = styled.div`
  font-size: 12px;
  font-weight: 400;
  align-self: end;
  padding-right: ${typography.unit}px;
`;

export const StyledDownloadButton = styled(ButtonText)`
  margin-right: 20px;
`;
