import React from 'react';
import { NewButtonBaseProps } from 'components/shared/button/buttonBase/ButtonBase.consts';
import { OfferNewStyledButton } from './AddNewButton.style';
import AddIcon from '@mui/icons-material/Add';

const NewOfferAddNewButton = ({ onClick, className }: NewButtonBaseProps) => {
  return (
    <>
      <OfferNewStyledButton onClick={onClick} className={className} data-title="Add New">

        <AddIcon />
      </OfferNewStyledButton>
    </>
  );
};
export default NewOfferAddNewButton;
