import React, { useEffect, useRef } from 'react';
import { debounce } from 'utils/debounce';
import { getIsControlWithError } from 'utils/form';
import { AdornmentPosition, TextFieldProps } from './TextField.consts';
import { InputContainer, StyledChildren, StyledContainer, StyledError, InputWrapper, StyledInput, StyledLabel } from './OfferTemplateTextField.style';

const OfferTemplateTextField = ({
  id,
  name,
  type = 'text',
  register,
  validation,
  label,
  labelIsHorizontal,
  placeholder,
  disabled,
  value,
  errors,
  adornmentPosition = AdornmentPosition.Start,
  children,
  className,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  debounceTime,
  caretPosition,
  autoFocus = false,
  showError = true,
  selectWidth,
}: TextFieldProps) => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (autoFocus && caretPosition === 0) {
      return;
    }
    if (caretPosition !== 0) {
      elementRef.current?.setSelectionRange(caretPosition, caretPosition);
    }
  }, [caretPosition]);

  return (
    <StyledContainer labelIsHorizontal={labelIsHorizontal} className={className}>
      {label && (
        <StyledLabel disabled={disabled} labelIsHorizontal={labelIsHorizontal}>{`${label}${validation?.required ? '*' : ''}`}</StyledLabel>
      )}
      <InputContainer {...(disabled && { title: value?.toString() })}>
        <InputWrapper
          className={'input-wrapper'}
          disabled={disabled}
          adornmentPosition={adornmentPosition}
          error={getIsControlWithError(name, errors)}
        >
          {children && <StyledChildren>{children}</StyledChildren>}
          <StyledInput
            ref={elementRef}
            id={id}
            {...(register &&
              register(name, {
                ...validation,
                shouldUnregister: true,
                setValueAs: (v: string | number) => (typeof v === 'string' ? v.trim() : v),
              }))}
            defaultValue={value}
            placeholder={placeholder}
            disabled={disabled}
            {...(onChange && { onChange: debounce(onChange, debounceTime) })}
            {...onFocus}
            {...onBlur}
            onKeyPress={onKeyPress}
            autoFocus={autoFocus}
            autoComplete="off"
            data-automation-id={`text-field-${name}`}
            width={selectWidth}
          />
        </InputWrapper>
        {showError && <StyledError name={name} errors={errors} />}
      </InputContainer>
    </StyledContainer>
  );
};

export default OfferTemplateTextField;
